import {
  USER_IMAGE_UPLOADED,
  USER_IMAGE_UPLOADED_FAILED,
  NEWS_IMAGE_UPLOADED,
  NEWS_IMAGE_UPLOADED_FAILED,
  NEWS_VIDEO_UPLOADED,
  NEWS_VIDEO_UPLOADED_FAILED,
  BENEFITS_IMAGE_UPLOADED,
  BENEFITS_IMAGE_UPLOADED_FAILED,
  BENEFITS_VIDEO_UPLOADED,
  BENEFITS_VIDEO_UPLOADED_FAILED
} from "../../@jumbo/constants/ActionTypes";

export const userImageUploaded = contentURL => {
  return dispatch => {
    dispatch({
      type: USER_IMAGE_UPLOADED,
      payload: contentURL
    });
  };
};

export const userImageUploadedFailed = message => {
  return dispatch => {
    dispatch({
      type: USER_IMAGE_UPLOADED_FAILED,
      payload: message
    });
  };
};

export const newsImageUploaded = contentURL => {
  return dispatch => {
    dispatch({
      type: NEWS_IMAGE_UPLOADED,
      payload: contentURL
    });
  };
};

export const newsImageUploadedFailed = message => {
  return dispatch => {
    dispatch({
      type: NEWS_IMAGE_UPLOADED_FAILED,
      payload: message
    });
  };
};

export const newsVideoUploaded = contentURL => {
  return dispatch => {
    dispatch({
      type: NEWS_VIDEO_UPLOADED,
      payload: contentURL
    });
  };
};

export const newsVideoUploadedFailed = message => {
  return dispatch => {
    dispatch({
      type: NEWS_VIDEO_UPLOADED_FAILED,
      payload: message
    });
  };
};

export const benefitsImageUploaded = contentURL => {
  return dispatch => {
    dispatch({
      type: BENEFITS_IMAGE_UPLOADED,
      payload: contentURL
    });
  };
};

export const benefitsImageUploadedFailed = message => {
  return dispatch => {
    dispatch({
      type: BENEFITS_IMAGE_UPLOADED_FAILED,
      payload: message
    });
  };
};

export const benefitsVideoUploaded = contentURL => {
  return dispatch => {
    dispatch({
      type: BENEFITS_VIDEO_UPLOADED,
      payload: contentURL
    });
  };
};

export const benefitsVideoUploadedFailed = message => {
  return dispatch => {
    dispatch({
      type: BENEFITS_VIDEO_UPLOADED_FAILED,
      payload: message
    });
  };
};
