import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Block,
  CheckCircleOutline,
  Edit,
  MoreHoriz,
  Visibility
} from "@material-ui/icons";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import {
  sentMailToTraining,
  updateTraining
} from "../../../../redux/actions/Trainings";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const TrainingListRow = ({
  row,
  isSelected,
  onRowClick,
  onTrainingEdit,
  onTrainingDelete,
  onTrainingView
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getTrainingActions = training => {
    const actions = [
      {
        action: "view",
        label: "Ver",
        icon: <Visibility id="btn-verCapacitacion" />
      },
      {
        action: "edit",
        label: "Editar",
        icon: <Edit id="btn-editarCapacitacion" />
      }
    ];

    row.status === "ACTIVE"
      ? actions.push({
          action: "suspend",
          label: "Suspender",
          icon: <Block id="btn-suspenderCapacitacion" />
        })
      : actions.push({
          action: "activate",
          label: "Activar",
          icon: <CheckCircleOutline />
        });
    return actions;
  };

  const onTrainingMenuClick = menu => {
    if (menu.action === "view") {
      onTrainingView(row);
    } else if (menu.action === "edit") {
      onTrainingEdit(row);
    } else if (menu.action === "email") {
      dispatch(sentMailToTraining());
    } else if (menu.action === "suspend") {
      dispatch(updateTraining({ id: row.id, status: "SUSPENDED" }));
    } else if (menu.action === "activate") {
      dispatch(updateTraining({ id: row.id, status: "ACTIVE" }));
    } else if (menu.action === "delete") {
      onTrainingDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const trainingActions = getTrainingActions(row);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row.id)}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
    >
      <TableCell padding="checkbox" />
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={trainingActions}
          onItemClick={onTrainingMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(TrainingListRow);
