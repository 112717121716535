import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { alpha, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";

import ProjectTable from "./ProjectTable";
import { getProjects } from "../../../../redux/actions/Projects";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: "relative",
    "& .Cmt-card-content": {
      padding: 0,
      paddingBottom: 24
    }
  },
  scrollbarRoot: {
    height: 292
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: "2px 10px",
    borderRadius: 30
  }
}));

const CurrentProjectsSummary = () => {
  const classes = useStyles();

  const { projects } = useSelector(({ projectsReducer }) => projectsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects([], "", () => {}));
  }, [dispatch]);

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader title="Equipos"></CmtCardHeader>
      <CmtCardContent>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          {projects && <ProjectTable data={projects} />}
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  );
};

export default CurrentProjectsSummary;
