import Forbidden from "@jumbo/components/Forbidden";
import GridContainer from "@jumbo/components/GridContainer";
import Restricted from "services/permissionProvider/Restricted";
import UserActivityAlert from "@jumbo/components/UserActivityAlert";
import { CurrentStorageMethod } from "@jumbo/constants/AppConstants";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
  Zoom
} from "@material-ui/core";
import { ImageSearch } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "redux/actions/Roles";
import { addNews } from "redux/actions/UserActivities";
import { getAreas, getUsers } from "redux/actions/Users";
import { NOTICIAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import { StorageMethods, StorageContentTypes } from "services/storage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { useHistory } from "react-router";
import ReactPlayer from "react-player";
import { useHasPrivilege } from "routes/checkPermission";

const AddNews = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { users } = useSelector(({ usersReducer }) => usersReducer);
  const { areas } = useSelector(({ usersReducer }) => usersReducer);
  const { newsImageUploadedUrl } = useSelector(({ storage }) => storage);
  const [userValue, setUserValue] = React.useState([]);
  const [areaValue, setAreaValue] = React.useState([]);
  const [noticia, setNoticia] = React.useState({
    title: "",
    description: "",
    type: "",
    image_url: "",
    video_url: "",
    expiration_date: "",
    publish_date: moment(new Date()).format("YYYY-MM-DD"),
    recipient_areas: areaValue,
    recipient_users: userValue
  });
  const [titleError, setTitleError] = React.useState(false);
  const [descriptionError, setDescriptionError] = React.useState(false);
  const [typeError, setTypeError] = React.useState(false);
  const [dateError, setDateError] = React.useState(false);
  const [dataError, setDataError] = React.useState(false);
  const [videoError, setVideoError] = React.useState(false);
  const [loadImage, setLoadImage] = React.useState(false);
  // const [loadVideo, setLoadVideo] = React.useState("");
  const [shareToAllUsers, setShareToAllUsers] = React.useState(false);

  const canDispatch = useHasPrivilege(NOTICIAS_EDITAR);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canDispatch) {
      dispatch(getUsers([], "", () => {}));
    }
  }, [dispatch, canDispatch]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getRoles([], "", () => {}));
    }
  }, [dispatch, canDispatch]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getAreas([], "", () => {}));
    }
  }, [dispatch, canDispatch]);

  const handlePic = pic => {
    setNoticia({ ...noticia, image_url: URL.createObjectURL(pic) });
    dispatch(
      StorageMethods[CurrentStorageMethod].onUpload(
        pic,
        StorageContentTypes.NEWS_IMAGE_UPLOAD
      )
    );
    setLoadImage(true);
  };

  /*  const handleVideo = video => {
    setNoticia({ ...noticia, video_url: URL.createObjectURL(video) });
    dispatch(
      StorageMethods[CurrentStorageMethod].onUpload(
        video,
        StorageContentTypes.NEWS_VIDEO_UPLOAD
      )
    );
    setLoadVideo(true);
  }; */

  const handleVideo = e => {
    setNoticia({ ...noticia, video_url: e.target.value });
    // console.log(e.target.value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    // 'video':['.mp4', '.mp3']
    onDrop: acceptedFiles => {
      handlePic(acceptedFiles[0]);
      // handleVideo(acceptedFiles[0]);
    }
  });

  const handleChange = e => {
    setNoticia({ ...noticia, [e.target.name]: e.target.value });
    setTitleError(false);
    setDescriptionError(false);
    setTypeError(false);
    setDataError(false);
    setDateError(false);
    setVideoError(false);
  };

  const todaysDate = moment(new Date()).format("DD/MM/yyyy");

  const validateNewsForm = () => {
    let condition = true;

    noticia.expiration_date = moment(noticia.expiration_date).format(
      "DD/MM/yyyy"
    );

    noticia.publish_date = moment(noticia.publish_date).format("DD/MM/yyyy");

    if (noticia.title.trim() === "" || noticia.title.length > 100) {
      setTitleError(true);
      condition = false;
    } else if (titleError && noticia.title.trim() !== "") {
      setTitleError(false);
    }
    if (noticia.description.trim() === "" || noticia.description.length > 254) {
      setDescriptionError(true);
      condition = false;
    } else if (descriptionError && noticia.description.trim() !== "") {
      setTitleError(false);
    }
    if (noticia.video_url.replaceAll(" ", "").length === 0) {
      setNoticia({ ...noticia, video_url: "" });
    } else if (noticia.video_url.length > 200) {
      setVideoError(true);
      condition = false;
    } else if (videoError && noticia.video_url.replaceAll(" ", "").length > 0) {
      setVideoError(false);
    }
    if (noticia.type === "") {
      setTypeError(true);
      condition = false;
    } else if (typeError && noticia.type !== "") {
      setTypeError(false);
    }
    if (noticia.publish_date === noticia.expiration_date) {
      setDateError(true);
      condition = false;
    } else if (noticia.publish_date.slice(6, 10) < todaysDate.slice(6, 10)) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(6, 10) > noticia.expiration_date.slice(6, 10)
    ) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(6, 10) ===
        noticia.expiration_date.slice(6, 10) &&
      noticia.publish_date.slice(3, 5) > noticia.expiration_date.slice(3, 5)
    ) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(3, 10) ===
        noticia.expiration_date.slice(3, 10) &&
      noticia.publish_date.slice(0, 2) > noticia.expiration_date.slice(0, 2)
    ) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(6, 10) === todaysDate.slice(6, 10) &&
      noticia.publish_date.slice(3, 5) < todaysDate.slice(3, 5)
    ) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(3, 10) === todaysDate.slice(3, 10) &&
      noticia.publish_date.slice(0, 2) < todaysDate.slice(0, 2)
    ) {
      setDateError(true);
      condition = false;
    }

    if (!shareToAllUsers) {
      if (areaValue.length === 0 && userValue.length === 0) {
        setDataError(true);
        condition = false;
      } else if (
        (dataError && areaValue.length !== 0) ||
        userValue.length !== 0
      ) {
        setDataError(false);
      }
    }

    return condition;
  };

  const onAddNews = news => {
    let currentNew = Object.assign({}, noticia);
    currentNew["image_url"] = newsImageUploadedUrl;
    setNoticia(currentNew);

    const newImageURL = newsImageUploadedUrl
      ? newsImageUploadedUrl
      : news.image_url;
    news.image_url = newImageURL;

    const allAreas = areas.map(area => {
      return { name: area.name };
    });

    if (validateNewsForm()) {
      const noti = {
        title: news.title,
        description: news.description,
        type: news.type,
        recipient_users: shareToAllUsers
          ? [{ email: authUser.email }]
          : userValue,
        recipient_areas: shareToAllUsers ? allAreas : areaValue,
        expiration_date: news.expiration_date,
        publish_date: news.publish_date,
        image_url: newImageURL,
        video_url: news.video_url.replaceAll(" ", "")
      };

      /*  console.log(noti);
      console.log(news.video_url) */

      const scrollTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

      dispatch(
        addNews(noti, () => {
          scrollTop();
          setUserValue([]);
          setNoticia({
            title: "",
            description: "",
            type: "",
            image_url: "",
            video_url: "",
            expiration_date: "",
            publish_date: "",
            recipient_areas: [],
            recipient_users: []
          });
        })
      );
    }
  };

  const orderedUsers = stableSort(users, getComparator("asc", "first_name"));

  // console.log(noticia.video_url.replaceAll(' ', '').length)

  const history = useHistory();

  return (
    <Restricted to={NOTICIAS_EDITAR} fallback={Forbidden}>
      <Box>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography gutterBottom>Crear noticia</Typography>
          <UserActivityAlert />
          <Tooltip title="Ver noticias" TransitionComponent={Zoom}>
            <Button
              style={{ marginBottom: "10px" }}
              id="btn-verNoticias"
              variant="outlined"
              disableElevation
              color="primary"
              onClick={() => history.push("/noticias")}
            >
              Ver Noticias
            </Button>
          </Tooltip>
        </Grid>
        <Card>
          <CardContent>
            <GridContainer>
              <Grid xs={12} sm={12} item>
                <TextField
                  id="txt-tituloNoticia"
                  placeholder="Escribe un titulo"
                  variant="outlined"
                  label="Título"
                  fullWidth
                  value={noticia.title}
                  error={titleError}
                  helperText={
                    titleError
                      ? "Debes completar este campo con 100 carácteres como máximo."
                      : ""
                  }
                  name="title"
                  onChange={e => handleChange(e)}
                />
              </Grid>
              <Grid xs={12} sm={12} item>
                <TextField
                  id="txt-descripcionNoticia"
                  placeholder="Escribe una noticia"
                  variant="outlined"
                  label="Noticia"
                  fullWidth
                  multiline
                  minRows={8}
                  value={noticia.description}
                  error={descriptionError}
                  helperText={
                    descriptionError
                      ? "Debes completar este campo con 254 carácteres como máximo."
                      : ""
                  }
                  name="description"
                  onChange={e => handleChange(e)}
                />
              </Grid>
              <Grid xs={12} sm={12} item>
                {noticia.image_url.length > 0 && (
                  <CardMedia
                    component="img"
                    height="100px"
                    width="50px"
                    image={noticia.image_url}
                    alt="La vista previa no pudo ser cargada"
                  />
                )}
                <Button
                  id="btn-imagenNoticia"
                  fullWidth
                  variant="outlined"
                  onClick={e => handlePic(e)}
                  {...getRootProps()}
                  mr={{ xs: 0, md: 5 }}
                  mb={{ xs: 3, md: 0 }}
                >
                  {loadImage ? "Cambiar Imágen" : "Seleccionar Imágen"}
                  <ImageSearch />
                </Button>
                <input {...getInputProps()} />
              </Grid>

              <Grid xs={12} sm={12} item>
                {noticia.video_url.length > 0 && (
                  <ReactPlayer
                    url={noticia.video_url}
                    height="300px"
                    width="100%"
                  />
                )}
                <TextField
                  style={{ marginTop: "10px" }}
                  placeholder="Ingresá una URL de youtube"
                  variant="outlined"
                  label="URL"
                  name="video"
                  error={videoError}
                  helperText={
                    videoError
                      ? "Debes completar este campo con 200 carácteres como máximo."
                      : ""
                  }
                  fullWidth
                  value={noticia.video_url}
                  onChange={e => handleVideo(e)}
                />
              </Grid>

              <Box ml={3}>
                <FormControlLabel
                  label="Compartir con todos los usuarios"
                  control={
                    <Checkbox
                      id="chkbox-compartirTodosNoticia"
                      onChange={e => setShareToAllUsers(e.target.checked)}
                    />
                  }
                />
              </Box>

              {!shareToAllUsers && (
                <>
                  <Grid xs={12} sm={12} item>
                    <Autocomplete
                      id="destinatarios-areas"
                      multiple
                      options={areas}
                      getOptionLabel={area => area.name}
                      name="recipient_areas"
                      getOptionSelected={(option, value) => option === value}
                      placeholder="Buscar..."
                      onChange={(e, getOptionSelected) =>
                        setAreaValue(
                          getOptionSelected.map(op => {
                            return {
                              name: op.name
                            };
                          })
                        )
                      }
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Áreas Destinatarias"
                          variant="outlined"
                          error={dataError}
                          helperText={
                            dataError
                              ? "Debes elegir al menos una opción de Área o Usuarios destinatarios"
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} item>
                    <Autocomplete
                      id="destinatarios-users"
                      multiple
                      options={orderedUsers}
                      getOptionLabel={user =>
                        user.first_name + " " + user.last_name
                      }
                      name="recipient_users"
                      getOptionSelected={(userValue, value) =>
                        userValue === value
                      }
                      placeholder="Buscar..."
                      onChange={(e, getOptionSelected) =>
                        setUserValue(
                          getOptionSelected.map(op => {
                            return {
                              email: op.email
                            };
                          })
                        )
                      }
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Usuarios Destinatarios"
                          variant="outlined"
                          error={dataError}
                          helperText={
                            dataError
                              ? "Debes elegir al menos una opción de Área o Usuarios destinatarios"
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid xs={12} sm={12} item>
                <TextField
                  id="publicacionType"
                  select
                  name="type"
                  label="Tipo de publicación"
                  variant="outlined"
                  style={{ width: "400px" }}
                  value={noticia.type}
                  error={typeError}
                  helperText={typeError ? "Debes elegir una opción" : ""}
                  onChange={e => handleChange(e)}
                >
                  <MenuItem value={"LAUNCH"}>Alerta</MenuItem>
                  <MenuItem value={"TRAINING"}>Capacitaciones</MenuItem>
                  <MenuItem value={"WORKSHOP"}>Comunicado</MenuItem>
                  <MenuItem value={"BIRTHDAY"}>Cumpleaños</MenuItem>
                  <MenuItem value={"EVENT"}>Evento</MenuItem>
                  <MenuItem value={"READING"}>Lectura</MenuItem>
                </TextField>
              </Grid>
              <Grid xs={12} sm={12} item>
                <TextField
                  id="publishDate"
                  type="date"
                  name="publish_date"
                  label="Día de Publicación"
                  variant="outlined"
                  style={{ width: "400px" }}
                  value={noticia.publish_date}
                  onChange={e => handleChange(e)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={dateError}
                  helperText={
                    dateError
                      ? "La fecha de publicación debe ser previa a la fecha de expiración y mayor a la actual"
                      : ""
                  }
                />
              </Grid>
              <Grid xs={12} sm={12} item>
                <TextField
                  id="expirationDate"
                  type="date"
                  name="expiration_date"
                  label="Día de Expiración"
                  variant="outlined"
                  style={{ width: "400px" }}
                  value={noticia.expiration_date}
                  onChange={e => handleChange(e)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12} item>
                <Button
                  id="btn-publicarNoticia"
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={() => onAddNews(noticia)}
                >
                  Publicar
                </Button>
              </Grid>
            </GridContainer>
          </CardContent>
        </Card>
      </Box>
    </Restricted>
  );
};

export default AddNews;
