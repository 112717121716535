import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import AppTextInput from "../../../../@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  addNewLicence,
  getLicences,
  updateLicence
} from "../../../../redux/actions/Licences";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const AddEditLicence = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const currentLicence = useSelector(
    ({ licencesReducer }) => licencesReducer.currentLicence
  );
  const { licences } = useSelector(({ licencesReducer }) => licencesReducer);

  const [name, setName] = useState(currentLicence ? currentLicence.name : "");
  const [nameError, setNameError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLicences());
  }, [dispatch]);

  useEffect(() => {
    if (currentLicence) {
      setName(currentLicence.name);
    }
  }, [currentLicence]);

  const replaceSpecialCharacters = text => {
    const text1 = text.replaceAll("/", "-");
    const text3 = text1.replaceAll("¿", "-");
    const text4 = text3.replaceAll("?", "-");
    const text5 = text4.replaceAll("¡", "-");
    const text6 = text5.replaceAll("!", "-");
    const text7 = text6.replaceAll("[", "-");
    const text8 = text7.replaceAll("]", "-");
    const text9 = text8.replaceAll("{", "-");
    const text10 = text9.replaceAll("}", "-");
    const text11 = text10.replaceAll("*", "-");
    const text12 = text11.replaceAll('"', "");
    const text13 = text12.replaceAll("'", "");

    return text13;
  };

  useEffect(() => {
    setName(replaceSpecialCharacters(name));
  }, [name]);

  const onSubmitClick = () => {
    const activeFilter = licences.filter(
      licence => licence.status === "ACTIVE"
    );
    const suspendedFilter = licences.filter(
      licence => licence.status === "SUSPENDED"
    );
    const activeNames = activeFilter.map(licence => licence.name);
    const suspendedNames = suspendedFilter.map(licence => licence.name);
    if (name == null || name === "") {
      setNameError("Nombre requerido");
    } else if (activeNames.includes(name)) {
      setNameError("Existe una licencia activa con este nombre");
    } else if (suspendedNames.includes(name)) {
      setNameError("Existe una licencia suspendida con este nombre");
    } else {
      onLicenceSave();
    }
  };

  const onLicenceSave = phoneNumbers => {
    if (currentLicence) {
      const id = currentLicence.id;
      const licenceDetail = {
        id,
        name
      };
      dispatch(
        updateLicence(licenceDetail, () => {
          onCloseDialog();
        })
      );
    } else {
      const licenceDetail = {
        name
      };
      dispatch(
        addNewLicence(licenceDetail, () => {
          onCloseDialog();
        })
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentLicence ? "Editar Licencia" : "Crear Licencia"}
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            id="txt-nombreLicencia"
            fullWidth
            variant="outlined"
            label={<Typography variant="h6">Nombre</Typography>}
            value={name}
            onChange={e => {
              setName(e.target.value);
              setNameError("");
            }}
            helperText={nameError}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} id="btn-cancelar">
            Cancel
          </Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmitClick}
              id="btn-guardarCambios"
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditLicence;

AddEditLicence.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
