import React from "react";
import {
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser
} from "../../../redux/actions/Auth";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { auth, googleAuthProvider } from "./config";
import Button from "@material-ui/core/Button";
import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import axios from "axios";

const SocialMediaIcons = () => {
  const dispatch = useDispatch();

  const signInUserWithGoogle = () => {
    dispatch(fetchStart());
    try {
      auth
        .signInWithPopup(googleAuthProvider)
        .then(data => {
          dispatch(fetchSuccess());
          dispatch(setAuthUser(data.user));
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Button
        onClick={signInUserWithGoogle}
        variant="contained"
        color="primary"
      >
        Ingresar
      </Button>
    </Box>
  );
};

const Firebase = {
  onRegister: ({ email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        auth
          .createUserWithEmailAndPassword(email, password)
          .then(data => {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  //MOCKING FOR TEST
  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        const user = { email: email };
        dispatch(fetchSuccess());
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(setAuthUser(user));
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        auth
          .signOut()
          .then(data => {
            window.location.reload();
            dispatch(fetchSuccess());
            dispatch(setAuthUser(null));
          })
          .catch(error => {
            // dispatch(fetchError(error.message));
          });
      } catch (error) {
        // dispatch(fetchError(error.message));
      }
    };
  },

  getAuthUser: () => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(false));
      try {
        auth.onAuthStateChanged(authUser => {
          dispatch(fetchSuccess());
          if (authUser) {
            authUser.getIdToken().then(function(idToken) {
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${idToken}`;
              dispatch(
                setAuthUser({
                  uid: authUser.uid,
                  displayName: authUser.displayName,
                  email: authUser.email,
                  photoURL: authUser.photoURL,
                  token: authUser.refreshToken,
                  idToken: idToken
                })
              );
            });
          } else {
            dispatch(updateLoadUser(true));
            dispatch(updateLoadUser(true));
          }
        });
      } catch (error) {
        dispatch(updateLoadUser(true));
        dispatch(fetchError(error.message));
      }
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },

  getSocialMediaIcons: () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <SocialMediaIcons />
      </Box>
    );
  }
};

export default Firebase;
