import { useState, useRef } from "react";
import Instrucciones from "./Instrucciones";
import './tutorial.css'

import React from "react";

export default function Tutorial() {

    const tutorialRef = useRef(null);

    const [displayed, setDisplayed] = useState(false);

    const [height, setHeight] = useState("0px")

    const style ={
        height: height
    }

    const handleDisplayTutorial = () => {
        setDisplayed(true);
    }

    return (
        <>
            <p id="boton-display-tutorial" onClick={handleDisplayTutorial}>¿Cómo agrego mi firma en Gmail?</p>
            <div ref={tutorialRef} style={style} className={displayed ? "tutorial show" : "tutorial"} id="tutorial">
                <div className={displayed ? "side-bar show" : "side-bar"}>
                </div>
                <ul id="list">
                    {displayed ? <Instrucciones setHeight={setHeight} tutorialRef={tutorialRef}></Instrucciones> : ""}
                </ul>
            </div>
        </>
    );
}