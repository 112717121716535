import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";
import {
  ADD_USER,
  ADD_USER_TRACKABLE,
  ADD_USER_APPROVABLE,
  DELETE_BULK_USERS,
  DELETE_USER,
  DELETE_USER_TRACKABLE,
  DELETE_USER_APPROVABLE,
  EDIT_USER,
  GET_USERS,
  GET_ACTIVE_USERS,
  GET_LEADERS,
  GET_USER_DETAIL,
  GET_TRACKABLE_USERS,
  GET_APPROVABLE_USERS,
  SET_USER_DETAILS,
  USER_ALERT,
  GET_JOB_TITLES,
  GET_PROFESSIONAL_SKILLS,
  GET_AREAS,
  GET_HOBBIES
} from "../../@jumbo/constants/ActionTypes";

const userAlert = message => {
  return dispatch => {
    dispatch({
      type: USER_ALERT,
      payload: message
    });
  };
};

export const getUsers = (filterOptions = [], searchTerm = "", callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_USERS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getLeaders = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/leaders`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_LEADERS, payload: data.data });
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getAreas = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/areas`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_AREAS, payload: data.data });
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getProfessionalSkills = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/professional-skills`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PROFESSIONAL_SKILLS, payload: data.data });
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getHobbies = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/hobbies`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_HOBBIES, payload: data.data });
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getJobTitles = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/job-titles`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_JOB_TITLES, payload: data.data });
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getActiveUsers = callbackFun => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users`, {
        params: { status: "ACTIVE" }
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ACTIVE_USERS, payload: data.data });
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getTrackableUsers = (trackableId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users`, {
        params: { trackableId: trackableId }
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TRACKABLE_USERS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getApprovableUsers = (trackableId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users`, {
        params: { approvableId: trackableId }
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_APPROVABLE_USERS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const setCurrentUser = user => {
  return dispatch => {
    dispatch({ type: SET_USER_DETAILS, payload: user });
  };
};

export const addNewUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/users`, user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New user was added successfully."));
          dispatch({ type: ADD_USER, payload: data.data });
          dispatch(getUsers());
          dispatch(userAlert("Usuario creado con éxito"));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));

          dispatch(userAlert("Error creando usuario"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const addUserTrackable = (userId, trackableId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/${userId}/time-trackables/${trackableId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New user was added successfully."));
          dispatch({ type: ADD_USER_TRACKABLE, payload: data.data });
          dispatch(getTrackableUsers(trackableId));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteUserTrackable = (userId, trackableId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/${userId}/time-trackables/${trackableId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New user was added successfully."));
          dispatch({ type: DELETE_USER_TRACKABLE, payload: data.data });
          dispatch(getTrackableUsers(trackableId));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const addUserApprovable = (userId, trackableId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/${userId}/approvables/${trackableId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New approver was added successfully."));
          dispatch({ type: ADD_USER_APPROVABLE, payload: data.data });
          dispatch(getApprovableUsers(trackableId));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteUserApprovable = (userId, trackableId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/${userId}/approvables/${trackableId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New approver was removed successfully."));
          dispatch({ type: DELETE_USER_APPROVABLE, payload: data.data });
          dispatch(getApprovableUsers(trackableId));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess("Email has been sent to user successfully"));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/api/users/${user.id}`, user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected user was updated successfully."));
          dispatch({ type: EDIT_USER, payload: data.data });
          dispatch(getUsers());
          dispatch(userAlert("Usuario actualizado"));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));

          dispatch(userAlert("Error actualizando usuario"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateUserStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/update-status`,
        data
      )
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess("User status was updated successfully."));
          dispatch({ type: EDIT_USER, payload: response.data });
          dispatch(getUsers());
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/users/bulk-delete", { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess("Selected users were deleted successfully."));
          dispatch({ type: DELETE_BULK_USERS, payload: userIds });
          dispatch(getUsers());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteUser = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT}/api/users/${id}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected user was deleted successfully."));
          dispatch({ type: DELETE_USER, payload: data.data });
          dispatch(getUsers());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getUserDetail = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/${id}/profile`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_USER_DETAIL, payload: data.data });
        } else {
          dispatch(fetchError("Something went wrong"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Something went wrong"));
      });
  };
};

/* export const getSignature = (id, fileName) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/${id}/signature`)
      .then(data => {
        if (data.status === 200) {
          const aElement = document.createElement("a");
          aElement.download = fileName.replace(/\s+/g, "") + ".html";
          const blob1 = new Blob([data.data], { type: "text/html" });
          aElement.href = URL.createObjectURL(blob1);
          aElement.click();
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError("Hubo un error descargando la firma"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Hubo un error en el servidor"));
      });
  };
}; */
