import {
  ADD_USER,
  DELETE_BULK_USERS,
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  GET_LEADERS,
  GET_USER_DETAIL,
  GET_TRACKABLE_USERS,
  GET_APPROVABLE_USERS,
  GET_ACTIVE_USERS,
  SET_USER_DETAILS,
  GET_AREAS,
  GET_HOBBIES,
  GET_PROFESSIONAL_SKILLS,
  USER_ALERT,
  GET_JOB_TITLES
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  users: [],
  activeUsers: [],
  trackableUsers: [],
  approvableUsers: [],
  leaders: [],
  professionalSkills: [],
  areas: [],
  hobbies: [],
  currentUser: null,
  userDetail: null,
  userAlert: null,
  jobTitles: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS: {
      return {
        ...state,
        users: action.payload
      };
    }
    case GET_LEADERS: {
      return {
        ...state,
        leaders: action.payload
      };
    }
    case GET_PROFESSIONAL_SKILLS: {
      return {
        ...state,
        professionalSkills: action.payload
      };
    }
    case GET_HOBBIES: {
      return {
        ...state,
        hobbies: action.payload
      };
    }
    case GET_AREAS: {
      return {
        ...state,
        areas: action.payload
      };
    }
    case GET_USER_DETAIL: {
      return { ...state, userDetail: action.payload };
    }
    case GET_TRACKABLE_USERS: {
      return {
        ...state,
        trackableUsers: action.payload
      };
    }
    case GET_APPROVABLE_USERS: {
      return {
        ...state,
        approvableUsers: action.payload
      };
    }
    case GET_ACTIVE_USERS: {
      return {
        ...state,
        activeUsers: action.payload
      };
    }
    case SET_USER_DETAILS: {
      return {
        ...state,
        currentUser: action.payload
      };
    }
    case ADD_USER: {
      return {
        ...state,
        users: [action.payload, ...state.users]
      };
    }
    case EDIT_USER: {
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.id ? action.payload : user
        )
      };
    }
    case DELETE_USER: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload)
      };
    }
    case DELETE_BULK_USERS: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id))
      };
    }
    case USER_ALERT: {
      return {
        ...state,
        userAlert: action.payload
      };
    }
    case GET_JOB_TITLES: {
      return {
        ...state,
        jobTitles: action.payload
      };
    }
    default:
      return state;
  }
};
