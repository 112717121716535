import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import AppTextInput from "../../../../@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  addNewTraining,
  getTrainings,
  updateTraining
} from "../../../../redux/actions/Trainings";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const AddEditTraining = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const currentTraining = useSelector(
    ({ trainingsReducer }) => trainingsReducer.currentTraining
  );
  const { trainings } = useSelector(({ trainingsReducer }) => trainingsReducer);

  const [name, setName] = useState(currentTraining ? currentTraining.name : "");
  const [nameError, setNameError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrainings());
  }, [dispatch]);

  useEffect(() => {
    if (currentTraining) {
      setName(currentTraining.name);
    }
  }, [currentTraining]);

  const replaceSpecialCharacters = text => {
    const text1 = text.replaceAll("/", "-");
    const text3 = text1.replaceAll("¿", "-");
    const text4 = text3.replaceAll("?", "-");
    const text5 = text4.replaceAll("¡", "-");
    const text6 = text5.replaceAll("!", "-");
    const text7 = text6.replaceAll("[", "-");
    const text8 = text7.replaceAll("]", "-");
    const text9 = text8.replaceAll("{", "-");
    const text10 = text9.replaceAll("}", "-");
    const text11 = text10.replaceAll("*", "-");
    const text12 = text11.replaceAll('"', "");
    const text13 = text12.replaceAll("'", "");

    return text13;
  };

  useEffect(() => {
    setName(replaceSpecialCharacters(name));
  }, [name]);

  const onSubmitClick = () => {
    const activeFilter = trainings.filter(
      training => training.status === "ACTIVE"
    );
    const suspendedFilter = trainings.filter(
      training => training.status === "SUSPENDED"
    );
    const activeNames = activeFilter.map(training => training.name);
    const suspendedNames = suspendedFilter.map(training => training.name);
    if (name == null || name === "") {
      setNameError("Nombre requerido");
    } else if (activeNames.includes(name)) {
      setNameError("Existe una capacitación activa con este nombre");
    } else if (suspendedNames.includes(name)) {
      setNameError("Existe una capacitación suspendida con este nombre");
    } else {
      onTrainingSave();
    }
  };

  const onTrainingSave = phoneNumbers => {
    if (currentTraining) {
      const id = currentTraining.id;
      const trainingDetail = {
        id,
        name
      };
      dispatch(
        updateTraining(trainingDetail, () => {
          onCloseDialog();
        })
      );
    } else {
      const trainingDetail = {
        name
      };
      dispatch(
        addNewTraining(trainingDetail, () => {
          onCloseDialog();
        })
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentTraining ? "Editar Capacitación" : "Crear Capacitación"}
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            id="txt-nombreCapacitacion"
            fullWidth
            variant="outlined"
            label={<Typography variant="h6">Nombre</Typography>}
            value={name}
            onChange={e => {
              setName(e.target.value);
              setNameError("");
            }}
            helperText={nameError}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} id="btn-cancelar">
            Cancel
          </Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmitClick}
              id="btn-guardarCambios"
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditTraining;

AddEditTraining.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
