import {
  ADD_ROLE,
  DELETE_BULK_ROLES,
  DELETE_ROLE,
  EDIT_ROLE,
  GET_ROLES,
  GET_ROLE,
  SET_ROLE_DETAILS,
  ROLE_ALERT
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  roles: [],
  currentRole: null,
  roleAlert: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES: {
      return {
        ...state,
        roles: action.payload
      };
    }
    case GET_ROLE: {
      return {
        ...state,
        currentRole: action.payload
      };
    }
    case SET_ROLE_DETAILS: {
      return {
        ...state,
        currentRole: action.payload
      };
    }
    case ADD_ROLE: {
      return {
        ...state,
        roles: [ action.payload, ...state.roles ]
      };
    }
    case EDIT_ROLE: {
      return {
        ...state,
        roles: state.roles.map(
          role => (role.id === action.payload.id ? action.payload : role)
        )
      };
    }
    case DELETE_ROLE: {
      return {
        ...state,
        roles: state.roles.filter(role => role.id !== action.payload)
      };
    }
    case DELETE_BULK_ROLES: {
      return {
        ...state,
        roles: state.roles.filter(role => !action.payload.includes(role.id))
      };
    }
    case ROLE_ALERT: {
      return {
        ...state,
        roleAlert: action.payload
      };
    }
    default:
      return state;
  }
};
