import {
  GET_TIME_TRACKING_ITEMS,
  GET_TIME_TRACKING_ITEMS_HISTORY,
  GET_APPROVABLES_TIME_TRACKING_ITEMS,
  GET_ADMIN_TIME_TRACKING_ITEMS,
  GET_TIME_TRACKING_ITEMS_BY_ID,
  GET_TIME_TRACKING_ITEMS_BY_LEADER
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  timeTrackingItems: [],
  timeTrackingItemsHistory: [],
  approvableTimeTrackingItems: [],
  adminTimeTrackingItems: [],
  timeTrackingItemsById: [],
  timeTrackingItemsByLeader: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TIME_TRACKING_ITEMS: {
      return {
        ...state,
        timeTrackingItems: action.payload
      };
    }
    case GET_TIME_TRACKING_ITEMS_HISTORY: {
      return {
        ...state,
        timeTrackingItemsHistory: action.payload
      };
    }
    case GET_APPROVABLES_TIME_TRACKING_ITEMS: {
      return {
        ...state,
        approvableTimeTrackingItems: action.payload
      };
    }
    case GET_ADMIN_TIME_TRACKING_ITEMS: {
      return {
        ...state,
        adminTimeTrackingItems: action.payload
      };
    }
    case GET_TIME_TRACKING_ITEMS_BY_ID: {
      return {
        ...state,
        timeTrackingItemsById: action.payload
      };
    }
    case GET_TIME_TRACKING_ITEMS_BY_LEADER: {
      return {
        ...state,
        timeTrackingItemsByLeader: action.payload
      };
    }
    default:
      return state;
  }
};
