import Busqueda from "./busqueda/Busqueda";
import Encabezado from "./encabezado/Encabezado";
import Tutorial from "./tutorial/Tutorial";
import ListaEmpleados from './empleados/ListaEmpleados'
import DisplayFirma from "./DisplayFirma";

import { useState, useEffect } from "react";
import axios from "axios";

import React from "react";


import './busqueda-firmas.css'

export default function BusquedaFirmas() {
    const [firmaDisplayed, setFirmaDisplayed] = useState(false);

    const [firma, setFirma] = useState();

    const [empleadosFiltrados, setEmpleadosFiltrados] = useState();

    const [empleados, setEmpleados] = useState([]);

    const [searchValue, setSearchValue] = useState("");

    function handleChange(event) {
        let valor = event.target.value;

        //Se quita el primer espacio
        valor.trimStart();

        // Reemplazar varios espacios seguidos por uno solo
        valor = valor.replace(/\s{2,}/g, ' ');

        // Solo se pueden ingresar letras o espacios
        if (/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/.test(valor)) {
            valor = valor.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, '');
        }

        // Actualizar el input
        setSearchValue(valor)

        setEmpleadosFiltrados(
            empleados.filter(empleado =>
                empleado.first_name.toLowerCase().includes(valor.toLowerCase())
                ||
                empleado.last_name.toLowerCase().includes(valor.toLowerCase()))
        )
    }

    function generarFirma(nombre, apellido, rol) {
        axios.post("http://192.168.45.49:8080/firma", {
            "nombre": nombre,
            "apellido": apellido,
            "puesto": rol
        })
            .then(response => {
                if (response.status === 200)
                    setFirma(response.data)
                else {
                    throw new Error(response.data || 'Error desconocido');
                }
            })
            .catch(error => {
                let mensajeError = "";

                if (error.response) {
                    mensajeError = error.response.data;
                }
                else {
                    mensajeError = error.message;
                }

                console.error("Error generando la firma: " + mensajeError)
                setFirma(undefined)
            })
            .then(() => { setFirmaDisplayed(true); })
    }

    useEffect(() => {
        getEmpleados();
    }, [])

    function getEmpleados() {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/users`, {
            params: { status: "ACTIVE" }
          })
            .then(response => {
                if (response.status === 200) {
                    setEmpleados(response.data);
                    setEmpleadosFiltrados(response.data);
                }
            })
    }

    return (
        <div className={"body-firmas"}>
            <section className="main">
                <Encabezado></Encabezado>
                <Tutorial></Tutorial>
                <Busqueda handleChange={handleChange} searchValue={searchValue}></Busqueda>
                <ListaEmpleados generarFirma={generarFirma} empleados={empleadosFiltrados}></ListaEmpleados>
                {firmaDisplayed && <DisplayFirma firma={firma} setFirmaDisplayed={setFirmaDisplayed}></DisplayFirma>}
            </section>
        </div>
    );
}