import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { deleteBulkProjects } from "../../../../redux/actions/Projects";
import ConfirmDialog from "../../../../@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import CmtSearch from "@coremat/CmtSearch";

const ProjectTableToolbar = ({
  selected,
  setSelected,
  onProjectAdd,
  searchTerm,
  setSearchTerm
}) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  /* const [anchorEl, setAnchorEl] = React.useState(null); */

  const dispatch = useDispatch();

  /*  const handleClose = () => {
    setAnchorEl(null);
  }; */

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteBulkProjects(selected, () => setSelected([])));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <React.Fragment>
      <Toolbar className={clsx(classes.root, {})}>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Proyectos{" "}
          <Button
            color="primary"
            onClick={() => onProjectAdd(true)}
            id="btn-nuevoProyecto"
          >
            Agregar Proyecto
          </Button>
        </Typography>

        <React.Fragment>
          <CmtSearch
            id="btn-buscarUsuario"
            onChange={e => setSearchTerm(e.target.value)}
            value={searchTerm}
            border={false}
            onlyIcon
          />
        </React.Fragment>
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirmación de borrado`}
        content={"Estás seguro de querer borrar a este usuario?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

ProjectTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  onProjectAdd: PropTypes.func
};

export default React.memo(ProjectTableToolbar);
