import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";
import {
  ADD_USER_ACTIVITY,
  GET_CURRENT_USER_ACTIVITIES,
  GET_USER_ACTIVITIES,
  GET_USER_ACTIVITY,
  SET_USER_ACTIVITY_DETAILS,
  UPDATE_USER_ACTIVITY,
  USER_ACTIVITY_ALERT
} from "../../@jumbo/constants/ActionTypes";

const userActivityAlert = message => {
  return dispatch => {
    dispatch({
      type: USER_ACTIVITY_ALERT,
      payload: message
    });
  };
};

export const getUserActivities = (
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/company-activities`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_USER_ACTIVITIES, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError("There was something issue in responding server"));
      });
  };
};

export const addNews = (noticia, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/company-activities`,
        noticia
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: ADD_USER_ACTIVITY, payload: data.data });
          dispatch(getUserActivities());
          dispatch(userActivityAlert("¡Noticia publicada con éxito!"));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(userActivityAlert("Hubo un error publicando la noticia"));
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(userActivityAlert("Hubo un error publicando la noticia"));
        dispatch(fetchError("Hubo un error publicando la noticia"));
      });
  };
};

export const getUserActivity = (id, userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/company-activities/${id}/${userId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_USER_ACTIVITY, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError("There was something issue in responding server"));
      });
  };
};

export const deleteUserActivity = (activityId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT}/api/company-activities/${activityId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("The activity was deleted successfully."));
          dispatch(getUserActivities());
          if (callbackFun) callbackFun();
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError("There was something issue in responding server"));
      });
  };
};

export const setCurrentActivity = activity => {
  return dispatch => {
    dispatch({ type: SET_USER_ACTIVITY_DETAILS, payload: activity });
  };
};

export const getCurrentUserActivities = (
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/company-activities/current`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CURRENT_USER_ACTIVITIES, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError("There was something issue in responding server"));
      });
  };
};

export const updateNew = (noticiaId, noticia, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/company-activities/${noticiaId}`,
        noticia
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: UPDATE_USER_ACTIVITY, payload: data.data });
          dispatch(getCurrentUserActivities());
          dispatch(getUserActivities());
          dispatch(userActivityAlert("¡Noticia actualizada con éxito!"));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(userActivityAlert("Hubo un error actualizando la noticia"));
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(userActivityAlert("Hubo un error actualizando la noticia"));
        dispatch(fetchError("Hubo un error actualizando la noticia"));
      });
  };
};
