import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";
import {
  ADD_TRAINING,
  DELETE_BULK_TRAININGS,
  DELETE_TRAINING,
  EDIT_TRAINING,
  GET_TRAININGS,
  GET_TRAINING,
  SET_TRAINING_DETAILS
} from "../../@jumbo/constants/ActionTypes";

export const getTrainings = (
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/trainings`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TRAININGS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getTraining = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/trainings/${id}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TRAINING, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const setCurrentTraining = training => {
  return dispatch => {
    dispatch({ type: SET_TRAINING_DETAILS, payload: training });
  };
};

export const addNewTraining = (training, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/trainings`, training)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New training was added successfully."));
          dispatch({ type: ADD_TRAINING, payload: data.data });
          dispatch(getTrainings());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const sentMailToTraining = () => {
  return dispatch => {
    dispatch(fetchSuccess("Email has been sent to training successfully"));
  };
};

export const updateTraining = (training, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/trainings/${training.id}`,
        training
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected training was updated successfully."));
          dispatch({ type: EDIT_TRAINING, payload: data.data });
          dispatch(getTrainings());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateTrainingStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/trainings/update-status", data)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess("Training status was updated successfully."));
          dispatch({ type: EDIT_TRAINING, payload: response.data });
          dispatch(getTrainings());
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteBulkTrainings = (trainingIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/trainings/bulk-delete", { trainingIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(
            fetchSuccess("Selected trainings were deleted successfully.")
          );
          dispatch({ type: DELETE_BULK_TRAININGS, payload: trainingIds });
          dispatch(getTrainings());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteTraining = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT}/api/trainings/${id}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected training was deleted successfully."));
          dispatch({ type: DELETE_TRAINING, payload: id });
          dispatch(getTrainings());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};
