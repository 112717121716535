import React, { useEffect } from "react";
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";
import ActivitiesTimeLine from "./ActivitiesTimeLine";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserActivities } from "../../../../redux/actions/UserActivities";
import { Box, CardMedia, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: "relative",
    height: "485px"
  },
  scrollbarRoot: {
    height: 350,
    marginRight: -24,
    paddingRight: 24,
    marginLeft: -24,
    paddingLeft: 24,
    marginTop: -5,
    paddingTop: 5
  },
  scrollbarNew: {
    display: "flex",
    justifyConten: "center",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    height: 400,
    marginRight: -24,
    paddingRight: 24,
    marginLeft: -24,
    paddingLeft: 24,
    marginTop: -5,
    paddingTop: 5,
    backgroundColor: "secondary"
  },
  media: {
    padding: "40px",
    maxHeight: "300px",
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      height: 350
    }
  }
}));

const RecentActivities = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { currentUserActivities } = useSelector(
    ({ userActivitiesReducer }) => userActivitiesReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUserActivities([], "", () => {}));
  }, [dispatch]);

  const newsByAreaOrMail = currentUserActivities
    .filter(act => {
      let acts;
      if (
        !!act.recipient_areas &&
        act.recipient_areas.some(area => area.name === authUser.area.name)
      ) {
        acts = act;
      } else if (
        !!act.recipient_users &&
        act.recipient_users.some(user => user.email === authUser.email)
      ) {
        acts = act;
      }
      return acts;
    })
    .map(act => act);

  const currentActivities = newsByAreaOrMail.sort((a, b) => {
    if (a.id === b.id) {
      return 0;
    }
    if (a.id > b.id) {
      return -1;
    }
    return 1;
  });

  const logoUrl = "/images/Logo20CDT.svg";

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader title="Actividades Recientes" />
      <CmtCardContent>
        {!!currentActivities.length ? (
          <PerfectScrollbar className={classes.scrollbarRoot}>
            <ActivitiesTimeLine data={currentActivities} />
          </PerfectScrollbar>
        ) : (
          <PerfectScrollbar className={classes.scrollbarNew}>
            <Typography>¡Bienvenidos a la nueva Intranet de CDT!</Typography>
            <Typography>
              En esta sección verás las últimas noticias disponibles
            </Typography>
            <Box display="flex" justifyContent="center">
              <CardMedia
                className={classes.media}
                component="img"
                src={logoUrl}
                alt="something"
              />
            </Box>
          </PerfectScrollbar>
        )}
      </CmtCardContent>
    </CmtCard>
  );
};

export default RecentActivities;
