import React from "react";
import clsx from "clsx";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { alpha, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InfoIcon from "@material-ui/icons/Info";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  actionsRoot: {
    transition: "all 0.3s ease",
    transform: "translateX(-110%)",
    opacity: 0,
    visibility: "hidden",
    marginLeft: 10
  },
  hoverAction: {
    "&:hover $actionsRoot": {
      transform: "translateX(0%)",
      opacity: 1,
      visibility: "visible"
    }
  },
  tableRowRoot: {
    position: "relative",
    transition: "all .2s",
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: "translateY(-4px)",
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: "transparent",
      "& $tableCellRoot": {
        color: theme.palette.text.primary,
        "&:last-child": {
          color: theme.palette.error.main
        },
        "&.success": {
          color: theme.palette.success.main
        }
      }
    },
    "&:last-child": {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`
    }
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    borderBottom: "0 none",
    "&:first-child": {
      paddingLeft: 24
    },
    "&:last-child": {
      textAlign: "right",
      paddingRight: 24
    },
    "& .Cmt-avatar-more": {
      fontSize: 10,
      color: theme.palette.primary.main
    }
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary
  },
  blockRoot: {
    display: "block",
    fontSize: 14
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14
  }
}));

const TableItem = ({ row }) => {
  const classes = useStyles();
  let history = useHistory();

  const handleProjectView = () => {
    history.push("/proyectos/" + row.id);
  };

  const actions = [
    {
      icon: <InfoIcon />,
      label: (
        <Typography
        // variant="h6"
        >
          Ver Proyecto
        </Typography>
      ),
      onClick: handleProjectView
    }
  ];

  return (
    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
      <TableCell className={classes.tableCellRoot}>
        <Typography variant="h6">{row.name}</Typography>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Typography variant="h6">{row.area}</Typography>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <CmtDropdownMenu
          TriggerComponent={
            <Tooltip title="Más">
              <IconButton size="small">
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          }
          items={actions}
        />
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
