import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { alpha } from "@material-ui/core/styles";
import CmtImage from "@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import AuthWrapper from "@jumbo/components/Common/authComponents/AuthWrapper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch } from "react-redux";
import ProfesionalDetails from "./ProfesionalDetails";
import PersonalDetails from "./PersonalDetails";
import { StorageMethods, StorageContentTypes } from "services/storage";
import { CurrentStorageMethod } from "@jumbo/constants/AppConstants";
import {
  getProfessionalSkills,
  updateUser,
  getHobbies
} from "../../../../redux/actions/Users";
import { setAuthUser } from "../../../../redux/actions/Auth";
import { isValidName, isValidStudies } from "@jumbo/utils/commonHelper";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  textCapital: {
    textTransform: "capitalize"
  },
  textAcc: {
    textAlign: "center",
    "& a": {
      marginLeft: 4
    }
  },
  alrTextRoot: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "right"
    }
  },
  root: {
    width: "100%"
  },
  input: {
    display: "none"
  },
  button: {
    marginRight: theme.spacing(2)
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  adviseBox: {
    borderRadius: "20px",
    border: "1px solid #ed3c1c",
    padding: "10px"
  },
  advise: {
    color: "#ed3c1c"
  }
}));

const UserProfileEdit = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { userImageUploadedUrl } = useSelector(({ storage }) => storage);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [step, setStep] = useState(0);

  const [showAdvise, setShowAdvise] = useState(true);

  const [user, setUser] = useState({
    id: authUser.id,
    firstName: authUser["first_name"],
    lastName: authUser["last_name"],
    residence: authUser.residence,
    studies: authUser.studies,
    interests: authUser.interests,
    hobbies: authUser.hobbies,
    profilePic: authUser["profile_pic"]
  });

  const {
    firstName,
    lastName,
    residence,
    studies,
    interests,
    hobbies,
    profilePic
  } = user;
  const values = {
    firstName,
    lastName,
    residence,
    studies,
    interests,
    hobbies,
    profilePic
  };

  useEffect(() => {
    dispatch(getProfessionalSkills([], "", () => {}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getHobbies([], "", () => {}));
  }, [dispatch]);

  useEffect(() => {
    if (authUser.status === "ONBOARDING_REQUIRED") {
      setShowAdvise(true);
    } else {
      setShowAdvise(false);
    }
  }, [authUser]);

  const steps = ["Info Personal", "Datos Profesionales"];

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const handleChange = input => e => {
    setError("");
    setSuccess("");
    let currentUser = Object.assign({}, user);
    currentUser[input] = e.target.value;
    setUser(currentUser);
  };

  const handleInterests = newValue => {
    let currentUser = Object.assign({}, user);
    currentUser["interests"] = newValue;
    setUser(currentUser);
  };

  const handleHobbies = newValue => {
    let currentUser = Object.assign({}, user);
    currentUser["hobbies"] = newValue;
    setUser(currentUser);
  };

  const handlePic = pic => {
    dispatch(
      StorageMethods[CurrentStorageMethod].onUpload(
        pic,
        StorageContentTypes.USER_IMAGE_UPLOAD
      )
    );
    let currentUser = Object.assign({}, user);
    currentUser[profilePic] = userImageUploadedUrl;
    setUser(currentUser);
  };

  const validateData = () => {
    if (!isValidName(user.firstName)) {
      setError("El nombre ingresado no es válido");
    } else if (!isValidName(user.lastName)) {
      setError("El apellido ingresado no es válido");
    } else if (!isValidStudies(user.residence)) {
      setError("La residencia ingresada no es válida");
    } else if (!isValidStudies(user.studies)) {
      setError("Los estudios ingresados no son válidos");
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const profileImageURL = userImageUploadedUrl
      ? userImageUploadedUrl
      : user.profilePic;
    const userToUpdate = {
      id: user.id,
      profile_pic: profileImageURL,
      first_name: user.firstName,
      last_name: user.lastName,
      residence: user.residence,
      studies: user.studies,
      interests: user.interests,
      hobbies: user.hobbies,
      status: "ACTIVE"
    };
    dispatch(
      updateUser(userToUpdate, () => {
        MySwal.fire({
          title: <strong>Listo</strong>,
          html: <i>Tu perfil se actualizó correctamente</i>,
          icon: "success"
        });
      })
    );
    setTimeout(function() {
      //Start the timer
      dispatch(setAuthUser(authUser));
    }, 2000);
    setTimeout(function() {
      //Start the timer
      history.push("");
    }, 3000);
  };

  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return (
          <PersonalDetails
            nextStep={nextStep}
            handleChange={handleChange}
            values={values}
            uploadedPic={userImageUploadedUrl}
            classes={classes}
            handlePic={handlePic}
          />
        );

      case 1:
        return (
          <ProfesionalDetails
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange}
            handleInterests={handleInterests}
            values={values}
            uploadedPic={userImageUploadedUrl}
            validateData={validateData}
            handleHobbies={handleHobbies}
            success={success}
            error={error}
            classes={classes}
          />
        );
      default:
      // do nothing
    }
  };

  return (
    <AuthWrapper variant="standard">
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={"/images/logo.png"} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Creemos tu perfil.
        </Typography>
        {showAdvise === true && (
          <>
            <Box className={classes.adviseBox}>
              <Typography component="span" className={classes.advise}>
                Completá todos los datos para que tu perfil se active
                correctamente.
              </Typography>
            </Box>
            <br></br>
          </>
        )}
        <Typography component="span">
          Toda tu información la podrás editar cuando quieras accediendo a tu
          perfil de usuario.
        </Typography>
        <Stepper activeStep={step}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {conditionalComponent()}
      </Box>
    </AuthWrapper>
  );
};
export default UserProfileEdit;
