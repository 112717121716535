import DisplayBusqueda from "./DisplayBusqueda";
import './busqueda.css'
import React from "react";

export default function Busqueda({handleChange, searchValue}) {
    return (
        <article className="busqueda">
            <DisplayBusqueda handleChange={handleChange} searchValue={searchValue}></DisplayBusqueda>
        </article>
    )
}