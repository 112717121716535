import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import { useHasPrivilege } from "routes/checkPermission";
import { getLicences } from "redux/actions/Licences";
import { HORAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import Forbidden from "@jumbo/components/Forbidden";
import GridContainer from "@jumbo/components/GridContainer";
import Restricted from "services/permissionProvider/Restricted";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddOneDay from "./AddOneDay";
import AddMoreDays from "./AddMoreDays";
import moment from "moment";
import { getTimeTrackingItems } from "redux/actions/TimeTrackingItems";
import { addHoliday, getHolidays } from "redux/actions/Holidays";
import CustomHolidayLoader from "./CustomHolidayLoader";

const AddHolidayHours = () => {
  const { licences } = useSelector(({ licencesReducer }) => licencesReducer);
  const { holidays } = useSelector(({ holidaysReducer }) => holidaysReducer);
  const MySwal = withReactContent(Swal);

  const [asignarDias, setAsignarDias] = useState("");
  const [licencia, setLicencia] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const [dataError, setDataError] = useState(false);
  const [fechaInicioValida, setFechaInicioValida] = useState("");
  const [fechaFinValida, setFechaFinValida] = useState("");

  const [modFeriado, setModFeriado] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const canDispatch = useHasPrivilege(HORAS_EDITAR);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canDispatch) {
      dispatch(getLicences());
    }
  }, [dispatch, canDispatch]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getTimeTrackingItems());
    }
  }, [dispatch, canDispatch, modFeriado]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getHolidays(() => {}));
    }
  }, [dispatch, updateTable, canDispatch]);

  const filteredLicences = licences.filter(
    l => l.name === "Asueto - Día no laborable" || l.name === "Feriado"
  );

  const handleChange = (e, name) => {
    switch (name) {
      case "unDia":
        setFechaInicio("");
        setFechaFin("");
        setFechaInicioValida("");
        setFechaFinValida("");
        setAsignarDias(e);
        break;
      case "masDeUnDia":
        setFechaInicio("");
        setFechaFin("");
        setFechaInicioValida("");
        setFechaFinValida("");
        setAsignarDias(e);
        break;
      case "tipoLicencia":
        setLicencia(e);
        break;
      default:
        setDataError(false);
    }
  };

  const clearInputs = () => {
    setAsignarDias("");
    setLicencia("");
    setFechaInicio("");
    setFechaFin("");
    setDataError(false);
    setFechaInicioValida("");
    setFechaFinValida("");
  };

  const guardarFechas = fechas => {
    dispatch(
      addHoliday(fechas, () => {
        clearInputs();
        setModFeriado(!modFeriado);
        setUpdateTable(!updateTable);
        MySwal.fire("¡Listo!", "", "success");
      })
    );
  };

  const getFormatedDate = date => {
    const splitDate = date.slice(0, 10);
    const formatedDate = moment(splitDate).format("DD/MM/YYYY");
    return formatedDate;
  };

  const confirmarFeriados = fechas => {
    //revisar esta validacion
    const existingDates = fechas.map(f => {
      const dates = holidays.filter(
        h => getFormatedDate(h.item_date) === getFormatedDate(f.item_date)
      );
      return dates;
    });

    const filterDates = existingDates.filter(e => {
      return e.length > 0;
    });

    const formatDates = filterDates.map(e => {
      return getFormatedDate(e[0].item_date);
    });

    if (formatDates.length > 0) {
      MySwal.fire({
        title: <strong>¡Cuidado!</strong>,
        html: (
          <p>
            Ya existen los siguientes feriados/asuetos:{" "}
            {formatDates.map(f => (
              <Typography key={f}>{f}</Typography>
            ))}
          </p>
        ),
        icon: "error"
      });
    } else {
      if (asignarDias === "unDia") {
        MySwal.fire({
          title: (
            <strong>Por favor, confirma que los datos sean correctos</strong>
          ),
          html: (
            <Typography>
              Usuarios: Todos los activos. <br />
              Licencia: {licencia.name} <br />
              Fecha: {moment(fechaInicio).format("DD/MM/YYYY")}
              <br />
              Horas: 08:00 <br />
              Estado: Aprobadas.
            </Typography>
          ),
          confirmButtonText: "Aceptar",
          showCancelButton: true,
          cancelButtonText: "Editar"
        }).then(result => {
          if (result.isConfirmed) {
            guardarFechas(fechas);
          }
        });
      } else {
        MySwal.fire({
          title: (
            <strong>Por favor, confirma que los datos sean correctos</strong>
          ),
          html: (
            <Typography>
              Usuarios: Todos los activos. <br />
              Licencia: {licencia.name} <br />
              Fecha: {moment(fechaInicio).format("DD/MM/YYYY")} al{" "}
              {moment(fechaFin).format("DD/MM/YYYY")} inclusive
              <br />
              Horas: 08:00 <br />
              Estado: Aprobadas.
            </Typography>
          ),
          confirmButtonText: "Aceptar",
          showCancelButton: true,
          cancelButtonText: "Editar"
        }).then(result => {
          if (result.isConfirmed) {
            guardarFechas(fechas);
          }
        });
      }
    }
  };

  const crearFeriados = () => {
    let fechas = [];

    if (asignarDias === "unDia") {
      const fecha = moment(fechaInicio);
      fechas = [
        {
          trackable: licencia,
          item_date: fecha.toISOString()
        }
      ];
      confirmarFeriados(fechas);
    }

    if (asignarDias === "masDeUnDia") {
      let fecha1 = moment(fechaInicio);
      let fecha2 = moment(fechaFin);
      const lastDayPlusOneDay = fecha2.add(1, "days");
      while (fecha1 < lastDayPlusOneDay) {
        if (fecha1.format("DD/MM/YYYY") !== fecha2.format("DD/MM/YYYY")) {
          fechas = [
            ...fechas,
            {
              trackable: licencia,
              item_date: fecha1.toISOString()
            }
          ];
          fecha1 = moment(fecha1).add(1, "days");
        }
      }
      confirmarFeriados(fechas);
    }
  };

  const disableButton = () => {
    if (asignarDias === "unDia") {
      if (licencia !== null && licencia !== "" && fechaInicioValida === true) {
        return false;
      } else {
        return true;
      }
    } else if (asignarDias === "masDeUnDia") {
      if (
        licencia !== null &&
        licencia !== "" &&
        fechaInicioValida === true &&
        fechaFinValida === true
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  //console.log(holidays);

  return (
    <Restricted to={HORAS_EDITAR} fallback={Forbidden} Edit={true}>
      <Box>
        <CmtCard
          style={{
            width: "800px",
            margin: "0 auto",
            border: "2px solid #83b0fc",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" justifyContent="center">
              <CmtCardHeader title={`Carga de feriados/asuetos`} />
            </Box>

            <Box>
              <Divider />
            </Box>
          </Box>

          <Box>
            <Card>
              <CardContent>
                <GridContainer
                  style={{
                    display: "flex",
                    marginTop: "15px",
                    flexDirection: "row",
                    justifyContent: "center"
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center"
                    }}
                  >
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <Box padding="12px">
                        <TextField
                          variant="outlined"
                          id="cantidadDias"
                          select
                          label="Cantidad de días"
                          style={{ width: "338px" }}
                          value={asignarDias}
                          helperText={
                            dataError ? "Debes seleccionar una opción" : ""
                          }
                          onChange={e =>
                            handleChange(e.target.value, e.target.value)
                          }
                        >
                          <MenuItem value={"unDia"} id="item-unDia">
                            Un día
                          </MenuItem>
                          <MenuItem value={"masDeUnDia"} id="item-dias">
                            Más de un día
                          </MenuItem>
                        </TextField>
                      </Box>

                      <Box padding="12px">
                        <TextField
                          variant="outlined"
                          id="licencia"
                          select
                          label="Licencia"
                          style={{ width: "338px" }}
                          value={licencia}
                          helperText={
                            dataError ? "Debes seleccionar una opción" : ""
                          }
                          onChange={e =>
                            handleChange(e.target.value, "tipoLicencia")
                          }
                        >
                          {filteredLicences.map(l => (
                            <MenuItem
                              key={l.id}
                              name={l.name}
                              value={l}
                              id={l.id}
                            >
                              {l.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "left" }}>
                      {asignarDias === "unDia" ? (
                        <AddOneDay
                          fechaInicio={fechaInicio}
                          setFechaInicio={setFechaInicio}
                          fechaInicioValida={fechaInicioValida}
                          setFechaInicioValida={setFechaInicioValida}
                        />
                      ) : (
                        asignarDias === "masDeUnDia" && (
                          <AddMoreDays
                            fechaInicio={fechaInicio}
                            setFechaInicio={setFechaInicio}
                            fechaFin={fechaFin}
                            setFechaFin={setFechaFin}
                            fechaInicioValida={fechaInicioValida}
                            setFechaInicioValida={setFechaInicioValida}
                            fechaFinValida={fechaFinValida}
                            setFechaFinValida={setFechaFinValida}
                          />
                        )
                      )}
                    </Box>
                  </Box>

                  <Button
                    style={{
                      width: "300px",
                      marginTop: "40px",
                      marginBottom: "40px",
                      padding: "10px",
                      borderRadius: "16px"
                    }}
                    id="btn-guardarCambios"
                    variant="contained"
                    color="primary"
                    disabled={disableButton()}
                    onClick={() => crearFeriados()}
                  >
                    Guardar
                  </Button>
                </GridContainer>

                <Box mt="50px">
                  <CmtCardHeader title={`Historial de feriados/asuetos`} />
                  <CustomHolidayLoader
                    updateTable={updateTable}
                    setUpdateTable={setUpdateTable}
                    filteredLicences={filteredLicences}
                    HORAS_EDITAR={HORAS_EDITAR}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </CmtCard>
      </Box>
    </Restricted>
  );
};
export default AddHolidayHours;
