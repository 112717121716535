import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { timeFromNow } from "../../../../@jumbo/utils/dateHelper";
import {
  Block,
  CheckCircleOutline,
  Edit,
  MoreHoriz,
  Style,
  Visibility
} from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { sentMailToUser, updateUser } from "../../../../redux/actions/Users";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CmtAvatar from "@coremat/CmtAvatar";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const statusTranslations = {
  ONBOARDING_REQUIRED: "Completar Perfil",
  ACTIVE: "Activo",
  SUSPENDED: "Suspendido"
};

const translateStatus = status => {
  return statusTranslations[status];
};

const getUserActions = user => {
  let actions = [];

  user.status === "SUSPENDED"
    ? (actions = [])
    : (actions = [
        { action: "view", label: "Ver", icon: <Visibility id="verPerfil" /> },
        { action: "edit", label: "Editar", icon: <Edit id="editarPerfil" /> },
        {
          action: "editRoles",
          label: "Editar Roles",
          icon: <Style id="btn-editarRoles" />
        }
      ]);

  if (user.status === "ACTIVE" || user.status === "ONBOARDING_REQUIRED") {
    actions.push({
      action: "suspend",
      label: "Suspender",
      icon: <Block id="btn-suspenderUsuario" />
    });
  } else {
    actions.push({
      action: "activate",
      label: "Reactivar",
      icon: <CheckCircleOutline />
    });
  }
  return actions;
};

const UserListRow = ({
  row,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserView,
  onUserEditRoles,
  users
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const onUserMenuClick = menu => {
    if (menu.action === "view") {
      onUserView(row);
    } else if (menu.action === "edit") {
      onUserEdit(row);
    } else if (menu.action === "editRoles") {
      onUserEditRoles(row);
    } else if (menu.action === "email") {
      dispatch(sentMailToUser());
    } else if (menu.action === "suspend") {
      const leaderUsers = users.filter(user => user.leader.id === row.id);
      if (
        row.roles.some(role => role.name === "gestor" && leaderUsers.length > 0)
      ) {
        MySwal.fire({
          title: "¡Atención!",
          html: (
            <>
              <Typography>
                Debes asignar un nuevo líder a los usuarios de la lista antes de
                suspender a {row.first_name} {row.last_name}:
              </Typography>
              {leaderUsers.map(user => (
                <Box m={1} display="flex" justifyContent="center">
                  <Typography
                    display="flex"
                    justifyContent="center"
                    color="primary"
                  >
                    {user.email}
                  </Typography>
                </Box>
              ))}
            </>
          ),
          confirmButtonText: "Aceptar"
        });
      } else {
        dispatch(
          updateUser({ id: row.id, status: "SUSPENDED" }, () => {
            MySwal.fire(
              "Listo",
              `El usuario ${row.first_name +
                " " +
                row.last_name} ha sido suspendido`,
              "success"
            );
          })
        );
      }
    } else if (menu.action === "activate") {
      dispatch(updateUser({ id: row.id, status: "ACTIVE" }, () => {}));
    } else if (menu.action === "delete") {
      onUserDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row.id)}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
    >
      <TableCell padding="checkbox" />
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.first_name} {row.last_name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="h4">{row.email}</Typography>
      </TableCell>
      <TableCell>{row.cuil}</TableCell>
      <TableCell>
        <Typography variant="h4">
          {row.status === "suspended"
            ? `Suspended by ${row.suspendedBy} (${timeFromNow(
                row.suspendedAt
              )})`
            : translateStatus(row.status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h4">
          {row.roles && row.roles.map(role => role.name + ", ")}
        </Typography>
      </TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
