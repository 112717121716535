import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

import CmtAvatar from "@coremat/CmtAvatar";
import { useSelector } from "react-redux";
import { alpha, makeStyles } from "@material-ui/core/styles";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import CmtObjectSummary from "@coremat/CmtObjectSummary";
import CmtAvatarGroup from "@coremat/CmtAvatarGroup";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonRemoveIcon from "@material-ui/icons/Remove";
import AddTrackablePopup from "./AddTrackablePopup";
import RemoveTrackablePopup from "./RemoveTrackablePopup";
import { getActiveUsers, getTrackableUsers } from "redux/actions/Users";
import { useDispatch } from "react-redux";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import Restricted from "services/permissionProvider/Restricted";
import { PROYECTOS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { useHasPrivilege } from "routes/checkPermission";

const useStyles = makeStyles(theme => ({
  actionsRoot: {
    transition: "all 0.3s ease",
    transform: "translateX(0%)",
    opacity: 1,
    visibility: "visible",
    marginLeft: 10
  },
  tableRowRoot: {
    position: "relative",
    transition: "all .2s",
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: "translateY(-4px)",
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: "transparent",
      "& $tableCellRoot": {
        color: theme.palette.text.primary,
        "&:last-child": {
          color: theme.palette.error.main
        },
        "&.success": {
          color: theme.palette.success.main
        }
      }
    },
    "&:last-child": {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`
    }
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    borderBottom: "0 none",
    "&:first-child": {
      paddingLeft: 24
    },
    "&:last-child": {
      textAlign: "right",
      paddingRight: 24
    },
    "& .Cmt-avatar-more": {
      fontSize: 10,
      color: theme.palette.primary.main
    }
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary
  },
  blockRoot: {
    display: "block",
    fontSize: 14
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14
  }
}));

const TrackableAsignation = ({ trackableId }) => {
  const classes = useStyles();

  const moreItemsTooltip = data => (
    <CmtList
      data={data}
      renderRow={(item, index) => <Box key={index}>{item.name}</Box>}
    />
  );
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [anchorElRemove, setAnchorElRemove] = useState(null);
  const handleAddDialog = event => {
    setAnchorElAdd(event.currentTarget);
  };
  const handleRemoveDialog = event => {
    setAnchorElRemove(event.currentTarget);
  };
  const { activeUsers } = useSelector(({ usersReducer }) => usersReducer);
  const { trackableUsers } = useSelector(({ usersReducer }) => usersReducer);

  const dispatch = useDispatch();
  const canDispatch = useHasPrivilege(PROYECTOS_EDITAR);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getActiveUsers([], "", () => {}));
      dispatch(getTrackableUsers(trackableId, () => {}));
    }
  }, [dispatch, trackableId, canDispatch]);

  // console.log("trackable", trackableUsers);

  const orderedTrackable = stableSort(
    trackableUsers,
    getComparator("asc", "first_name")
  );

  const orderedUsers = stableSort(
    activeUsers,
    getComparator("asc", "first_name")
  );

  return (
    <CmtCard>
      <CmtCardHeader title="Asignados">
        <Restricted to={PROYECTOS_EDITAR}>
          <div className={classes.actionsRoot}>
            <IconButton size="medium" onClick={handleAddDialog}>
              <PersonAddIcon id=" btn-agregarUsuario" />
            </IconButton>
          </div>
          <div className={classes.actionsRoot}>
            <IconButton size="medium" onClick={handleRemoveDialog}>
              <PersonRemoveIcon id="btn-eliminarUsuario" />
            </IconButton>
          </div>
        </Restricted>
      </CmtCardHeader>
      <CmtCardContent
        className={clsx(classes.hoverAction, classes.tableRowRoot)}
      >
        <Box className={classes.tableCellRoot}>
          <div style={{ display: "fix", alignItems: "center" }}>
            <CmtAvatarGroup
              items={orderedTrackable.filter(user => user.status === "ACTIVE")}
              srcKey="profile_pic"
              spacing={6}
              max={20}
              avatarSize={52}
              titleKey="name"
              moreVisibleOn="click"
              tooltipProps={{ classes: { tooltip: classes.tooltip } }}
              renderItemSummary={(item, index) => (
                <Box p={1}>
                  <CmtObjectSummary
                    key={index}
                    avatar={<CmtAvatar size={50} src={item.profilePic} />}
                    title={item.firstName}
                    subTitle={item.email}
                    align={"horizontal"}
                  />
                </Box>
              )}
              renderMore={moreItemsTooltip}
            />
          </div>
          <Restricted to={PROYECTOS_EDITAR}>
            <AddTrackablePopup
              options={orderedUsers.filter(u => {
                return !orderedTrackable.some(user => user.id === u.id);
              })}
              anchorEl={anchorElAdd}
              setAnchorEl={setAnchorElAdd}
              team={[]}
              trackableId={trackableId}
            />
            <RemoveTrackablePopup
              options={orderedTrackable}
              anchorEl={anchorElRemove}
              setAnchorEl={setAnchorElRemove}
              team={[]}
              trackableId={trackableId}
            />
          </Restricted>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default TrackableAsignation;
