import React, { useEffect, useState } from "react";
import { Box, Divider, TextField, Typography } from "@material-ui/core";
import { Check, Close, Delete, Edit } from "@material-ui/icons";
import Forbidden from "@jumbo/components/Forbidden";
import { useDispatch, useSelector } from "react-redux";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CmtCard from "@coremat/CmtCard";
import {
  deleteHoliday,
  editHoliday,
  getHolidays
} from "redux/actions/Holidays";
import { useHasPrivilege } from "routes/checkPermission";
import Restricted from "services/permissionProvider/Restricted";

export default function CustomHolidayLoader({
  updateTable,
  filteredLicences,
  HORAS_EDITAR
}) {
  const { holidays } = useSelector(({ holidaysReducer }) => holidaysReducer);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  //const colorMode = sessionStorage.getItem("colorMode");

  const [initialDate, setInitialDate] = useState("");
  const [initialTrackable, setInitialTrackable] = useState("");

  const [borrarItem, setBorrarItem] = useState("");
  const [editarItem, setEditarItem] = useState("");
  const [date, setDate] = useState("");
  const [trackable, setTrackable] = useState("");
  const [disableRows, setDisableRows] = useState(false);
  const [isValidItem, setIsValidItem] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [orderedItems, setOrderedItems] = useState("");
  const canDispatch = useHasPrivilege(HORAS_EDITAR);
  const [dateValid, setDateValid] = useState("");

  useEffect(() => {
    if (canDispatch) {
      dispatch(getHolidays(() => {}));
    }
  }, [dispatch, refresh, updateTable, canDispatch]);

  useEffect(() => {
    //Ordenar historial por fecha
    if (holidays.length > 0) {
      const orderedItemsByDate = stableSort(
        holidays,
        getComparator("asc", "item_date")
      );
      setOrderedItems(orderedItemsByDate);
    } else {
      setOrderedItems([]);
    }
  }, [holidays, updateTable, refresh]);

  useEffect(() => {
    //Validacion al editar fecha
    if (date === "" || date === null) {
      setDateValid("Mostrar");
    } else {
      setDateValid(true);
    }
  }, [date]);

  useEffect(() => {
    //Validacion para editar item sólo si cambian los datos iniciales

    if (dateValid === "Mostrar") {
      setIsValidItem(false);
    } else if (
      trackable === null ||
      trackable === undefined ||
      trackable === "" ||
      trackable.time_trackable === undefined
    ) {
      if (date === initialDate) {
        setIsValidItem(false);
      } else {
        setIsValidItem(true);
      }
    } else if (date === null || date === undefined || date === "") {
      setIsValidItem(false);
    } else if (trackable && trackable.time_trackable !== undefined) {
      if (
        trackable.time_trackable.id === initialTrackable.id &&
        date === initialDate
      ) {
        setIsValidItem(false);
      } else {
        setIsValidItem(true);
      }
    } else {
      setIsValidItem(true);
    }
  }, [trackable, date, initialTrackable, initialDate, dateValid]);

  const refreshTable = () => {
    setEditarItem("");
    setBorrarItem("");
    setDisableRows(false);
    setTrackable("");
    setDate("");
    setRefresh(!refresh);
  };

  const editItem = row => {
    setIsValidItem(false);
    setInitialDate(row.item_date.slice(0, 10));
    setInitialTrackable(row.trackable);
    setEditarItem(row.transaction_id);
    setDisableRows(true);
    setTrackable(row.trackable);
    setDate(row.item_date.slice(0, 10));
  };

  const cancelEditItem = () => {
    setInitialDate("");
    setInitialTrackable("");
    setEditarItem("");
    setDisableRows(false);
    setTrackable("");
    setDate("");
  };

  const onUpdateItem = rowData => {
    const holiday = [
      {
        id: rowData.id,
        item_date: moment(new Date(date)).toISOString(),
        trackable: trackable
      }
    ];

    dispatch(
      editHoliday(holiday, () => {
        MySwal.fire("¡Listo!", "", "success");
        refreshTable();
      })
    );
  };

  const cancelDeleteItem = () => {
    setBorrarItem("");
    setDisableRows(false);
  };

  const deleteItem = id => {
    setBorrarItem(id);
    setDisableRows(true);
  };

  const onDeleteItem = id => {
    const idArr = [id];
    dispatch(
      deleteHoliday(idArr, () => {
        MySwal.fire("¡Listo!", "", "success");
        refreshTable();
      })
    );
  };

  const getFormatedDate = date => {
    const splitDate = date.slice(0, 10);
    const formatedDate = moment(splitDate).format("DD/MM/YYYY");
    return formatedDate;
  };

  useEffect(() => {
    if (date !== initialDate) {
      const existingDates = holidays.filter(
        h => getFormatedDate(h.item_date) === getFormatedDate(date)
      );
      if (existingDates.length > 0) {
        setDateValid("Mostrar");
        MySwal.fire(
          "¡Cuidado!",
          "Ya existe un feriado/asueto con la fecha seleccionada",
          "error"
        );
      }
    }
  }, [holidays, date, refresh, MySwal, initialDate]);

  const Row = ({ row }) => {
    if (borrarItem === row.transaction_id) {
      return (
        <div key={row.transaction_id}>
          <Divider style={{ margin: "0px" }} />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "20px",
              width: "100%"
            }}
          >
            <Typography style={{ width: "80%", marginLeft: "50px" }}>
              ¿Querés borrar esta fila?
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "20%",
                paddingRight: "25px"
              }}
            >
              <Check
                style={{ cursor: "pointer" }}
                onClick={() => onDeleteItem(row.id)}
              />
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => cancelDeleteItem()}
              />
            </Box>
          </Box>
        </div>
      );
    } else if (editarItem === row.transaction_id) {
      return (
        <div key={row.transaction_id}>
          <Divider style={{ margin: "0px" }} />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
              width: "100%"
            }}
          >
            <div style={{ width: "0%", justifyContent: "left" }} />
            <Autocomplete
              style={{ width: "25%", padding: "0px", justifyContent: "left" }}
              id="asignación"
              disableClearable
              defaultValue={trackable}
              options={filteredLicences}
              getOptionLabel={item => (item.name ? item.name : "")}
              getOptionSelected={(option, value) => option === value}
              onChange={(event, getOptionSelected) =>
                setTrackable(getOptionSelected)
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  helperText={
                    <span style={{ color: "#ff2800" }}>
                      {trackable === "" && "Obligatorio"}
                    </span>
                  }
                />
              )}
              noOptionsText="No hay opciones disponibles"
            ></Autocomplete>
            <TextField
              variant="outlined"
              style={{ width: "25%", padding: "0px", justifyContent: "left" }}
              type="date"
              defaultValue={date}
              onChange={e => setDate(e.target.value)}
              helperText={
                dateValid !== "Mostrar" ? (
                  ""
                ) : (
                  <span style={{ color: "#ff2800" }}>Fecha inválida</span>
                )
              }
            />
            <Typography
              style={{
                width: "20%",
                textAlign: "center",
                alignSelf: "center",
                paddingLeft: "10px"
              }}
            >
              Aprobadas
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "20%",
                paddingRight: "10px"
              }}
            >
              <Check
                style={{
                  cursor: "pointer",
                  opacity: isValidItem === true ? "100%" : "20%"
                }}
                onClick={() => isValidItem === true && onUpdateItem(row)}
              />
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => cancelEditItem()}
              />
            </Box>
          </Box>
        </div>
      );
    } else {
      return (
        <div key={row.transaction_id}>
          <Divider style={{ margin: "0px" }} />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "20px"
            }}
          >
            <div style={{ width: "5%", justifyContent: "left" }} />
            <Typography style={{ width: "30%" }}>
              {row.trackable.name}
            </Typography>
            <Typography style={{ width: "30%" }}>
              {getFormatedDate(row.item_date)}
            </Typography>
            <Typography style={{ width: "20%" }}>Aprobadas</Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "20%",
                paddingRight: "20px"
              }}
            >
              <Edit
                style={{
                  cursor: "pointer",
                  opacity: disableRows === true ? "20%" : "100%"
                }}
                onClick={() => disableRows === false && editItem(row)}
              />
              <Delete
                style={{
                  cursor: "pointer",
                  opacity: disableRows === true ? "20%" : "100%"
                }}
                onClick={() =>
                  disableRows === false && deleteItem(row.transaction_id)
                }
              />
            </Box>
          </Box>
        </div>
      );
    }
  };

  return (
    <Restricted to={HORAS_EDITAR} fallback={Forbidden}>
      <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CmtCard>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "20px"
            }}
          >
            <Typography style={{ width: "5%" }} />
            <Typography style={{ fontWeight: 600, width: "30%" }}>
              Tipo
            </Typography>
            <Typography style={{ fontWeight: 600, width: "30%" }}>
              Fecha
            </Typography>
            <Typography style={{ fontWeight: 600, width: "20%" }}>
              Estado
            </Typography>
            <Typography
              style={{ fontWeight: 600, width: "20%", paddingLeft: "35px" }}
            >
              Acciones
            </Typography>
          </Box>
          {orderedItems.length > 0 ? (
            orderedItems.map(item => {
              return (
                <div key={item.id}>
                  <Row row={item} />
                </div>
              );
            })
          ) : (
            <div>
              <>
                <Divider style={{ margin: "0px" }} />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    padding: "20px"
                  }}
                >
                  <Typography
                    style={{
                      width: "15%",
                      fontSize: "14px",
                      paddingLeft: "20px"
                    }}
                  >
                    No hay registros
                  </Typography>
                </Box>
              </>
            </div>
          )}
        </CmtCard>
      </Box>
    </Restricted>
  );
}
