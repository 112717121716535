import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Header from "./Header";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import Contact from "./Contact";
import About from "./About";
import Projects from "./Projects";
import Trainings from "./Trainings";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../../redux/actions/Users";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  HORAS_CARGAR,
  USUARIOS_VER
} from "services/permissionProvider/PermissionsCatalog";
import Forbidden from "@jumbo/components/Forbidden";
import Restricted from "services/permissionProvider/Restricted";
import { useHasPrivilege } from "routes/checkPermission";

const useStyles = makeStyles(() => ({
  pageFull: {
    width: "100%"
  },

  profileMainContent: {
    "@media screen and (min-width: 1280px) and (max-width: 1499px)": {
      flexBasis: "100%",
      maxWidth: "100%"
    }
  }
}));

const UserProfileView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState("about");
  const { userDetail } = useSelector(({ usersReducer }) => usersReducer);
  const { id } = useParams();
  const { authUser } = useSelector(({ auth }) => auth);
  const canDispatch = useHasPrivilege(USUARIOS_VER);

  useEffect(() => {
    if (typeof id == "undefined" || id === authUser.id) {
      dispatch(getUserDetail(authUser.id));
    } else {
      if (canDispatch) {
        dispatch(getUserDetail(id));
      }
    }
  }, [dispatch, id, authUser, canDispatch]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getProfile = () => {
    let profile = <Restricted to={HORAS_CARGAR} fallback={Forbidden} />;
    if (userDetail !== null) {
      if (userDetail.user.id === authUser.id) {
        profile = (
          <Restricted to={HORAS_CARGAR} fallback={Forbidden}>
            <React.Fragment>
              {userDetail && (
                <Box className={classes.pageFull}>
                  <Header
                    classes={classes}
                    userDetail={userDetail}
                    tabValue={tabValue}
                    handleTabChange={handleTabChange}
                  />
                  <GridContainer>
                    <Grid
                      item
                      xs={12}
                      mb={4}
                      lg={4}
                      className={classes.profileSidebar}
                    >
                      <Box>
                        <Contact userDetail={userDetail} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      mb={4}
                      lg={4}
                      className={classes.profileSidebar}
                    >
                      <Box>
                        <Projects userDetail={userDetail} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      mb={4}
                      lg={4}
                      className={classes.profileSidebar}
                    >
                      <Box>
                        <Trainings userDetail={userDetail} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      className={classes.profileMainContent}
                    >
                      <Box mb={12}>
                        <About userDetail={userDetail} />
                      </Box>
                    </Grid>
                  </GridContainer>
                </Box>
              )}
            </React.Fragment>
          </Restricted>
        );
      } else {
        profile = (
          <Restricted to={USUARIOS_VER} fallback={Forbidden}>
            <React.Fragment>
              {userDetail && (
                <Box className={classes.pageFull}>
                  <Header
                    classes={classes}
                    userDetail={userDetail}
                    tabValue={tabValue}
                    handleTabChange={handleTabChange}
                  />
                  <GridContainer>
                    <Grid
                      item
                      xs={12}
                      mb={4}
                      lg={4}
                      className={classes.profileSidebar}
                    >
                      <Box>
                        <Contact userDetail={userDetail} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      mb={4}
                      lg={4}
                      className={classes.profileSidebar}
                    >
                      <Box>
                        <Projects userDetail={userDetail} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      mb={4}
                      lg={4}
                      className={classes.profileSidebar}
                    >
                      <Box>
                        <Trainings userDetail={userDetail} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      className={classes.profileMainContent}
                    >
                      <Box mb={12}>
                        <About userDetail={userDetail} />
                      </Box>
                    </Grid>
                  </GridContainer>
                </Box>
              )}
            </React.Fragment>
          </Restricted>
        );
      }
    }
    return profile;
  };

  return <>{getProfile()}</>;
};

export default UserProfileView;
