import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import RoleAlert from "@jumbo/components/RoleAlert";
import { ROLE_ALERT } from "@jumbo/constants/ActionTypes";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addRoleToUser,
  deleteRoleFromUser,
  getRoles
} from "redux/actions/Roles";

const CheckboxRoles = ({ editUserRoles, setEditUserRoles, users }) => {
  const { roles } = useSelector(({ rolesReducer }) => rolesReducer);
  const currentUser = useSelector(
    ({ usersReducer }) => usersReducer.currentUser
  );
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [titleCF, setTitleCF] = useState("");
  const [openCF, setOpenCF] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [warningRole, setWarningRole] = useState("");
  const [openLeaderWarning, setOpenLeaderWarning] = useState(false);
  const [leaderWarningInfo, setLeaderWarningInfo] = useState({
    title: "",
    content: "",
    userList: []
  });

  const roleAlert = message => {
    return dispatch => {
      dispatch({
        type: ROLE_ALERT,
        payload: message
      });
    };
  };

  useEffect(() => {
    dispatch(getRoles([], "", () => {}));
  }, [dispatch, currentUser]);

  useEffect(() => {
    dispatch(roleAlert(""));
  }, [dispatch]);

  const setRole = (e, roleId, roleName) => {
    setRoleId(roleId);
    openDialog(e, roleName);
  };

  const openDialog = (e, roleName) => {
    const leaderUsers = users.filter(user => user.leader.id === currentUser.id);
    if (roleName === "gestor" && leaderUsers.length > 0) {
      setOpenLeaderWarning(true);
      setLeaderWarningInfo({
        title: "¡Atención!",
        content:
          "Debes asignar un nuevo líder a los usuarios de la lista antes de eliminar el rol de gestor a " +
          currentUser.first_name +
          " " +
          currentUser.last_name +
          ":",
        userList: leaderUsers
      });
    } else {
      if (e) {
        setTitleCF("¿Estas seguro de querer agregar este rol?");
        setOpenCF(true);
        setChecked(false);
      } else {
        if (currentUser.roles.length < 2) {
          setWarningRole("Un usuario debe tener al menos un rol.");
        } else {
          setTitleCF("¿Estas seguro de querer eliminar este rol?");
          setOpenCF(true);
          setChecked(true);
        }
      }
    }
  };

  const addDeleteRole = () => {
    if (checked) {
      dispatch(deleteRoleFromUser(currentUser.id, roleId, () => {}));
      setChecked(false);
      setOpenCF(false);
      setRoleId("");
    } else {
      dispatch(addRoleToUser(currentUser.id, roleId, () => {}));
      setChecked(true);
      setOpenCF(false);
      setRoleId("");
    }
  };

  return (
    <Dialog open={editUserRoles}>
      <DialogContent dividers>
        <Box
          mb={{ xs: 6, md: 5 }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width="300px"
        >
          {roles.map(role => (
            <Box mb={{ xs: 6, md: 5 }} key={role.id}>
              <Box
                m={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControlLabel
                  label={role.name}
                  control={
                    <Checkbox
                      id="chkbox-listaRoles"
                      onChange={e =>
                        setRole(e.target.checked, role.id, role.name)
                      }
                      checked={
                        currentUser.roles
                          .map(rol => rol.name)
                          .toString()
                          .includes(role.name)
                          ? true
                          : false
                      }
                    />
                  }
                />
              </Box>
              <Divider />
              <ConfirmDialog
                title="Confirmación de Rol"
                content={titleCF}
                open={openCF}
                onConfirm={() => addDeleteRole()}
                onClose={() => setOpenCF(false)}
              />
            </Box>
          ))}
          <RoleAlert />
          <Typography>{warningRole}</Typography>
          <Button
            id="btn-guardarCambios"
            variant="outlined"
            color="primary"
            onClick={() => setEditUserRoles(false)}
          >
            Aceptar
          </Button>
        </Box>
      </DialogContent>
      <Dialog open={openLeaderWarning}>
        <Box
          m={4}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="center">
            <Typography
              display="flex"
              justifyContent="center"
              color="secondary"
            >
              {leaderWarningInfo.title}
            </Typography>
            <Divider />
          </Box>
          <Typography display="flex" justifyContent="center">
            {leaderWarningInfo.content}
          </Typography>
          <Box display="flex" justifyContent="center" flexDirection="column">
            {leaderWarningInfo.userList.map(user => (
              <Box m={1} display="flex" justifyContent="center">
                <Typography
                  display="flex"
                  justifyContent="center"
                  color="primary"
                >
                  {user.email}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box display="flex" justifyContent="center" m={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenLeaderWarning(false)}
            >
              Aceptar
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Dialog>
  );
};
export default CheckboxRoles;
