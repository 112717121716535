import React from "react";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveUsers } from "redux/actions/Users";
import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CmtAvatar from "@coremat/CmtAvatar";
import { useHistory } from "react-router-dom";
import Restricted from "services/permissionProvider/Restricted";
import Forbidden from "@jumbo/components/Forbidden";
import { USUARIOS_VER } from "services/permissionProvider/PermissionsCatalog";
import { useHasPrivilege } from "routes/checkPermission";

const useStyles = makeStyles({
  pagination: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    marginTop: "5px",
    borderRadius: "30px",
    border: "2px solid rgba(0, 0, 0, 0.12)"
  },
  resultado: {
    fontWeight: "600",
    color: "#555555"
  }
});

const HobbiesAndInterests = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [resultSize, setResultSize] = useState(0);
  const { activeUsers } = useSelector(({ usersReducer }) => usersReducer);
  const pageSize = 5;
  const [page, setPage] = useState(1);
  let history = useHistory();
  const canDispatch = useHasPrivilege(USUARIOS_VER);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (canDispatch) {
      dispatch(getActiveUsers([], "", () => {}));
    }
  }, [dispatch, canDispatch]);

  useEffect(() => {
    const filteredUsers = activeUsers.filter(row => {
      let rows;
      const formatText = text => {
        return text
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      };
      const newSearchTerm = formatText(searchTerm);
      if (searchTerm === "") {
        rows = row;
      } else if (formatText(row.first_name).includes(newSearchTerm)) {
        rows = row;
      } else if (formatText(row.last_name).includes(newSearchTerm)) {
        rows = row;
      } else if (
        row.hobbies.some(hobby =>
          formatText(hobby.name).includes(newSearchTerm)
        )
      ) {
        rows = row;
      } else if (
        row.interests.some(int => formatText(int.name).includes(newSearchTerm))
      ) {
        rows = row;
      } else if (
        row.job_title !== null &&
        formatText(row.job_title.name).includes(newSearchTerm)
      ) {
        rows = row;
      }
      return rows;
    });
    setResultSize(filteredUsers);
  }, [searchTerm, activeUsers]);

  const name = sessionStorage.getItem("buscador");

  useEffect(() => {
    setSearchTerm(name);
  }, [name]);

  const orderedUsers = stableSort(
    activeUsers,
    getComparator("asc", "first_name")
  );

  const handleChangePage = (_, pageNumber) => {
    setPage(pageNumber);
  };

  const getInfo = id => {
    history.push(`/perfil/${id}`);
  };

  const colorMode = sessionStorage.getItem("colorMode");

  const getUsers = pageNumber => {
    const comps = [];

    const filteredUsers = orderedUsers.filter(row => {
      let rows;
      const formatText = text => {
        return text
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      };
      const newSearchTerm = formatText(searchTerm);
      if (searchTerm === "") {
        rows = row;
      } else if (formatText(row.first_name).includes(newSearchTerm)) {
        rows = row;
      } else if (formatText(row.last_name).includes(newSearchTerm)) {
        rows = row;
      } else if (
        row.hobbies.some(hobby =>
          formatText(hobby.name).includes(newSearchTerm)
        )
      ) {
        rows = row;
      } else if (
        row.interests.some(int => formatText(int.name).includes(newSearchTerm))
      ) {
        rows = row;
      } else if (
        row.job_title !== null &&
        formatText(row.job_title.name).includes(newSearchTerm)
      ) {
        rows = row;
      }
      return rows;
    });

    const firstElement = (pageNumber - 1) * pageSize;
    const lastElement = firstElement + pageSize;

    if (searchTerm !== "") {
      filteredUsers.slice(firstElement, lastElement).map(item =>
        comps.push(
          <Box
            key={item.id}
            onClick={() => getInfo(item.id)}
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: colorMode === "dark" ? "#121212" : "#FFFFFF",
              justifyContent: "start",
              alignItems: "center",
              padding: "10px",
              marginTop: "5px",
              borderRadius: "30px",
              border: "2px solid rgba(0, 0, 0, 0.12)",
              cursor: "pointer"
            }}
          >
            <Box>
              <Box display="flex" alignItems="center">
                <Box mr={{ xs: 4, md: 5 }}>
                  <CmtAvatar size={40} src={item.profile_pic} alt={item.name} />
                </Box>
                <Box style={{ display: "flex" }}>
                  <div style={{ width: "300px" }}>
                    <Typography component="div" variant="h4">
                      {item.first_name} {item.last_name}
                    </Typography>
                  </div>
                  <div style={{ marginLeft: "16px", width: "300px" }}>
                    <Typography component="div" variant="h4">
                      {item.job_title !== null && item.job_title.name}
                    </Typography>
                  </div>
                  <div style={{ marginLeft: "16px" }}>
                    <Typography component="div" variant="h4">
                      {item.email}
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      );
      comps.push(
        <Box key="pagination" className={classes.pagination}>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={Math.ceil(filteredUsers.length / pageSize)}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          </Box>
        </Box>
      );
    }
    return comps;
  };

  return (
    <Restricted to={USUARIOS_VER} fallback={Forbidden}>
      <Box style={{ width: "100%" }}>
        {searchTerm !== null && searchTerm !== "" && resultSize.length > 0 && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <Typography className={classes.resultado}>
              {resultSize.length === 1
                ? resultSize.length + " usuario coincide"
                : resultSize.length + " usuarios coinciden"}{" "}
              con:
            </Typography>
            <Tooltip
              title={
                <Typography
                  style={{
                    backgroundColor: "#000 20%",
                    color: "#FFFFFF",
                    borderRadius: "50px"
                  }}
                >
                  {searchTerm}
                </Typography>
              }
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px",
                  borderRadius: "50px",
                  padding: "15px",
                  color: "#FFFFFF",
                  backgroundColor: "#f50057",
                  height: "30px",
                  width: "150px",
                  fontSize: "16px",
                  fontWeight: 600,
                  alignItems: "center",
                  letterSpacing: "1px"
                }}
              >
                {searchTerm.substring(0, 11) + "..."}
              </Box>
            </Tooltip>
          </Box>
        )}

        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          {getUsers(page)}
        </Box>
      </Box>
    </Restricted>
  );
};
export default HobbiesAndInterests;
