import React, { useEffect, useState } from "react";
import AppTextInput from "../../../../@jumbo/components/Common/formElements/AppTextInput";
import AppSelectBox from "../../../../@jumbo/components/Common/formElements/AppSelectBox";
import { Box, Button, Divider, MenuItem, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  isValidEmail,
  isValidName,
  isValidCuil
} from "../../../../@jumbo/utils/commonHelper";
import {
  updateUser,
  getLeaders,
  getAreas,
  getJobTitles
} from "../../../../redux/actions/Users";
import { getRoles } from "redux/actions/Roles";
import { Dialog } from "@material-ui/core";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
      display: "flex",
      justifyContent: "center"
    }
  },
  input: {
    width: "250px"
  }
}));

const EditUser = ({ open, onCloseDialog, users }) => {
  const classes = useStyles();
  const currentUser = useSelector(
    ({ usersReducer }) => usersReducer.currentUser
  );
  const { leaders, areas, jobTitles } = useSelector(
    ({ usersReducer }) => usersReducer
  );

  const [first_name, setFirst_Name] = useState(
    currentUser ? currentUser.first_name : ""
  );
  const [last_name, setLast_Name] = useState(
    currentUser ? currentUser.last_name : ""
  );
  const [email, setEmail] = useState(currentUser ? currentUser.email : "");
  const [cuil, setCuil] = useState(currentUser ? currentUser.cuil : "");
  const [leaderId, setLeaderId] = useState(
    currentUser && currentUser.leader ? currentUser.leader.id : ""
  );
  const [areaId, setAreaId] = useState("");
  const [jobTitleId, setJobTitleId] = useState(
    currentUser && currentUser.job_title ? currentUser.job_title.id : ""
  );
  /*  const [birthdayDate, setBirthdayDate] = useState("");
   const [joinningDay, setJoinningDay] = useState("");
   const [imageUrl, setImageUrl] = useState("");
   const [client, setClient] = useState("");
  */
  const [firstNameError, setFirstNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [cuilError, setCuilError] = useState("");
  const [leaderError, setLeaderError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  /* const [birthdayDateError, setBirthdayDateError] = useState("");
  const [joinningDayError, setJoinningDayError] = useState("");
  const [imageUrlError, setImageUrlError] = useState("");
  const [clientError, setClientError] = useState(""); */

  const dispatch = useDispatch();

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.area) {
        const filteredArea = areas.filter(
          area => area.name === currentUser.area.name
        );
        if (filteredArea.length > 0) {
          const idArea = filteredArea[0].id;
          setAreaId(idArea);
        }
      }
    }
  }, [currentUser, areas]);

  useEffect(() => {
    dispatch(getRoles([], "", () => {}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLeaders([], "", () => {}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAreas([], "", () => {}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getJobTitles([], "", () => {}));
  }, [dispatch]);

  const leaderFiltered = leaders
    .filter(
      leader => leader.status !== "SUSPENDED" && leader.id !== currentUser.id
    )
    .sort((a, b) => {
      const nombreA = a.first_name;
      const nombreB = b.first_name;
      if (nombreA < nombreB) {
        return -1;
      }
      if (nombreA > nombreB) {
        return 0;
      } else {
        return 0;
      }
    });

  const onCurrentUserUpdate = updateWithCuil => {
    const id = currentUser.id;
    const job_title = Object.assign({}, { id: jobTitleId });
    const leader = leaderId !== "" ? Object.assign({}, { id: leaderId }) : null;
    const area = Object.assign({}, { id: areaId });
    //const birthday = moment(birthdayDate).format("DD/MM/YYYY");
    //const joinningDate = moment(joinningDay).format("DD/MM/YYYY");
    //const assigned_client = client;

    const currentUserDetailWithCuil = {
      id,
      first_name,
      last_name,
      email,
      cuil,
      leader,
      area,
      job_title
      //birthday,
      //admissionDate,
      //imageUrl,
      //client
    };

    const currentUserDetail = {
      id,
      first_name,
      last_name,
      email,
      leader,
      area,
      job_title
      //birthday,
      //admissionDate,
      //imageUrl,
      //client
    };

    switch (updateWithCuil) {
      case "Cuil":
        dispatch(
          updateUser(currentUserDetailWithCuil, () => {
            MySwal.fire(
              "¡Listo!",
              "El usuario se actualizó correctamente",
              "success"
            );
          })
        );
        onCloseDialog();
        break;
      case "NoCuil":
        dispatch(
          updateUser(currentUserDetail, () => {
            MySwal.fire(
              "¡Listo!",
              "El usuario se actualizó correctamente",
              "success"
            );
          })
        );
        onCloseDialog();
        break;
      default:
        console.log("Error en switch");
    }
  };

  const checkIfUserAlreadyExist = () => {
    const usersCuil = users.filter(user => user.id !== currentUser.id);
    const filteredCuiles = usersCuil.map(user => user.cuil);

    if (currentUser.cuil === cuil) {
      setCuilError("");
      onCurrentUserUpdate("NoCuil");
    } else if (filteredCuiles.includes(cuil)) {
      setCuilError("Ya existe");
    } else {
      onCurrentUserUpdate("Cuil");
    }
  };

  const onSubmitClick = () => {
    if (!isValidName(first_name) || first_name.trim().length < 2) {
      setFirstNameError("Invalido");
    } else if (!isValidName(last_name) || last_name.trim().length < 2) {
      setLastNameError("Invalido");
    } else if (!isValidEmail(email)) {
      setEmailError("Invalido");
    } else if (!isValidCuil(cuil)) {
      setCuilError("Invalido");
    } else if (leaderId === null || leaderId === "") {
      setLeaderError("Incompleto");
    } else if (areaId === null || areaId === "") {
      setAreaError("Incompleto");
    } else if (jobTitleId === null || jobTitleId === "") {
      setJobTitleError("Incompleto");
      /*   } else if (birthdayDate === null || birthdayDate === "") {
          setBirthdayDateError("Incompleto");
        } else if (imageUrl === null || imageUrl === "") {
          setImageUrlError("Incompleto");
        } else if (joinningDay === null || joinningDay === "") {
          setJoinningDayError("Incompleto");
        } else if (client === null || client === "") {
          setClientError("Incompleto"); */
    } else {
      checkIfUserAlreadyExist();
    }
  };

  const orderedLeaders = stableSort(
    leaderFiltered,
    getComparator("asc", "first_name")
  );

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        Editar usuario
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box display="flex">
            <Box display="flex" flexDirection="column" m={2}>
              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  id="txt-nombreUsuario"
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  label={<Typography variant="h6">Nombre</Typography>}
                  value={first_name}
                  onChange={e => {
                    setFirst_Name(e.target.value);
                    setFirstNameError("");
                  }}
                  helperText={
                    firstNameError === "Invalido"
                      ? "El nombre no es válido"
                      : ""
                  }
                />
              </Box>

              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  id="txt-apellidoUsuario"
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  label={<Typography variant="h6">Apellido</Typography>}
                  value={last_name}
                  onChange={e => {
                    setLast_Name(e.target.value);
                    setLastNameError("");
                  }}
                  helperText={
                    lastNameError === "Invalido"
                      ? "El apellido no es válido"
                      : ""
                  }
                />
              </Box>

              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  id="txt-emailUsuario"
                  disabled
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  label={<Typography variant="h6">Dirección email</Typography>}
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  helperText={
                    emailError === "Ya existe"
                      ? "El email ingresado ya existe"
                      : emailError === "Invalido"
                      ? "El email no es válido"
                      : ""
                  }
                />
              </Box>

              {/* ESPERAR A QUE LOS CAMPOS NUEVOS ESTEN EN BACKEND MAIN PARA PROBAR */}
              {/*  <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  className={classes.input}
                  fullWidth
                  type="date"
                  variant="outlined"
                  label="Fecha de Nacimiento"
                  value={birthdayDate}
                  onChange={e => {
                    setBirthdayDate(e.target.value);
                    setBirthdayDateError("");
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={
                    birthdayDateError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box> */}

              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  id="txt-cuilUsuario"
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  label={<Typography variant="h6">Cuil</Typography>}
                  value={cuil}
                  onChange={e => {
                    setCuil(e.target.value);
                    setCuilError("");
                  }}
                  helperText={
                    cuilError === "Ya existe"
                      ? "El cuil ingresado ya existe"
                      : cuilError === "Invalido"
                      ? "El cuil no es válido"
                      : ""
                  }
                />
              </Box>

              {/* ESPERAR A QUE LOS CAMPOS NUEVOS ESTEN EN BACKEND MAIN PARA PROBAR */}
              {/*  <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  label="Foto de usuario"
                  value={imageUrl}
                  onChange={e => {
                    setImageUrl(e.target.value);
                    setImageUrlError("");
                  }}
                  helperText={
                    imageUrlError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box>
            </Box> */}

              {/* ESPERAR A QUE LOS CAMPOS NUEVOS ESTEN EN BACKEND MAIN PARA PROBAR */}
              {/* <Box display="flex" flexDirection="column" m={2}>
              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  className={classes.input}
                  fullWidth
                  type="date"
                  variant="outlined"
                  label="Fecha de Ingreso a CDT"
                  value={joinningDay}
                  onChange={e => {
                    setJoinningDay(e.target.value);
                    setJoinningDayError("");
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={
                    joinningDayError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box> */}

              {/* ESPERAR A QUE LOS CAMPOS NUEVOS ESTEN EN BACKEND MAIN PARA PROBAR */}
              {/*  <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  label="Cliente Asignado"
                  value={client}
                  onChange={e => {
                    setClient(e.target.value);
                    setClientError("");
                  }}
                  helperText={
                    clientError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box> */}

              <Box mb={{ xs: 6, md: 5 }}>
                <AppSelectBox
                  id="sel-listaLideres"
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  data={orderedLeaders}
                  value={leaderId}
                  renderRow={(item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.first_name + " " + item.last_name}
                    </MenuItem>
                  )}
                  valueKey="id"
                  label="Líder"
                  onChange={e => {
                    setLeaderId(e.target.value);
                    setLeaderError("");
                  }}
                  helperText={
                    leaderError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box>

              <Box mb={{ xs: 6, md: 5 }}>
                <AppSelectBox
                  id="sel-listaAreas"
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  data={areas}
                  value={areaId}
                  valueKey="id"
                  labelKey="name"
                  label="Área"
                  onChange={e => {
                    setAreaId(e.target.value);
                    setAreaError("");
                  }}
                  helperText={
                    areaError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box>

              <Box mb={{ xs: 6, md: 5 }}>
                <AppSelectBox
                  id="sel-listaPosiciones"
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  data={jobTitles}
                  value={jobTitleId}
                  valueKey="id"
                  labelKey="name"
                  label="Posición"
                  onChange={e => {
                    setJobTitleId(e.target.value);
                    setJobTitleError("");
                  }}
                  helperText={
                    jobTitleError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box>
            </Box>
          </Box>

          <Divider />
          <Box marginTop="20px">
            <Box display="flex" justifyContent="space-around" mb={4}>
              <Box>
                <Button onClick={onCloseDialog} id="btn-cancelar">
                  Cancelar
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  id="btn-guardarCambios"
                  variant="contained"
                  color="primary"
                  onClick={onSubmitClick}
                >
                  Guardar
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditUser;

EditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
