import { useSelector } from "react-redux";

export function useHasPrivilege(privilege) {
  const { authUser } = useSelector(({ auth }) => auth);

  if (!authUser) {
    return false;
  }

  const flatten = array => array.reduce((a, b) => a.concat(b), []);

  let privileges = flatten(
    authUser.roles.map(r => r.privileges.map(p => p.name))
  );
  return privileges.includes(privilege);
}
