import React, { useEffect, useState } from "react";
import TimetrackingTable from "../TimeTrackingTable";
import GridContainer from "@jumbo/components/GridContainer";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip
} from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovableTimeTrackingItems,
  approveTimeTrackingItem,
  rejectTimeTrackingItem,
  getTimeTrackingItemsByLeader
} from "../../../../redux/actions/TimeTrackingItems";
import Restricted from "../../../../services/permissionProvider/Restricted";
import Forbidden from "@jumbo/components/Forbidden";
import { HORAS_APROBAR_PROYECTOS } from "../../../../services/permissionProvider/PermissionsCatalog";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Helper from "@jumbo/components/Helper";
import { getUserDetail } from "../../../../redux/actions/Users";
import { makeStyles } from "@material-ui/core";
import NoRecordFound from "./NoRecordFound";
import { Pagination } from "@material-ui/lab";
import { getComparatorHash, stableSortHash } from "@jumbo/utils/tableHelper";
import SearchBox from "@jumbo/components/AppLayout/VerticalLayouts/ModernSidebar/ActionSideBar/Search/SearchBox";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CustomGroupRow from "./AdminTableStyled/CustomComponent";
import { useHasPrivilege } from "routes/checkPermission";
import { Notifications } from "@material-ui/icons";
import DownloadReport from "../TimeTrackingAdmin/DownloadReport";

const useStyles = makeStyles(theme => ({
  root: {
    display: "unset",
    padding: "0px 0px 0px"
  },
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "20px",
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  buttonStyle: {
    marginBottom: "30px",
    border: "2px solid #2681C4",
    height: "60px",
    width: "220px",
    transition: "all .2s ease-in-out",
    "&:hover": {
      boxShadow:
        "0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)",
      transform: "scale(1.1)"
    }
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  }
}));



export default function TimeTrackingModule() {
  const canDispatch = useHasPrivilege(HORAS_APROBAR_PROYECTOS);
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { userDetail } = useSelector(({ usersReducer }) => usersReducer);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const pageSize = 3;
  const [open, setOpen] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [motivoError, setMotivoError] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [rowId, setRowId] = useState("");
  const [approvableItems, setApprovableItems] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isOpenCreateReport, setIsOpenCreateReport] = useState(false);

  const trackables = userDetail
    ? userDetail.user.trackables.reduce(function (map, { id, name }) {
      map[id] = name;
      return map;
    }, {})
    : {};

  useEffect(() => {
    if (canDispatch) {
      dispatch(getUserDetail(authUser.id));
    }
  }, [dispatch, authUser, canDispatch, refresh]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getApprovableTimeTrackingItems(authUser.id, [], "", () => { }));
    }
  }, [dispatch, authUser, canDispatch, refresh]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getTimeTrackingItemsByLeader(authUser.id, () => { }));
    }
  }, [dispatch, authUser, canDispatch, refresh]);

  const onApprove = timeTrackingItemId => {
    if (false) {
      //validate here
    } else {
      dispatch(
        approveTimeTrackingItem(timeTrackingItemId, authUser.id, () => {
          setRefresh(!refresh);
        })
      );
    }
  };

  const onReject = timeTrackingItemId => {
    if (false) {
      //validate here
    } else {
      dispatch(
        rejectTimeTrackingItem(
          timeTrackingItemId,
          authUser.id,
          { reason: motivo },
          () => {
            setRefresh(!refresh);
          }
        )
      );
    }
  };

  const colorMode = sessionStorage.getItem("colorMode");

  const getChip = (status, row) => {
    const approverName =
      row.updatedBy && row.updatedBy.firstName + " " + row.updatedBy.lastName;
    switch (status) {
      case "APPROVED":
        return (
          <Tooltip
            placement="top"
            title={
              <Typography>
                {row.updatedBy && `Aprobado por ${approverName}`}
              </Typography>
            }
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "3px",
                borderRadius: "20px",
                width: "230px",
                height: "35px",
                backgroundColor:
                  colorMode === "dark" ? "#2E2E2E" : "rgb(174,241,149)",
                border:
                  colorMode === "dark" ? " 2px solid rgb(105 223 0)" : "none"
              }}
            >
              {row.updatedBy
                ? `Aprobado por ${approverName.substring(0, 10)}...`
                : "Aprobado"}
            </Box>
          </Tooltip>
        );
      case "REJECTED":
        return (
          <Tooltip
            style={{ fontSize: "16px" }}
            placement="top"
            title={
              <Typography>
                {row.updatedBy && `Rechazado por ${approverName}`}
              </Typography>
            }
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "3px",
                borderRadius: "20px",
                width: "230px",
                height: "35px",
                backgroundColor:
                  colorMode === "dark" ? "#2E2E2E" : "rgb(255,164,164)",
                border: colorMode === "dark" ? " 2px solid #F01C16" : "none"
              }}
            >
              {row.updatedBy
                ? `Rechazado por ${approverName.substring(0, 8)}...`
                : "Rechazado"}
            </Box>
          </Tooltip>
        );
      case "CONFIRMED":
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "3px",
              borderRadius: "20px",
              width: "230px",
              height: "35px",
              backgroundColor:
                colorMode === "dark" ? "rgba(81,81,81,1)" : "#BCBCBC",
              border: colorMode === "dark" ? " 2px solid #BCBCBC" : "none"
            }}
          >
            Pendiente
          </Box>
        );
      default:
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "3px",
              borderRadius: "20px",
              width: "230px",
              height: "35px",
              backgroundColor:
                colorMode === "dark" ? "rgba(81,81,81,1)" : "#BCBCBC",
              border: colorMode === "dark" ? " 2px solid #BCBCBC" : "none"
            }}
          >
            Pendiente
          </Box>
        );
    }
  };

  const columns = [
    {
      title: "",
      field: "itemDate",
      type: "date",
      dateSetting: { locale: "es-ES" },
      defaultGroupOrder: 0,
      headerStyle: {
        display: "none"
      }
    },
    {
      title: "",
      field: "trackable.name",
      editable: "never",
      type: "string",
      render: rowData =>
        rowData.trackable.name.length > 38 ? (
          <span style={{ width: "312px" }}>
            {rowData.trackable.name.substring(0, 38) + "..."}
          </span>
        ) : (
          <span style={{ width: "312px" }}>{rowData.trackable.name}</span>
        ),
      cellStyle: {
        width: "100%",
        padding: "16px 16px 16px 10px"
      },
      headerStyle: {
        display: "none"
      }
    },
    {
      title: "",
      field: "hours",
      type: "numeric",
      render: rowData => (
        <span
          style={{ display: "flex", justifyContent: "center", width: "100px" }}
        >
          Horas:{" " + rowData.hours}
        </span>
      ),
      cellStyle: {
        width: "8.5%"
      },
      headerStyle: {
        display: "none"
      },
      align: "center"
    },
    {
      title: "",
      field: "minutes",
      type: "numeric",
      render: rowData => (
        <span
          style={{ display: "flex", justifyContent: "center", width: "100px" }}
        >
          Minutos:{" " + rowData.minutes}
        </span>
      ),
      cellStyle: {
        width: "8.5%"
      },
      headerStyle: {
        display: "none"
      },
      align: "center"
    },
    {
      title: "",
      field: "status",
      type: "string",
      render: rowData => getChip(rowData.status, rowData), // VERIFICAR QUE UPDATEDBYID VENGA ASI DEL BACK
      cellStyle: {
        width: "230px"
      },
      headerStyle: {
        display: "none"
      },
      align: "center"
    },
    {
      title: "",
      field: "",
      type: "",
      cellStyle: {
        width: "26%"
      },
      headerStyle: {
        display: "none"
      }
    }
  ];

  const actions = [
    rowData => ({
      disabled: !rowData.actionsAllowed.includes("APPROVE"),
      icon:
        rowData.status === "APPROVED"
          ? ""
          : rowData.status === "REJECTED"
            ? ""
            : !rowData.actionsAllowed.includes("APPROVE")
              ? ""
              : Check,
      tooltip:
        rowData.status === "APPROVED"
          ? ""
          : rowData.status === "REJECTED"
            ? ""
            : !rowData.actionsAllowed.includes("APPROVE")
              ? ""
              : "Aprobar",
      cursor: !rowData.actionsAllowed.includes("APPROVE") ? "" : "pointer",
      onClick: (event, rowData) =>
        rowData.status !== "APPROVED" &&
        rowData.status !== "REJECTED" &&
        onApprove(rowData.id)
    }),
    rowData => ({
      disabled: !rowData.actionsAllowed.includes("REJECT"),
      icon:
        rowData.status === "APPROVED"
          ? ""
          : rowData.status === "REJECTED"
            ? ""
            : !rowData.actionsAllowed.includes("REJECT")
              ? ""
              : Clear,
      tooltip:
        rowData.status === "APPROVED"
          ? ""
          : rowData.status === "REJECTED"
            ? ""
            : !rowData.actionsAllowed.includes("REJECT")
              ? ""
              : "Rechazar",
      cursor: !rowData.actionsAllowed.includes("REJECT") ? "" : "pointer",
      onClick: (event, rowData) =>
        rowData.status !== "REJECTED" &&
        rowData.status !== "APPROVED" &&
        openDialog(rowData.id)
    })
  ];

  /*   const rowBackgroundColors = {
      APPROVED: "rgb(174,241,149)",
      REJECTED: "rgb(255,164,164)"
    }; */

  const options = {
    rowStyle: rowData => {
      return {
        //backgroundColor: rowBackgroundColors[rowData.status]
      };
    },
    actionsColumnIndex: 4,
    overflow: "hidden"
  };

  const {
    approvableTimeTrackingItems,
    timeTrackingItemsByLeader
  } = useSelector(({ timeTrackingItemsReducer }) => timeTrackingItemsReducer);

  useEffect(() => {
    if (
      Object.keys(approvableTimeTrackingItems).length > 0 &&
      Object.keys(timeTrackingItemsByLeader).length > 0
    ) {
      setApprovableItems({
        ...approvableTimeTrackingItems,
        ...timeTrackingItemsByLeader
      });
    } else if (
      Object.keys(approvableTimeTrackingItems).length > 0 &&
      Object.keys(timeTrackingItemsByLeader).length <= 0
    ) {
      setApprovableItems({ ...approvableTimeTrackingItems });
    } else if (
      Object.keys(approvableTimeTrackingItems).length <= 0 &&
      Object.keys(timeTrackingItemsByLeader).length > 0
    ) {
      setApprovableItems({ ...timeTrackingItemsByLeader });
    }
  }, [approvableTimeTrackingItems, timeTrackingItemsByLeader]);

  const handleChangePage = (_, pageNumber) => {
    setPage(pageNumber);
  };

  const openDialog = id => {
    setRowId(id);
    setOpen(true);
  };

  const handleOnClickRejectedDialog = id => {
    onReject(rowId);
    setOpen(false);
  };

  const handleOnChangeMotivo = value => {
    setMotivo(value);
    if (value.length > 0 && value.length < 250) {
      setDisableButton(false);
      setMotivoError("");
    } else if (value.length === 0 || value.length === 250) {
      setMotivoError(
        "Debes completar este campo con 250 carácteres como máximo."
      );
      setDisableButton(true);
    }
  };

  const handleCloseMotivoDialog = () => {
    setOpen(false);
    setMotivo("");
  };

  const customRow = rowData => {
    return (
      <tr>
        <td colSpan="7">
          <table style={{ width: "100%", overflow: "hidden" }}>
            <CustomGroupRow {...rowData} />
          </table>
        </td>
      </tr>
    );
  };

  const getPendingToAprove = (items) => {
    const itemsPendingToAprove = items.filter((i) => i.status === "CONFIRMED");
    return itemsPendingToAprove.length;
  };

  const getComponents = (myHashMap, pageNumber) => {
    const comps = [];
    if (myHashMap && trackables && Object.keys(myHashMap).length > 0) {
      const hashMap = Object.values(myHashMap).map(i => [
        ...new Map(i.map(m => [m.id, m])).values()
      ]);

      const users = stableSortHash(hashMap, getComparatorHash());

      const filteredUsers = users.filter(u => u[0].user.id !== authUser.id);

      const orderedUsers = filteredUsers.filter(row => {
        let rows;
        if (searchTerm === "") {
          rows = row;
        } else if (
          row[0].user.first_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          rows = row;
        } else if (
          row[0].user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          rows = row;
        }
        return rows;
      });

      const firstElement = (pageNumber - 1) * pageSize;
      const lastElement = firstElement + pageSize;

      if (orderedUsers.length > 0) {
        orderedUsers.slice(firstElement, lastElement).map(item =>
          comps.push(
            <Grid item xs={12} md={12} xl={12} key={item.key}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {item[0].user.first_name + " " + item[0].user.last_name}
                  </Typography>
                  {getPendingToAprove(item) > 0 ? (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#3f51b5",
                        width: "20px",
                        paddingLeft: "20px",
                      }}
                    >
                      <Notifications />
                    </Box>
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#f04c16",
                        width: "20px",
                        paddingLeft: "20px",
                      }}
                    ></Box>
                  )}
                </AccordionSummary>
                <AccordionDetails classes={classes} id="ACORDION-DETAILS">
                  <TimetrackingTable
                    actions={actions}
                    columns={columns}
                    title={"Aprobación de Horas:   " + item[0].user.email}
                    timeTrackingItems={item}
                    options={options}
                    components={{
                      GroupRow: rowData => customRow(rowData)
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )
        );
        comps.push(
          <Box className={classes.pagination1} key="pagination">
            <Box className={classes.pagination}>
              <Box
                style={{
                  justifyContent: "center",
                  width: "100%"
                }}
              >
                <Pagination
                  count={Math.ceil(orderedUsers.length / pageSize)}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Box>
            </Box>
          </Box>
        );
      } else {
        comps.push(
          <Grid item xs={12} md={12} xl={12} key="empty-approvals">
            <NoRecordFound>
              <Typography>
                Acá podés visualizar las horas que tenés pendientes de
                aprobación.
              </Typography>
              <Typography>
                No tenés horas por aprobar en este momento.
              </Typography>
            </NoRecordFound>
          </Grid>
        );
      }
    }
    return comps;
  };

  return (
    <Restricted to={HORAS_APROBAR_PROYECTOS} fallback={Forbidden}>
      <GridContainer>
        <Grid item style={{ width: "99%" }}>
          <Helper
            message={
              <div>
                <p>
                  En esta sección aparecen los usuarios con horas pendientes de
                  aprobación.
                </p>
                <p>
                  Presionando <strong>&#10004;</strong> aprobás el registro de
                  esa carga de horas.
                </p>
                <p>
                  Presionando <strong>X</strong> desaprobás el registro de esa
                  carga de horas y las horas le apareceran al usuario para que
                  las corrija.
                </p>
                <p>
                  Si un registro en estado pendiente no tiene ninguna de las
                  acciones <strong>&#10004;</strong> <strong>X</strong>{" "}
                  significa que esas horas deben ser aprobadas por otro gestor.
                </p>
                <p>
                  Una vez que una carga de horas sea aprobada solo es editable
                  por Capital Humano.
                </p>
              </div>
            }
          />
        </Grid>

        <Box className={classes.buttonContainer}>
          <Button
            id="btn-crearReportes"
            className={classes.buttonStyle}
            onClick={() => setIsOpenCreateReport(true)}
          >
            Crear reporte
          </Button>

          <Box className={classes.centerContainer}>
            <Button
              id="btn-verReportes"
              className={classes.buttonStyle}
              onClick={() =>
                window.open(
                  "https://drive.google.com/drive/folders/1S3YqJNi1miSN1F_ynuQM7RARMLCwgPBD",
                  "_blank"
                )
              }
            >
              Ver reportes
            </Button>
          </Box>
        </Box>



        <Box style={{ width: "100%", marginLeft: "10px" }}>
          <SearchBox
            placeholder="Buscar..."
            onSearch={e => setSearchTerm(e.target.value)}
          />
        </Box>
        {getComponents(approvableItems, page)}
        <Dialog open={open} className={classes.dialogRoot}>
          <DialogTitle className={classes.dialogTitleRoot}>
            Por favor escribí el motivo por el cual rechazas las horas
          </DialogTitle>
          <DialogContent dividers>
            <Box mb={{ xs: 6, md: 5 }}>
              <AppTextInput
                id="RejectedTextInput"
                fullWidth
                multiline
                variant="outlined"
                value={motivo}
                onChange={e => {
                  handleOnChangeMotivo(e.target.value);
                }}
                helperText={motivoError}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                id="btn-cancelar"
                color="secondary"
                onClick={() => handleCloseMotivoDialog()}
                ml={2}
              >
                Cancelar
              </Button>
              <Button
                id="btn-enviar"
                color="primary"
                onClick={() => handleOnClickRejectedDialog()}
                disabled={disableButton}
              >
                Enviar
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        <DownloadReport
          isOpenCreateReport={isOpenCreateReport}
          setIsOpenCreateReport={setIsOpenCreateReport}
          userName={authUser && authUser.first_name + " " + authUser.last_name}
        />
      </GridContainer>
    </Restricted>
  );
}
