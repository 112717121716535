import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { useDispatch, useSelector } from "react-redux";
import { getTraining } from "redux/actions/Trainings";
import IconButton from "@material-ui/core/IconButton";
import { useParams } from "react-router-dom";
import Header from "./Header";
import { Block, CheckCircleOutline } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import TrackableAsignation from "@jumbo/components/TrackableAsignation";
import Restricted from "services/permissionProvider/Restricted";
import { CAPACITACIONES_VER } from "services/permissionProvider/PermissionsCatalog";
import Forbidden from "@jumbo/components/Forbidden";
import { useHasPrivilege } from "routes/checkPermission";

const useStyles = makeStyles(() => ({
  pageFull: {
    width: "100%"
  },

  profileMainContent: {
    "@media screen and (min-width: 1280px) and (max-width: 1499px)": {
      flexBasis: "100%",
      maxWidth: "100%"
    }
  }
}));

const ProjectDetailView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { currentTraining } = useSelector(
    ({ trainingsReducer }) => trainingsReducer
  );

  const dispatch = useDispatch();
  const canDispatch = useHasPrivilege(CAPACITACIONES_VER);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getTraining(id));
    }
  }, [dispatch, id, canDispatch]);

  return (
    <Restricted to={CAPACITACIONES_VER} fallback={Forbidden}>
      <React.Fragment>
        {currentTraining && (
          <Box className={classes.pageFull}>
            <Header
              classes={classes}
              assignedUsers={[]}
              trackable={currentTraining.time_trackable}
            />
            <Box ml="auto" mt={-2} display="flex" alignItems="center">
              <Box ml={1}>
                <Tooltip title={currentTraining.status}>
                  <IconButton aria-label="filter list">
                    {currentTraining.status === "suspended" && (
                      <Block color="primary" />
                    )}
                    {currentTraining.status === "active" && (
                      <CheckCircleOutline color="primary" />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <GridContainer>
              <Grid
                item
                xs={12}
                mb={12}
                lg={12}
                className={classes.profileSidebar}
              >
                <Box>
                  <TrackableAsignation
                    trackableId={currentTraining.time_trackable.id}
                  />
                </Box>
              </Grid>
            </GridContainer>
          </Box>
        )}
      </React.Fragment>
    </Restricted>
  );
};

export default ProjectDetailView;
