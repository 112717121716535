import * as React from "react";
import { Brightness2, Brightness4 } from "@material-ui/icons";
import { Box, IconButton, Typography } from "@material-ui/core";
import AppContext from "@jumbo/components/contextProvider/AppContextProvider/AppContext";
import { useState } from "react";
import { useContext } from "react";
import { THEME_TYPES } from "@jumbo/constants/ThemeOptions";
import { useEffect } from "react";

const ColorModeSwitch = () => {
  const [themeState, setThemeState] = useState("claro");
  const { themeType, setThemeType } = useContext(AppContext);
  const mode = THEME_TYPES;

  const handleChangeColorMode = () => {
    if (themeState === "claro") {
      setThemeState("oscuro");
      setThemeType(mode.DARK);
      sessionStorage.setItem("colorMode", "dark");
      window.location.reload();
    } else {
      setThemeState("claro");
      setThemeType(mode.LIGHT);
      sessionStorage.setItem("colorMode", "light");
      window.location.reload();
    }
  };

  useEffect(() => {
    if (themeType === mode.LIGHT) {
      setThemeState("claro");
    } else {
      setThemeState("oscuro");
    }
  }, [themeType, mode.LIGHT]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "200px",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "transparent",
        color: "text.primary",
        borderRadius: 1,
        p: 3
      }}
    >
      <Typography variant="h6">Modo {themeState}</Typography>
      <IconButton
        sx={{ ml: 1 }}
        onClick={() => handleChangeColorMode()}
        color="inherit"
      >
        {themeState === "oscuro" ? <Brightness2 /> : <Brightness4 />}
      </IconButton>
    </Box>
  );
};
export default ColorModeSwitch;
