import {
  ADD_PROJECT,
  DELETE_BULK_PROJECTS,
  DELETE_PROJECT,
  EDIT_PROJECT,
  GET_PROJECTS,
  GET_PROJECT,
  GET_GESTORES,
  SET_PROJECT_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  projects: [],
  currentProject: null,
  gestores: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS: {
      return {
        ...state,
        projects: action.payload
      };
    }
    case GET_PROJECT: {
      return {
        ...state,
        currentProject: action.payload
      };
    }
    case SET_PROJECT_DETAILS: {
      return {
        ...state,
        currentProject: action.payload
      };
    }
    case ADD_PROJECT: {
      return {
        ...state,
        projects: [action.payload, ...state.projects]
      };
    }
    case EDIT_PROJECT: {
      return {
        ...state,
        projects: state.projects.map(project =>
          project.id === action.payload.id ? action.payload : project
        )
      };
    }
    case DELETE_PROJECT: {
      return {
        ...state,
        projects: state.projects.filter(
          project => project.id !== action.payload
        )
      };
    }
    case DELETE_BULK_PROJECTS: {
      return {
        ...state,
        projects: state.projects.filter(
          project => !action.payload.includes(project.id)
        )
      };
    }
    case GET_GESTORES: {
      return {
        ...state,
        gestores: action.payload
      };
    }
    default:
      return state;
  }
};
