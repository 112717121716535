import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Block,
  CheckCircleOutline,
  Edit,
  MoreHoriz,
  Visibility
} from "@material-ui/icons";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import {
  sentMailToLicence,
  updateLicence
} from "../../../../redux/actions/Licences";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const LicenceListRow = ({
  row,
  isSelected,
  onRowClick,
  onLicenceEdit,
  onLicenceDelete,
  onLicenceView
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getLicenceActions = licence => {
    const actions = [
      {
        action: "view",
        label: "Ver",
        icon: <Visibility id="btn-verLicencia" />
      },
      {
        action: "edit",
        label: "Editar",
        icon: <Edit id="btn-editarLicencia" />
      }
    ];

    row.status === "ACTIVE"
      ? actions.push({
          action: "suspend",
          label: "Suspender",
          icon: <Block id="btn-suspenderLicencia" />
        })
      : actions.push({
          action: "activate",
          label: "Activar",
          icon: <CheckCircleOutline />
        });
    return actions;
  };

  const onLicenceMenuClick = menu => {
    if (menu.action === "view") {
      onLicenceView(row);
    } else if (menu.action === "edit") {
      onLicenceEdit(row);
    } else if (menu.action === "email") {
      dispatch(sentMailToLicence());
    } else if (menu.action === "suspend") {
      dispatch(updateLicence({ id: row.id, status: "SUSPENDED" }));
    } else if (menu.action === "activate") {
      dispatch(updateLicence({ id: row.id, status: "ACTIVE" }));
    } else if (menu.action === "delete") {
      onLicenceDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const licenceActions = getLicenceActions(row);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row.id)}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
    >
      <TableCell padding="checkbox" />
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={licenceActions}
          onItemClick={onLicenceMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(LicenceListRow);
