import React from "react";
import Box from "@material-ui/core/Box";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

const Forbidden = (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100%"
  >
    <Box fontSize={{xs: 40, sm: 80}}>No Autorizado</Box>
    <Box fontSize={{xs: 16, sm: 24}} mb={8} color="grey.500">
      Contenido no disponible. Por favor contacte a su Administrador
    </Box>
    <Box mt={8}>
      <Link to={{pathname: "/"}}>
        <Button variant="contained" color="primary">
          Ir al Inicio
        </Button>
      </Link>
    </Box>
  </Box>
);

export default Forbidden;
