import Forbidden from "@jumbo/components/Forbidden";
import GridContainer from "@jumbo/components/GridContainer";
import {
  Avatar,
  Box,
  CardMedia,
  CardContent,
  Grid,
  IconButton,
  Modal,
  Typography,
  Tooltip,
  Zoom,
  Button,
  makeStyles,
  Card,
  CardHeader
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Restricted from "services/permissionProvider/Restricted";
//import { BENEFICIOS_VER, BENEFICIOS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import {
  NOTICIAS_VER,
  NOTICIAS_EDITAR
} from "services/permissionProvider/PermissionsCatalog";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getUserActivity,
  deleteUserActivity
} from "redux/actions/UserActivities";
import { Delete } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import { useHasPrivilege } from "routes/checkPermission";
// import ReactPlayer from "react-player";

const useStyles = makeStyles(theme => ({
  button: {
    padding: "15px",
    marginBottom: "20px",
    borderRadius: "20px",
    boxShadow: "0 0 5px 5px rgba(168, 175, 206, 0.8)"
  },
  title: {
    fontSize: "35px"
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  }
}));

const MyModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const BenefitsDetailView = () => {
  const canDispatch = useHasPrivilege(NOTICIAS_VER);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { currentActivity } = useSelector(
    ({ userActivitiesReducer }) => userActivitiesReducer
  );
  const { authUser } = useSelector(({ auth }) => auth);
  const [open, setOpen] = useState(false);
  const MySwal = withReactContent(Swal);
  useEffect(() => {
    if (canDispatch) {
      dispatch(getUserActivity(id, authUser.id, () => {}));
    }
  }, [dispatch, id, authUser, canDispatch]);

  const handleDelete = () => {
    handleDeleteBenefits(currentActivity.id);
    history.push("/beneficios");
    MySwal.fire("¡Listo!", "El beneficio se elimino correctamente", "success");
  };

  const handleDeleteBenefits = id => {
    dispatch(deleteUserActivity(id));
  };

  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      <Restricted to={NOTICIAS_VER} fallback={Forbidden}>
        {currentActivity !== null ? (
          <React.Fragment>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar aria-label="cdt-pic">
                    <img
                      src="https://media-exp1.licdn.com/dms/image/C4D0BAQE-f4fkYhjAtQ/company-logo_200_200/0/1556211617173?e=2147483647&v=beta&t=n2WH60sO4EFD_A1L3_9rFLX3iRm4hxfG4ZGTF9vx6Uw"
                      alt="CDT"
                    />
                  </Avatar>
                }
                action={
                  <Box>
                    <Restricted to={NOTICIAS_EDITAR}>
                      <Tooltip title="Eliminar" TransitionComponent={Zoom}>
                        <IconButton
                          aria-label="delete"
                          onClick={e => setOpen(true)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Restricted>
                  </Box>
                }
              />
              <Box display="flex" justifyContent="center">
                <Typography className={classes.font}>
                  {currentActivity.title}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography h3>
                  {moment(currentActivity.publish_date.slice(0, 10)).format(
                    "DD/MM/yyyy"
                  )}
                </Typography>
              </Box>
              {currentActivity.image_url.trim() !== "" ? (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    image={currentActivity.image_url}
                    alt="something"
                  />
                </Box>
              ) : (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    src="/images/LogoCapitalHumano.jpg"
                    alt="something"
                  />
                </Box>
              )}

              {/*  {currentActivity.video_url !== "" ? (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                <ReactPlayer 
                  url={[ {src: currentActivity.video_url} ]}
                  light='true' 
                  height='350px'
                  width='350px'
                  controls={true}
                /> 
                </Box> 
              ) : (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    src="/images/LogoCapitalHumano.jpg"
                    alt="something"
                  />
                </Box>
              )} */}

              <CardContent>
                <Typography color="textSecondary">
                  {currentActivity.description}
                </Typography>
              </CardContent>
            </Card>
            <MyModal
              open={open}
              onClose={e => setOpen(false)}
              aria-labelledby="modal-delete-news"
              aria-describedby="modal-message"
            >
              <Box
                width={300}
                height={140}
                bgcolor={"background.default"}
                color={"text.primary"}
                p={3}
                borderRadius="10px"
                overflow="auto"
              >
                <Typography
                  id="modal-message"
                  variant="h6"
                  component="p"
                  style={{
                    marginTop: "20px",
                    fontSize: "15px",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  ¿Desea eliminar este beneficio?
                </Typography>
                <GridContainer
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                  }}
                >
                  <Grid item>
                    <Button
                      id="btn-eliminarNoticia"
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "20px" }}
                      onClick={() => handleDelete(currentActivity.id)}
                    >
                      eliminar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      id="btn-cancelar"
                      variant="contained"
                      color="default"
                      style={{ marginTop: "20px" }}
                      onClick={e => setOpen(false)}
                    >
                      cancelar
                    </Button>
                  </Grid>
                </GridContainer>
              </Box>
            </MyModal>
          </React.Fragment>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box fontSize={{ xs: 40, sm: 80 }}>No Autorizado</Box>
            <Box fontSize={{ xs: 16, sm: 24 }} mb={8} color="grey.500">
              Contenido no disponible. Por favor contacte a su Administrador
            </Box>
            <Box mt={8}>
              <Link to={{ pathname: "/" }}>
                <Button variant="contained" color="primary">
                  Ir al Inicio
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Restricted>
    </Box>
  );
};

export default BenefitsDetailView;
