import React, { useEffect } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { USER_ACTIVITY_ALERT } from "@jumbo/constants/ActionTypes";

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: "20px",
    color: "#43A205",
    marginBottom: "5px"
  },
  box: {
    width: "60%",
    height: "20px",
    paddingTop: "20px",
    margin: "0 auto 15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center"
  }
}));

const UserActivityAlert = () => {
  const { userActivityAlert } = useSelector(
    ({ userActivitiesReducer }) => userActivitiesReducer
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userActivityAlert !== "") {
      const timeoutId = setTimeout(() => {
        dispatch({
          type: USER_ACTIVITY_ALERT,
          payload: ""
        });
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [dispatch, userActivityAlert]);

  return (
    <Box className={classes.box}>
      <Typography className={classes.text}>{userActivityAlert}</Typography>
    </Box>
  );
};

export default UserActivityAlert;
