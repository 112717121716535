import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import AppTextInput from "../../../../@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  isValidEmail,
  isValidName,
  isValidCuil
} from "../../../../@jumbo/utils/commonHelper";
import {
  addNewUser,
  getAreas,
  getJobTitles,
  getLeaders,
  updateUser
} from "../../../../redux/actions/Users";
import { getRoles } from "redux/actions/Roles";
import { Dialog, Divider, MenuItem, Typography } from "@material-ui/core";
import AddRolesToUser from "routes/modules/Roles/AddRolesToUser";
import UserAlert from "@jumbo/components/UserAlert";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
//import moment from "moment";
//import { addNews } from 'redux/actions/UserActivities';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
      display: "flex",
      justifyContent: "center"
    }
  },
  input: {
    width: "250px"
  }
}));

const AddUser = ({ open, onCloseDialog, users }) => {
  const classes = useStyles();
  const { leaders, areas, jobTitles } = useSelector(
    ({ usersReducer }) => usersReducer
  );

  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email, setEmail] = useState("");
  const [cuil, setCuil] = useState("");
  const [leaderId, setLeaderId] = useState("");
  const [areaId, setAreaId] = useState("");
  const [jobTitleId, setJobTitleId] = useState("");

  /*   const [birthdayDate, setBirthdayDate] = useState("");
  const [joinningDay, setJoinningDay] = useState("");
  const [personalFilePic, setPersonalFilePic] = useState("");
  const [assignedClient, setAssignedClient] = useState(""); */

  const [firstNameError, setFirstNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [cuilError, setCuilError] = useState("");
  const [leaderError, setLeaderError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");

  /*   const [birthdayDateError, setBirthdayDateError] = useState("");
  const [joinningDayError, setJoinningDayError] = useState("");
  const [personalFilePicError, setPersonalFilePicError] = useState("");
  const [assignedClientError, setAssignedClientError] = useState(""); */

  const [onAddUserRoles, setOnAddUserRoles] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [activateUser, setActivateUser] = useState(false);
  const [updated, setUpdated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles([], "", () => { }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLeaders([], "", () => { }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAreas([], "", () => { }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getJobTitles([], "", () => { }));
  }, [dispatch]);

  /*     const setAdmissionNew = () => {
        const allAreas = areas.map(area => {
            return { name: area.name };
        });
        const todaysDate = moment(new Date()).format('YYYY-MM-DD');
        const aWeekLaterDate = moment(todaysDate).add(7, 'days').format('YYYY-MM-DD');
        const newInfo = {
            title: '¡Te damos la bienvenida!',
            description: "#TeamCDT #BienvenidaCDT",
            type: "WORKSHOP",
            image_url: "", //imagen creada previamente y guardada en drive o nube
            expiration_date: aWeekLaterDate,
            publish_date: todaysDate,
            recipient_areas: allAreas,
            recipient_users: []
        }
        dispatch(addNews(newInfo, () => {  Ofrecer descargar la foto de la noticia o avisar que se guardó en drive  }));
   // FALTA FUNCION NOTICIA CUMPLEAÑOS
    } */

  const suspendedUsers = users.filter(user => user.status === "SUSPENDED");

  const checkIfUserAlreadyExist = () => {
    const uEmail = users.map(user => user.email);
    const uCuil = users.map(user => user.cuil);
    if (uEmail.includes(email)) {
      setEmailError("Ya existe");
      const isSuspended = suspendedUsers.filter(user => user.email === email);
      if (isSuspended.length > 0) {
        setActivateUser(true);
      }
    } else if (uCuil.includes(cuil)) {
      setCuilError("Ya existe");
      const isSuspended = suspendedUsers.filter(user => user.cuil === cuil);
      if (isSuspended.length > 0) {
        setActivateUser(true);
      }
    } else {
      onUserSave();
    }
  };

  const onSubmitClick = () => {
    if (!isValidName(first_name) || first_name.trim().length < 2) {
      setFirstNameError("Invalido");
    } else if (!isValidName(last_name) || last_name.trim().length < 2) {
      setLastNameError("Invalido");
    } else if (!isValidEmail(email)) {
      setEmailError("Invalido");
    } else if (!isValidCuil(cuil)) {
      setCuilError("Invalido");
    } else if (leaderId === null || leaderId === "") {
      setLeaderError("Incompleto");
    } else if (areaId === null || areaId === "") {
      setAreaError("Incompleto");
    } else if (jobTitleId === null || jobTitleId === "") {
      setJobTitleError("Incompleto");
      /*  } else if (birthdayDate === null || birthdayDate === "") {
      setBirthdayDateError("Incompleto");
    } else if (personalFilePic === null || personalFilePic === "") {
      setPersonalFilePicError("Incompleto");
    } else if (joinningDay === null || joinningDay === "") {
      setJoinningDayError("Incompleto");
    } else if (assignedClient === null || assignedClient === "") {
      setAssignedClientError("Incompleto"); */
    } else {
      checkIfUserAlreadyExist();
    }
  };

  const handleUserAddRoles = () => {
    setDisabledInputs(true);
    setOnAddUserRoles(true);
  };

  const MySwal = withReactContent(Swal);

  const onUserSave = () => {
    const leader = leaderId !== "" ? Object.assign({}, { id: leaderId }) : null;
    const area = Object.assign({}, { id: areaId });
    const job_title = Object.assign({}, { id: jobTitleId });
    //const birthday = moment(birthdayDate).format("DD/MM/YYYY");
    //const joinningDate = moment(joinningDay).format("DD/MM/YYYY");
    //const assigned_client = assignedClient;

    //ESPERAR A QUE LOS CAMPOS NUEVOS ESTEN EN BACKEND MAIN PARA PROBAR
    const userDetail = {
      first_name,
      last_name,
      email,
      cuil,
      leader,
      area,
      job_title
      //birthday
      //assigned_client,
      //joinningDate,
      //personalFilePic,
    };

    dispatch(addNewUser(userDetail, () => { }));
    handleUserAddRoles();
  };

  const onCloseUpdateAlert = () => {
    setUpdateAlert(false);
    onCloseDialog();
  };

  const activateExistingUser = () => {
    const userToUpdateEmail = users.filter(user => user.email === email);
    const userToUpdateCuil = users.filter(user => user.cuil === cuil);
    if (!!emailError) {
      dispatch(
        updateUser({ id: userToUpdateEmail[0].id, status: "ACTIVE" }, () => {
          MySwal.fire(
            "¡Listo!",
            "El usuario se activó correctamente",
            "success"
          );
          setEmailError("");
          onCloseDialog();
        })
      );
    }

    if (!!cuilError) {
      dispatch(
        updateUser({ id: userToUpdateCuil[0].id, status: "ACTIVE" }, () => {
          setCuilError("");
          setUpdated(true);
        })
      );
    }
  };

  const onCloseActivateUser = () => {
    setActivateUser(false);
    onCloseDialog();
  };

  const nombreFirma = first_name + last_name;
  const signatureName = "Firma" + nombreFirma;

  const orderedLeaders = stableSort(
    leaders,
    getComparator("asc", "first_name")
  );

  return (
    <Dialog open={open} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        Crear nuevo usuario
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box display="flex">
            <Box display="flex" flexDirection="column" m={2}>
              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  id="txt-nombreUsuario"
                  className={classes.input}
                  fullWidth
                  disabled={disabledInputs}
                  variant="outlined"
                  label={<Typography variant="h6">Nombre</Typography>}
                  value={first_name}
                  onChange={e => {
                    setFirst_Name(e.target.value);
                    setFirstNameError("");
                  }}
                  helperText={
                    firstNameError === "Invalido"
                      ? "El nombre no es válido"
                      : ""
                  }
                />
              </Box>

              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  id="txt-apellidoUsuario"
                  className={classes.input}
                  fullWidth
                  disabled={disabledInputs}
                  variant="outlined"
                  label={<Typography variant="h6">Apellido</Typography>}
                  value={last_name}
                  onChange={e => {
                    setLast_Name(e.target.value);
                    setLastNameError("");
                  }}
                  helperText={
                    lastNameError === "Invalido"
                      ? "El apellido no es válido"
                      : ""
                  }
                />
              </Box>

              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  id="txt-emailUsuario"
                  className={classes.input}
                  fullWidth
                  disabled={disabledInputs}
                  variant="outlined"
                  label={<Typography variant="h6">Dirección Email</Typography>}
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  helperText={
                    emailError === "Ya existe"
                      ? "El email ingresado ya existe"
                      : emailError === "Invalido"
                        ? "El email no es válido"
                        : ""
                  }
                />
              </Box>

              {/* ESPERAR A QUE LOS CAMPOS NUEVOS ESTEN EN BACKEND MAIN PARA PROBAR */}
              {/*  <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  className={classes.input}
                  fullWidth
                  type="date"
                  disabled={disabledInputs}
                  variant="outlined"
                  label="Fecha de Nacimiento"
                  value={birthdayDate}
                  onChange={e => {
                    setBirthdayDate(e.target.value);
                    setBirthdayDateError("");
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={
                    birthdayDateError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box> */}

              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  id="txt-cuilUsuario"
                  className={classes.input}
                  disabled={disabledInputs}
                  fullWidth
                  variant="outlined"
                  label={<Typography variant="h6">Cuil</Typography>}
                  value={cuil}
                  onChange={e => {
                    setCuil(e.target.value);
                    setCuilError("");
                  }}
                  helperText={
                    cuilError === "Ya existe"
                      ? "El cuil ingresado ya existe"
                      : cuilError === "Invalido"
                        ? "El cuil debe contener 11 números"
                        : ""
                  }
                />
              </Box>

              {/* ESPERAR A QUE LOS CAMPOS NUEVOS ESTEN EN BACKEND MAIN PARA PROBAR */}
              {/* <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  className={classes.input}
                  fullWidth
                  disabled={disabledInputs}
                  variant="outlined"
                  label="Foto de usuario"
                  value={personalFilePic}
                  onChange={e => {
                    setPersonalFilePic(e.target.value);
                    setPersonalFilePicError("");
                  }}
                  helperText={
                    personalFilePicError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box>
            </Box> */}

              {/* ESPERAR A QUE LOS CAMPOS NUEVOS ESTEN EN BACKEND MAIN PARA PROBAR */}
              {/* <Box display="flex" flexDirection="column" m={2}>
              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  className={classes.input}
                  fullWidth
                  type="date"
                  disabled={disabledInputs}
                  variant="outlined"
                  label="Fecha de Ingreso a CDT"
                  value={joinningDay}
                  onChange={e => {
                    setJoinningDay(e.target.value);
                    setJoinningDayError("");
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={
                    joinningDayError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box> */}

              {/* ESPERAR A QUE LOS CAMPOS NUEVOS ESTEN EN BACKEND MAIN PARA PROBAR */}
              {/* <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  className={classes.input}
                  fullWidth
                  disabled={disabledInputs}
                  variant="outlined"
                  label="Cliente Asignado"
                  value={assignedClient}
                  onChange={e => {
                    setAssignedClient(e.target.value);
                    setAssignedClientError("");
                  }}
                  helperText={
                    assignedClientError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box> */}

              <Box mb={{ xs: 6, md: 5 }}>
                <AppSelectBox
                  id="sel-listaLideres"
                  disabled={disabledInputs}
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  data={orderedLeaders.filter(
                    leader => leader.status !== "SUSPENDED"
                  )}
                  value={leaderId}
                  renderRow={(item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.first_name + " " + item.last_name}
                    </MenuItem>
                  )}
                  valueKey="id"
                  label="Líder"
                  onChange={e => {
                    setLeaderId(e.target.value);
                    setLeaderError("");
                  }}
                  helperText={
                    leaderError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box>

              <Box mb={{ xs: 6, md: 5 }}>
                <AppSelectBox
                  id="sel-listaAreas"
                  disabled={disabledInputs}
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  data={areas}
                  value={areaId}
                  valueKey="id"
                  labelKey="name"
                  label="Área"
                  onChange={e => {
                    setAreaId(e.target.value);
                    setAreaError("");
                  }}
                  helperText={
                    areaError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box>

              <Box mb={{ xs: 6, md: 5 }}>
                <AppSelectBox
                  id="sel-listaPosiciones"
                  disabled={disabledInputs}
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  data={jobTitles}
                  value={jobTitleId}
                  valueKey="id"
                  labelKey="name"
                  label="Posición"
                  onChange={e => {
                    setJobTitleId(e.target.value);
                    setJobTitleError("");
                  }}
                  helperText={
                    jobTitleError === "Incompleto"
                      ? "Debes completar este campo"
                      : ""
                  }
                />
              </Box>
            </Box>
          </Box>

          <Divider />

          <Box marginTop="20px">
            {onAddUserRoles ? (
              <AddRolesToUser
                setOnAddUserRoles={setOnAddUserRoles}
                onCloseUpdateAlert={onCloseUpdateAlert}
                users={users}
                signatureName={signatureName}
              />
            ) : (
              !updateAlert && (
                <Box display="flex" justifyContent="space-around" mb={4}>
                  <Box>
                    <Button onClick={onCloseDialog} id="btn-cancelar">
                      Cancelar
                    </Button>
                  </Box>
                  <Box ml={2}>
                    <Button
                      id="btn-guardarCambios"
                      variant="contained"
                      color="primary"
                      onClick={onSubmitClick}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Box>
              )
            )}
          </Box>
          <Dialog open={activateUser}>
            {updated ? (
              <Box
                m={4}
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <UserAlert />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onCloseActivateUser()}
                >
                  Aceptar
                </Button>
              </Box>
            ) : (
              <Box
                m={4}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                padding="20px 20px 0px 20px"
              >
                <Typography>
                  Existe un usuario suspendido con estos datos:
                </Typography>
                <Typography>
                  {!!emailError && "Dirección Email: " + email + " "}
                  {!!cuilError && "Cuil: " + cuil + " "}
                </Typography>
                <Box display="flex" justifyContent="center">
                  <Typography>¿Deseas reactivarlo?</Typography>
                </Box>
                <Box padding="15px">
                  <Divider />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Box m={2}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setActivateUser(false)}
                    >
                      Cancelar
                    </Button>
                  </Box>
                  <Box m={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => activateExistingUser()}
                    >
                      Activar
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Dialog>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddUser;

AddUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
