import {
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  Modal,
  styled,
  Tooltip,
  Typography
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import React from "react";

const MyModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const Beneficio = ({title, icon, image, beneficio, texto}) => {
  const [ open, setOpen ] = React.useState(false);

  return (
    <Box>
      <Card style={{ width: "190px" }}>
        <CardHeader
          avatar={icon}
          action={
            <Tooltip title="Ver más">
              <IconButton
                aria-label="expand-more"
                color="default"
                onClick={e => setOpen(true)}
                sx={{ padding: "4px" }}
              >
                <ExpandMore />
              </IconButton>
            </Tooltip>
          }
          title={title}
          titleTypographyProps={{
            variant: "h6",
            style: { fontSize: "11px" }
          }}
        />
        <CardMedia
          component="img"
          height="300"
          width="100%"
          image={image}
          sx={{
            height: "10px",
            width: "100%",
            aspectRatio: "1 / 1",
            objectFit: "cover",
            objectPosition: "center"
          }}
          alt="Imagen Beneficio"
        />
      </Card>
      <MyModal
        open={open}
        onClose={e => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          maxWidth={500}
          maxHeight={500}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={7}
          borderRadius="10px"
          overflow="auto"
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            style={{
              fontSize: "20px",
              textAlign: "center",
              fontWeight: "bold"
            }}
          >
            {beneficio}
          </Typography>
          <Divider />
          <Typography
            id="modal-description"
            variant="h6"
            component="p"
            style={{
              marginTop: "20px",
              fontSize: "15px",
              fontFamily: "sans-serif",
              fontWeight: "lighter"
            }}
          >
            {texto}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{marginTop: "20px"}}
            onClick={e => setOpen(false)}
          >
            cerrar
          </Button>
        </Box>
      </MyModal>
    </Box>
  );
};

export default Beneficio;
