import React from "react";
import {useSelector} from "react-redux";
import {Alert} from "@material-ui/lab";
import {Collapse} from "@material-ui/core";

export default function Helper({message}){
  const {showHelp} = useSelector(({common}) => common);

  return (
    <Collapse in={showHelp}>
      <Alert severity="info">{message}</Alert>
    </Collapse>
  );
}
