import React from "react";
import { Box, Divider, TextField } from "@material-ui/core";
import Restricted from "services/permissionProvider/Restricted";
import { HORAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";

const AddOneDay = ({
  setFechaInicio,
  fechaInicioValida,
  setFechaInicioValida
}) => {
  const handleChange = date => {
    if (date === "" || date === null) {
      setFechaInicioValida("mostrar");
    } else {
      setFechaInicioValida(true);
      setFechaInicio(date);
    }
  };

  return (
    <Restricted to={HORAS_EDITAR}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center"
        }}
      >
        <Box style={{ display: "flex", justifyContent: "left" }}>
          <Box display="block" padding="12px">
            <TextField
              id="fecha"
              InputLabelProps={{ shrink: true }}
              style={{ width: "338px" }}
              label="Fecha"
              variant="outlined"
              type="date"
              locale="es-ES"
              format="DD/MM/YYYY"
              helperText={
                fechaInicioValida === "mostrar" ? "Fecha inválida" : ""
              }
              onChange={e => handleChange(e.target.value)}
            />
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "20px"
          }}
        >
          <Divider />
        </Box>
      </Box>
    </Restricted>
  );
};
export default AddOneDay;
