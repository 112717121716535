import React from "react";
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";

import Box from "@material-ui/core/Box";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallIcon from "@material-ui/icons/Call";
import IconButton from "@material-ui/core/IconButton";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { blue, pink } from "@material-ui/core/colors";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: "100%"
  },
  selectBoxRoot: {
    marginBottom: 6,
    display: "inline-block",
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      display: "none"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    },
    "& .MuiInputBase-root": {
      fontSize: 14,
      color: theme.palette.text.secondary
    }
  },
  addressTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up("lg")]: {
      fontSize: 18
    }
  },
  socialLink: {
    display: "flex",
    alignItems: "center",
    marginLeft: -8,
    marginRight: -8
  },
  socialLinkCol: {
    paddingLeft: 8,
    paddingRight: 8,
    "& .btn": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
      padding: 6,
      "& .MuiSvgIcon-root": {
        fontSize: 20
      },
      "&.twitter": {
        backgroundColor: alpha(blue[500], 0.1),
        color: blue[500]
      },
      "&.instagram": {
        backgroundColor: alpha(pink[500], 0.1),
        color: pink[500]
      },
      "&.linkedin": {
        backgroundColor: alpha(blue[500], 0.1),
        color: blue[500]
      }
    }
  },
  contactRoot: {
    "& .MuiSvgIcon-root": {
      fontSize: 20
    }
  }
}));

const socialMediaLinks = [
  {
    title: "facebook",
    url: "https://es-la.facebook.com/cdtsolucionestecnologicas",
    icon: <FacebookIcon />
  },
  {
    title: "instagram",
    url: "https://www.instagram.com/cdtsolucionestecnologicas/?hl=es",
    icon: <InstagramIcon />
  },
  {
    title: "linkedin",
    url: "https://ar.linkedin.com/company/cdt-soluciones-tecnol-gicas",
    icon: <LinkedInIcon />
  }
];

const ourOfficeData = {
  title: "Nuestra Oficina en CABA",
  socialProfiles: [
    { title: "twitter", url: "" },
    { title: "facebook", url: "" },
    { title: "instagram", url: "" }
  ],
  addresses: [
    {
      label: "Oficina Central",
      addressLine1: "Av. Córdoba 1345",
      addressLine2: "6to C",
      city: "CABA",
      state: "Ciudad Autónoma de Buenos Aires",
      zipCode: "C1055AAD",
      phoneNumber: "(011)5219-0575",
      emailAddress: "info@cdt.com.ar",
      mapDirectionLink:
        "https://www.google.com/maps/place/Consultores+en+Desarrollos+Tecnol%C3%B3gicos+S.A./@-34.5989178,-58.3882882,17z/data=!3m1!4b1!4m5!3m4!1s0x95bccac778f8a40b:0x378d7c73406ff0c3!8m2!3d-34.5991113!4d-58.3860873"
    }
  ]
};

const OurOffice = () => {
  const { addresses, title, description } = ourOfficeData;
  const classes = useStyles();
  const currentAddress = addresses[0];

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader title={title} subTitle={description} />

      <CmtCardContent>
        <Box className={classes.selectBoxRoot}>
          <Typography>Oficina Central</Typography>
        </Box>
        <Box mb={5}>
          <Typography
            component="div"
            variant="h4"
            className={classes.addressTitle}
          >
            {currentAddress.addressLine1}, {currentAddress.addressLine2},{" "}
            {currentAddress.city}, {currentAddress.state}
          </Typography>
        </Box>

        <Box className={classes.contactRoot} mb={6}>
          <Box display="flex" alignItems="center" mb={3} color="text.secondary">
            <CallIcon />
            <Box ml={3}>{currentAddress.phoneNumber}</Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3} color="text.secondary">
            <MailOutlineIcon />
            <Box ml={3}>{currentAddress.emailAddress}</Box>
          </Box>
        </Box>

        <Box className={classes.socialLink}>
          {socialMediaLinks.map((item, index) => (
            <Box key={index} className={classes.socialLinkCol}>
              <IconButton
                className={`btn ${item.title}`}
                target="_blank"
                href={item.url}
              >
                {item.icon}
              </IconButton>
            </Box>
          ))}
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default OurOffice;
