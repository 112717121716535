import React from "react";
import {
  Person,
  MenuBook,
  PostAdd,
  Computer,
  Group,
  AccessTime,
  EventAvailable,
  Receipt,
  FormatListNumbered,
  AddAlarm,
  Search,
  Create
} from "@material-ui/icons";
import {
  CAPACITACIONES_EDITAR,
  LICENCIAS_EDITAR,
  NOTICIAS_VER,
  PROYECTOS_EDITAR,
  USUARIOS_EDITAR,
  HORAS_EDITAR,
  HORAS_APROBAR_PROYECTOS,
  HORAS_CARGAR,
  USUARIOS_VER
  // FERIADOS_EDITAR
} from "services/permissionProvider/PermissionsCatalog";
import IntlMessages from "../../../utils/IntlMessages";

export const adminSidebarNavs = [
  {
    name: "Principal",
    type: "section",
    children: [
      {
        name: "Perfil",
        type: "item",
        icon: <Person />,
        link: "/perfil",
        privilege: HORAS_CARGAR
      },
      {
        name: "Firmas",
        type: "item",
        icon: <Create />,
        link: "/firmas",
        privilege: HORAS_CARGAR
      },
      {
        name: "Usuarios",
        type: "item",
        icon: <Group />,
        link: "/usuarios",
        privilege: USUARIOS_EDITAR
      },
      {
        name: "Proyectos",
        type: "item",
        icon: <Computer />,
        link: "/proyectos",
        privilege: PROYECTOS_EDITAR
      },
      {
        name: "Licencias",
        type: "item",
        icon: <PostAdd />,
        link: "/licencias",
        privilege: LICENCIAS_EDITAR
      },
      {
        name: "Capacitaciones",
        type: "item",
        icon: <MenuBook />,
        link: "/capacitaciones",
        privilege: CAPACITACIONES_EDITAR
      },
      {
        name: "Noticias",
        type: "item",
        icon: <Receipt />,
        link: "/noticias",
        privilege: NOTICIAS_VER
      },
      {
        name: "Aprobación de horas",
        type: "item",
        icon: <EventAvailable />,
        link: "/aprobacion-de-horas",
        privilege: HORAS_APROBAR_PROYECTOS
      },
      {
        name: "Admin de horas",
        type: "item",
        icon: <FormatListNumbered />,
        link: "/admin-horas",
        privilege: HORAS_EDITAR
      },
      {
        name: "Carga de horas",
        type: "item",
        icon: <AccessTime />,
        link: "/carga-de-horas",
        privilege: HORAS_CARGAR
      },
      {
        name: "Carga de feriados",
        type: "item",
        icon: <AddAlarm />,
        link: "/admin-asignar-feriados",
        privilege: HORAS_EDITAR
      },
      {
        name: "Asignar horas",
        type: "item",
        icon: <AddAlarm />,
        link: "/admin-asignar-horas",
        privilege: HORAS_EDITAR
      },
      {
        name: "Buscador de usuarios",
        type: "item",
        icon: <Search />,
        link: "/buscador",
        privilege: USUARIOS_VER
      }
    ]
  }
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];
