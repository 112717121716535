import React, { useEffect, useState } from "react";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import { Box, Divider, MenuItem, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import AddOneDay from "./AddOneDay";
import AddMoreDays from "./AddMoreDays";
import { getActiveUsers } from "redux/actions/Users";
import { HORAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import Forbidden from "@jumbo/components/Forbidden";
import Restricted from "services/permissionProvider/Restricted";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { useHasPrivilege } from "routes/checkPermission";

const AdminAddHoursModule = () => {
  const [mostrarModulo, setMostrarModulo] = useState(false);
  const [asignarDias, setAsignarDias] = useState("");
  const [tipoAsignacion, setTipoAsignacion] = useState("Proyecto");
  const [user, setUser] = useState("");
  const [projects, setProjects] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [licences, setLicences] = useState([]);
  const [estado, setEstado] = useState("");
  const { activeUsers } = useSelector(({ usersReducer }) => usersReducer);
  const canDispatch = useHasPrivilege(HORAS_EDITAR);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canDispatch) {
      dispatch(getActiveUsers());
    }
  }, [dispatch, canDispatch]);

  useEffect(() => {
    if (user !== "") {
      const proyectos = user.trackables.filter(
        t => t.status === "ACTIVE" && t.type === "PROJECT"
      );
      const capacitaciones = user.trackables.filter(
        t => t.status === "ACTIVE" && t.type === "TRAINING"
      );
      const licencias = user.trackables.filter(
        t => t.status === "ACTIVE" && t.type === "LICENCE"
      );

      const orderedProjects = stableSort(
        proyectos,
        getComparator("asc", "name")
      );

      const orderedTrainings = stableSort(
        capacitaciones,
        getComparator("asc", "name")
      );

      const orderedLicences = stableSort(
        licencias,
        getComparator("asc", "name")
      );

      setProjects(orderedProjects);
      setTrainings(orderedTrainings);
      setLicences(orderedLicences);
    }
  }, [user]);

  const handleChange = e => {
    setMostrarModulo(true);
    switch (e) {
      case "unDia":
        setAsignarDias(e);
        break;
      case "masDeUnDia":
        setAsignarDias(e);
        break;
      case "Proyecto":
        setTipoAsignacion(e);
        break;
      case "Licencia":
        setTipoAsignacion(e);
        break;
      case "Capacitación":
        setTipoAsignacion(e);
        break;
      case "APPROVED":
        setEstado(e);
        break;
      case "CONFIRMED":
        setEstado(e);
        break;
      default:
    }
  };

  const clearInputs = () => {
    setMostrarModulo(false);
    setAsignarDias("");
    setEstado("");
  };

  const orderedUsers = stableSort(
    activeUsers,
    getComparator("asc", "first_name")
  );

  return (
    <Restricted to={HORAS_EDITAR} fallback={Forbidden}>
      <Box display="flex" justifyContent="center">
        <CmtCard
          style={{
            width: "1100px",
            border: "2px solid #83b0fc",
            borderRadius: "8px"
          }}
        >
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" justifyContent="center">
              <CmtCardHeader title={`Asignar horas a usuario`} />
            </Box>

            <Box style={{ width: "1000px", margin: "0px 50px 20px 50px" }}>
              <Divider />
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center"
            }}
          >
            <Box
              style={{
                display: "flex",
                width: "1000px",
                justifyContent: "space-between",
                alignContent: "center",
                marginTop: "20px",
                marginBottom: "20px"
              }}
            >
              <Box display="block" width="300px">
                <TextField
                  variant="outlined"
                  id="cantidadDias"
                  select
                  label="Cantidad de días"
                  style={{ width: "300px" }}
                  onChange={e => handleChange(e.target.value)}
                  value={asignarDias}
                >
                  <MenuItem value={"unDia"} id="item-unDia">
                    Un día
                  </MenuItem>
                  <MenuItem value={"masDeUnDia"} id="item-dias">
                    Más de un día
                  </MenuItem>
                </TextField>
              </Box>
              <Box display="block" width="300px">
                <TextField
                  variant="outlined"
                  id="tipoAsignacion"
                  select
                  label="Tipo de asignación"
                  style={{ width: "300px" }}
                  onChange={e => handleChange(e.target.value)}
                  value={tipoAsignacion}
                >
                  <MenuItem value={"Capacitación"} id="item-capacitacion">
                    Capacitación
                  </MenuItem>
                  <MenuItem value={"Licencia"} id="item-licencia">
                    Licencia
                  </MenuItem>
                  <MenuItem value={"Proyecto"} id="item-proyecto">
                    Proyecto
                  </MenuItem>
                </TextField>
              </Box>
              <Box display="block" width="300px">
                <TextField
                  variant="outlined"
                  id="estadoHoras"
                  select
                  name="type"
                  label="Estado de las horas"
                  style={{ width: "300px" }}
                  onChange={e => handleChange(e.target.value)}
                  value={estado}
                >
                  <MenuItem value={"APPROVED"} id="item-aprobadas">
                    Aprobadas
                  </MenuItem>
                  <MenuItem value={"CONFIRMED"} id="item-pendientes">
                    Pendientes de aprobación
                  </MenuItem>
                </TextField>
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%"
            }}
          >
            {mostrarModulo &&
              (asignarDias === "unDia" ? (
                <AddOneDay
                  usuarios={orderedUsers}
                  user={user}
                  setUser={setUser}
                  tipoAsignacion={tipoAsignacion}
                  setTipoAsignacion={setTipoAsignacion}
                  proyectos={projects}
                  licencias={licences}
                  capacitaciones={trainings}
                  estado={estado}
                  clearInputs={clearInputs}
                />
              ) : (
                asignarDias === "masDeUnDia" && (
                  <AddMoreDays
                    usuarios={orderedUsers}
                    user={user}
                    setUser={setUser}
                    tipoAsignacion={tipoAsignacion}
                    setTipoAsignacion={setTipoAsignacion}
                    proyectos={projects}
                    licencias={licences}
                    capacitaciones={trainings}
                    estado={estado}
                    clearInputs={clearInputs}
                  />
                )
              ))}
          </Box>
        </CmtCard>
      </Box>
    </Restricted>
  );
};
export default AdminAddHoursModule;
