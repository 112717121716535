export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_HELP = "show_help";

export const UPDATE_AUTH_USER = "update_auth_user";
export const UPDATE_LOAD_USER = "update_load_user";
export const SEND_FORGET_PASSWORD_EMAIL = "send_forget_password_email";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";

export const SET_DASHBOARD_DATA = "set_dashboard_data";

export const SET_TASK_CURRENT_USER = "set_task_current_user";
export const SET_TASKS_DATA = "set_tasks_data";
export const SET_TASK_LIST_DATA = "set_task_list_data";
export const ADD_TASK = "add_task";
export const DELETE_TASK = "delete_task";
export const UPDATE_TASK = "update_task";
export const SET_FILTER_DATA = "set_filter_data";
export const ADD_TASK_LIST = "add_task_list";
export const UPDATE_TASK_LIST = "update_task_list";
export const DELETE_TASK_LIST = "delete_task_list";
export const SET_TASK_DETAIL = "set_task_detail";
export const SEND_MESSAGE = "send_message";
export const TOGGLE_SIDEBAR_COLLAPSED = "toggle_sidebar_collapsed";
export const GET_TASKS_COUNTS = "get_tasks_counts";

//mail app
export const GET_LABELS_LIST = "get_labels_list";
export const GET_CONNECTIONS_LIST = "get_connections_list";
export const GET_MAILS_LIST = "get_mails_list";
export const UPDATE_MAIL_FOLDER = "update_mail_folder";
export const UPDATE_MAIL_LABEL = "upade_mail_label";
export const UPDATE_FAVORITE_STATUS = "update_favorite_status";
export const UPDATE_READ_STATUS = "update_read_status";
export const UPDATE_IMPORTANT_STATUS = "update_important_status";
export const COMPOSE_MAIL = "compose_mail";
export const SET_FILTER_TYPE = "set_filter_type";
export const GET_SELECTED_MAIL = "GET_SELECTED_MAIL";
export const UPDATE_SELECTED_MAIL = "update_selected_mail";
export const NULLIFY_SELECTED_MAIL = "nullify_selected_mail";
export const REPLY_TO_MAIL = "reply_to_mail";
export const GET_MAIL_COUNTS = "get_mail_count";
export const ADD_LABEL = "add_label";
export const ADD_CONNECTION = "add_connection";
export const REMOVE_CONNECTION = "remove_connection";

export const SET_CHAT_USERS = "set_chat_users";
export const SET_CONTACT_USERS = "set_contact_users";
export const SET_CURRENT_USER = "set_current_user";
export const SET_CONVERSATION_DATA = "set_conversation_data";
export const SEND_NEW_CHAT_MESSAGE = "send_new_chat_message";
export const SEND_NEW_MEDIA_MESSAGE = "send_new_media_message";

//Contact App
export const GET_CONTACTS_LIST = "get_contacts_list";
export const SET_CURRENT_CONTACT = "set_current_contact";
export const CREATE_CONTACT = "create_contact";
export const UPDATE_STARRED_STATUS = "update_starred_status";
export const DELETE_CONTACT = "delete_contact";
export const UPDATE_CONTACT_LABEL = "update_contact_label";
export const UPDATE_CONTACT = "update_contact";
export const GET_CONTACT_COUNTS = "get_contact_counts";
export const UPDATE_LABEL_ITEM = "update_label_item";
export const DELETE_LABEL_ITEM = "delete_label_item";

export const GET_USER_DETAIL = "get_user_detail";
export const GET_FEED_POSTS = "get_feed_posts";
export const CREATE_POST = "create_post";
export const UPDATE_POST = "update_post";

// Users Module
export const GET_USERS = "GET_USERS";
export const GET_ACTIVE_USERS = "GET_ACTIVE_USERS";
export const ADD_USER = "ADD_USER";
export const ADD_USER_TRACKABLE = "ADD_USER_TRACKABLE";
export const ADD_USER_APPROVABLE = "ADD_USER_APPROVABLE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_TRACKABLE = "DELETE_USER_TRACKABLE";
export const DELETE_USER_APPROVABLE = "DELETE_USER_APPROVABLE";
export const DELETE_BULK_USERS = "DELETE_BULK_USERS";
export const GET_TRACKABLE_USERS = "GET_TRACKABLE_USERS";
export const GET_APPROVABLE_USERS = "GET_APPROVABLE_USERS";
export const GET_LEADERS = "GET_LEADERS";
export const GET_AREAS = "GET_AREAS";
export const GET_HOBBIES = "GET_HOBBIES";
export const GET_JOB_TITLES = "GET_JOB_TITLES";
export const GET_PROFESSIONAL_SKILLS = "GET_PROFESSIONAL_SKILLS";
export const USER_ALERT = "USER_ALERT";

// User Activity
export const ADD_USER_ACTIVITY = "ADD_USER_ACTIVITY";
export const GET_USER_ACTIVITIES = "GET_USER_ACTIVITIES";
export const GET_USER_ACTIVITY = "GET_USER_ACTIVITY";
export const DELETE_USER_ACTIVITY = "DELETE_USER_ACTIVITY";
export const SET_USER_ACTIVITY_DETAILS = "SET_USER_ACTIVITY_DETAILS";
export const USER_ACTIVITY_ALERT = "USER_ACTIVITY_ALERT";
export const GET_CURRENT_USER_ACTIVITIES = "GET_CURRENT_USER_ACTIVITIES";
export const UPDATE_USER_ACTIVITY = "UPDATE_USER_ACTIVITY";

// TimeTracking
export const GET_TIME_TRACKING_ITEMS = "GET_TIME_TRACKING_ITEMS";
export const GET_TIME_TRACKING_ITEMS_BY_ID = "GET_TIME_TRACKING_ITEMS_BY_ID";
export const GET_TIME_TRACKING_ITEMS_BY_LEADER =
  "GET_TIME_TRACKING_ITEMS_BY_LEADER";
export const GET_TIME_TRACKING_ITEMS_HISTORY =
  "GET_TIME_TRACKING_ITEMS_HISTORY";
export const GET_ADMIN_TIME_TRACKING_ITEMS = "GET_ADMIN_TIME_TRACKING_ITEMS";
export const ADD_TIME_TRACKING_ITEM = "ADD_TIME_TRACKING_ITEM";
export const GET_APPROVABLES_TIME_TRACKING_ITEMS =
  "GET_APPROVABLES_TIME_TRACKING_ITEMS";

// Licences
export const ADD_LICENCE = "ADD_LICENCE";
export const DELETE_BULK_LICENCES = "DELETE_BULK_LICENCES";
export const DELETE_LICENCE = "DELETE_LICENCE";
export const EDIT_LICENCE = "EDIT_LICENCE";
export const GET_LICENCES = "GET_LICENCES";
export const GET_LICENCE = "GET_LICENCE";
export const SET_LICENCE_DETAILS = "SET_LICENCE_DETAILS";

// Trainings
export const ADD_TRAINING = "ADD_TRAINING";
export const DELETE_BULK_TRAININGS = "DELETE_BULK_TRAININGS";
export const DELETE_TRAINING = "DELETE_TRAINING";
export const EDIT_TRAINING = "EDIT_TRAINING";
export const GET_TRAININGS = "GET_TRAININGS";
export const GET_TRAINING = "GET_TRAINING";
export const SET_TRAINING_DETAILS = "SET_TRAINING_DETAILS";

// Projects
export const ADD_PROJECT = "ADD_PROJECT";
export const DELETE_BULK_PROJECTS = "DELETE_BULK_PROJECTS";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const GET_GESTORES = "GET_GESTORES";
export const SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS";

// Roles
export const ADD_ROLE = "ADD_ROLE";
export const DELETE_BULK_ROLES = "DELETE_BULK_ROLES";
export const DELETE_ROLE = "DELETE_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const GET_ROLES = "GET_ROLES";
export const GET_ROLE = "GET_ROLE";
export const SET_ROLE_DETAILS = "SET_ROLE_DETAILS";
export const ADD_ROLE_TO_USER = "ADD_ROLE_TO_USER";
export const ADD_PRIVILEGE_TO_ROLE = "ADD_PRIVILEGE_TO_ROLE";
export const DELETE_PRIVILEGE_FROM_ROLE = "DELETE_PRIVILEGE_FROM_ROLE";
export const ROLE_ALERT = "ROLE_ALERT";

// Privileges
export const GET_PRIVILEGES = "GET_PRIVILEGES";
export const GET_PRIVILEGE = "GET_PRIVILEGE";
export const ADD_PRIVILEGE = "ADD_PRIVILEGE";
export const DELETE_PRIVILEGE = "DELETE_PRIVILEGE";

// Storage
export const USER_IMAGE_UPLOADED = "USER_IMAGE_UPLOADED";
export const USER_IMAGE_UPLOADED_FAILED = "USER_IMAGE_UPLOADED_FAILED";
export const NEWS_IMAGE_UPLOADED = "NEWS_IMAGE_UPLOADED";
export const NEWS_IMAGE_UPLOADED_FAILED = "NEWS_IMAGE_UPLOADED_FAILED";
export const NEWS_VIDEO_UPLOADED = "NEWS_VIDEO_UPLOADED";
export const NEWS_VIDEO_UPLOADED_FAILED = "NEWS_VIDEO_UPLOADED_FAILED";
export const BENEFITS_IMAGE_UPLOADED = "BENEFITS_IMAGE_UPLOADED";
export const BENEFITS_IMAGE_UPLOADED_FAILED = "BENEFITS_IMAGE_UPLOADED_FAILED";
export const BENEFITS_VIDEO_UPLOADED = "BENEFITS_VIDEO_UPLOADED";
export const BENEFITS_VIDEO_UPLOADED_FAILED = "BENEFITS_VIDEO_UPLOADED_FAILED";

// Holidays
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const EDIT_HOLIDAY = "EDIT_HOLIDAY";
export const GET_HOLIDAY = "GET_HOLIDAY";
