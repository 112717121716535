import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER
} from "../../@jumbo/constants/ActionTypes";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";

export const setAuthUser = user => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/auth/` + user.email)
      .then(data => {
        if (data.status === 200) {
          //console.log(data.data);
          dispatch({ type: UPDATE_AUTH_USER, payload: data.data });
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status
    });
  };
};
