import React, { useEffect, useState } from "react";
import { Box, Button, Divider, TextField, Typography } from "@material-ui/core";
import Restricted from "../../../../services/permissionProvider/Restricted";
import { HORAS_CARGAR } from "../../../../services/permissionProvider/PermissionsCatalog";
import { Check, Close, Delete, Edit, Markunread } from "@material-ui/icons";
import Forbidden from "@jumbo/components/Forbidden";
import { useDispatch, useSelector } from "react-redux";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { Autocomplete } from "@material-ui/lab";
import {
  addTimeTrackingItem,
  confirmTimeTrackingItems,
  getTimeTrackingItems,
  removeTimeTrackingItem,
  updateTimeTrackingItem
} from "redux/actions/TimeTrackingItems";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CmtCard from "@coremat/CmtCard";
import { getUserDetail } from "redux/actions/Users";

export default function CustomTimeTrackingLoader({ updateTable }) {
  const { authUser } = useSelector(({ auth }) => auth);
  const { userDetail } = useSelector(({ usersReducer }) => usersReducer);
  const { timeTrackingItems } = useSelector(
    ({ timeTrackingItemsReducer }) => timeTrackingItemsReducer
  );
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const colorMode = sessionStorage.getItem("colorMode");
  const USER_ACTION_SAVE = "USER_ACTION_SAVE";
  const USER_ACTION_UPDATE = "USER_ACTION_UPDATE";

  const [borrarItem, setBorrarItem] = useState("");
  const [editarItem, setEditarItem] = useState("");
  const [date, setDate] = useState("");
  const [trackable, setTrackable] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [disableRows, setDisableRows] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [addNewItem, setAddNewItem] = useState(false);
  const [isValidItem, setIsValidItem] = useState(false);
  const [dayCount, setDayCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [orderedItems, setOrderedItems] = useState("");

  useEffect(() => {
    dispatch(getTimeTrackingItems(() => {}));
  }, [dispatch, refresh, updateTable]);

  useEffect(() => {
    dispatch(getUserDetail(authUser.id, () => {}));
  }, [dispatch, authUser]);

  useEffect(() => {
    if (timeTrackingItems.length > 0) {
      const orderedItemsByDate = stableSort(
        timeTrackingItems,
        getComparator("asc", "item_date")
      );
      setOrderedItems(orderedItemsByDate);
    } else {
      setOrderedItems([]);
    }
  }, [timeTrackingItems, updateTable, refresh]);

  useEffect(() => {
    const today = moment(new Date()).format("dddd");
    /* switch (today) { VALIDACION OK 17 OCT
      case "lunes":
        setDayCount(1);
        break;
      case "martes":
        setDayCount(2);
        break;
      case "miércoles":
        setDayCount(3);
        break;
      case "jueves":
        setDayCount(4);
        break;
      case "viernes":
        setDayCount(5);
        break;
      default:
        break;
    } */

    //VALIDACION PROVISORIA SEMANA ACTUAL + SEMANA ANTERIOR
    switch (today) {
      case "lunes":
        setDayCount(8);
        break;
      case "martes":
        setDayCount(9);
        break;
      case "miércoles":
        setDayCount(10);
        break;
      case "jueves":
        setDayCount(11);
        break;
      case "viernes":
        setDayCount(12);
        break;
      default:
        break;
    }
  }, []);

  const trackables = userDetail
    ? userDetail.user.trackables
        .filter(
          trackable =>
            trackable.status === "ACTIVE" &&
            trackable.name !== "Feriado" &&
            trackable.name !== "Asueto - Día no laborable"
        )
        .map(i => {
          return { id: i.id, name: i.name };
        })
    : [];

  const orderedTrackables = stableSort(
    trackables,
    getComparator("asc", "name")
  );

  const rowBackgroundColors = {
    REJECTED: "rgb(251,160,148)"
  };

  const rowBackgroundColorsDarkMode = {
    REJECTED: "#eb4034"
  };

  const getFormatedDate = date => {
    const splitDate = date.slice(0, 10);
    const formatedDate = moment(splitDate).format("DD/MM/YYYY");
    return formatedDate;
  };

  const mostrarMotivo = motivo => {
    return MySwal.fire("Motivo de rechazo", motivo);
  };

  // Funciones de botones laterales

  const deleteItem = id => {
    setBorrarItem(id);
    setDisableRows(true);
    setDisableButton(true);
  };

  const editItem = row => {
    setEditarItem(row.id);
    setDisableRows(true);
    setDisableButton(true);
    setHours(parseInt(row.hours));
    setMinutes(parseInt(row.minutes));
    setTrackable({ id: row.trackable.id, name: row.trackable.name });
    setDate(row.item_date.slice(0, 10));
  };

  const cancelEditItem = () => {
    setEditarItem("");
    setDisableRows(false);
    setDisableButton(false);
    setHours("");
    setMinutes("");
    setTrackable("");
    setDate("");
  };

  const cancelDeleteItem = () => {
    setBorrarItem("");
    setDisableRows(false);
    setDisableButton(false);
  };

  const addItem = () => {
    setAddNewItem(true);
    setDisableRows(true);
    setDisableButton(true);
  };

  const cancelAddItem = () => {
    setAddNewItem(false);
    setDisableRows(false);
    setDisableButton(false);
    setHours("");
    setMinutes("");
    setTrackable("");
    setDate("");
  };

  const refreshTable = () => {
    setEditarItem("");
    setBorrarItem("");
    setAddNewItem(false);
    setDisableRows(false);
    setDisableButton(false);
    setHours("");
    setMinutes("");
    setTrackable("");
    setDate("");
    setRefresh(!refresh);
  };

  // Validaciones

  const validDate = (theDate, numberOfDays) => {
    const currentTime = new Date().getTime();
    const formatedDate = new Date(theDate).getTime();
    if ((currentTime - formatedDate) / (1000 * 3600 * 24) > numberOfDays) {
      return false;
    } else if ((formatedDate - currentTime) / (1000 * 3600 * 24) > 0) {
      return false;
    } else if (theDate === "") {
      return false;
    } else {
      return true;
    }
  };

  const validHours = () => {
    const horas = parseInt(hours);
    const minutos = parseInt(minutes);
    if (hours === null || hours === undefined || hours === "") {
      return "Obligatorio";
    } else if (horas > 16 || horas < 0) {
      return "Entre 0 y 16";
    } else if (horas === 16 && minutos > 0) {
      return "Hasta 16 horas";
    } else if (horas === 0 && minutos === 0) {
      return "Es obligatorio cargar horas y/o minutos";
    } else {
      return "";
    }
  };

  const validMinutes = () => {
    const horas = parseInt(hours);
    const minutos = parseInt(minutes);
    if (minutes === null || minutes === undefined || minutes === "") {
      return "Obligatorio";
    } else if (minutos > 59 || minutos < 0) {
      return "Entre 0 y 59";
    } else if (horas === 16 && minutos > 0) {
      return "Hasta 16 horas";
    } else if (horas === 0 && minutos === 0) {
      return "Es obligatorio cargar horas y/o minutos";
    } else {
      return "";
    }
  };

  const validTimeTrackingItem = (timeTrackingItem, userAction) => {
    let itemDate;
    if (userAction === USER_ACTION_SAVE) {
      itemDate = timeTrackingItem.item_date.getTime();
    }
    if (userAction === USER_ACTION_UPDATE) {
      itemDate = timeTrackingItem.item_date;
    }

    if (validDate(itemDate, dayCount) === false) {
      MySwal.fire({
        title: <strong>Fecha Invalida</strong>,
        html: <i>La fecha debe corresponder a esta semana</i>,
        icon: "error"
      });

      return false;
    }
    return true;
  };

  useEffect(() => {
    const validHoursAndMinutes = () => {
      const horas = parseInt(hours);
      const minutos = parseInt(minutes);
      if (hours === null || hours === undefined || hours === "") {
        return false;
      } else if (minutes === null || minutes === undefined || minutes === "") {
        return false;
      } else if (minutos > 59 || minutos < 0) {
        return false;
      } else if (horas === 16 && minutos !== 0) {
        return false;
      } else if (horas === 0 && minutos === 0) {
        return false;
      } else if (horas > 16 || horas < 0) {
        return false;
      } else if (horas === 16 && minutos > 0) {
        return false;
      } else if (horas === 0 && minutos === 0) {
        return false;
      } else {
        return true;
      }
    };
    if (
      validDate(date, dayCount) === true &&
      validHoursAndMinutes() === true &&
      trackable !== ""
    ) {
      setIsValidItem(true);
    } else {
      setIsValidItem(false);
    }
  }, [date, hours, minutes, trackable, dayCount]);

  // Funciones fetch

  const onDeleteItem = id => {
    dispatch(removeTimeTrackingItem(id, "", "", () => {}));
    refreshTable();
  };

  const onSaveNewItem = () => {
    const fechaFormato = new Date(date);
    const timeTrackingItem = {
      item_date: fechaFormato,
      trackable: { id: trackable.id },
      hours: hours,
      minutes: minutes
    };
    timeTrackingItem["user"] = { id: authUser.id };
    if (validTimeTrackingItem(timeTrackingItem, USER_ACTION_SAVE)) {
      timeTrackingItem.item_date = moment(date).toISOString();
      dispatch(addTimeTrackingItem(timeTrackingItem, () => {}, "", ""));
      refreshTable();
    }
  };

  const onUpdateItem = rowData => {
    const fechaFormato = new Date(date);
    const timeTrackingItem = {
      id: rowData.id,
      item_date: fechaFormato,
      hours: hours,
      minutes: minutes,
      status: rowData.status,
      reject_reason: rowData.reject_reason,
      trackable: trackable,
      user: { id: authUser.id }
    };

    if (validTimeTrackingItem(timeTrackingItem, USER_ACTION_UPDATE)) {
      if (timeTrackingItem.status === "REJECTED") {
        timeTrackingItem.status = "CREATED";
      }
      timeTrackingItem.item_date = moment(date).toISOString();
      dispatch(updateTimeTrackingItem(timeTrackingItem, () => {}));
      refreshTable();
    }
  };

  // Confirmar items para aprobación

  const validateBeforeConfirm = timetrackingItems => {
    if (timetrackingItems.find(a => a.status === "REJECTED")) {
      return false;
    }
    return true;
  };

  const confirmItems = () => {
    if (validateBeforeConfirm(timeTrackingItems)) {
      const timeTrackingItemIds = { ids: timeTrackingItems.map(i => i.id) };
      dispatch(confirmTimeTrackingItems(timeTrackingItemIds, () => {}));
      MySwal.fire({
        title: <strong>Confirmando Horas</strong>,
        html: <p>Las horas serán aprobadas por un gestor.</p>,
        icon: "info"
      });
    } else {
      MySwal.fire({
        title: <strong>Confirmando Horas</strong>,
        html: <p>Las horas rechazadas deben ser modificadas.</p>,
        icon: "error"
      });
    }
  };

  const getHelperTextDate = () => {
    const today = moment(new Date()).format("DD/MM/YYYY");
    const selectedDate = moment(date).format("DD/MM/YYYY");
    const lastValidDate = moment(date)
      .subtract(dayCount, "days")
      .format("DD/MM/YYYY");
    if (date === "") {
      return "Obligatorio";
    } else if (selectedDate > today) {
      return "Fecha inválida";
    } else if (selectedDate < lastValidDate) {
      return "Fecha inválida";
    } else {
      return "";
    }
  };

  const Row = ({ row }) => {
    if (borrarItem === row.id) {
      return (
        <div key={row.id}>
          <Divider style={{ margin: "0px" }} />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "20px",
              width: "100%"
            }}
          >
            <Typography style={{ width: "90%", marginLeft: "50px" }}>
              ¿Querés borrar esta fila?
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "10%",
                paddingRight: "25px"
              }}
            >
              <Check
                style={{ cursor: "pointer" }}
                onClick={() => onDeleteItem(row.id)}
              />
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => cancelDeleteItem()}
              />
            </Box>
          </Box>
        </div>
      );
    } else if (editarItem === row.id) {
      return (
        <div key={row.id}>
          <Divider style={{ margin: "0px" }} />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
              width: "100%"
            }}
          >
            <div style={{ width: "0%", justifyContent: "left" }} />
            <TextField
              variant="outlined"
              style={{ width: "20%", padding: "0px" }}
              type="date"
              defaultValue={date}
              onChange={e => setDate(e.target.value)}
              helperText={
                validDate(date, dayCount) === true ? (
                  ""
                ) : (
                  /* <span style={{ color: "#ff2800" }}>
                          Debe ser de la semana vigente
                        </span> */
                  <span style={{ color: "#ff2800" }}>
                    {getHelperTextDate()}
                  </span>
                )
              }
            />
            <Autocomplete
              style={{ width: "25%", padding: "0px" }}
              id="asignación"
              disableClearable
              defaultValue={trackable}
              options={orderedTrackables}
              getOptionLabel={item => (item.name ? item.name : "")}
              getOptionSelected={(option, value) => option === value}
              onChange={(event, getOptionSelected) =>
                setTrackable(getOptionSelected)
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  helperText={
                    <span style={{ color: "#ff2800" }}>
                      {trackable === "" && "Obligatorio"}
                    </span>
                  }
                />
              )}
              noOptionsText="No hay opciones disponibles"
            ></Autocomplete>
            <TextField
              variant="outlined"
              style={{ width: "10%", padding: "0px" }}
              type="number"
              defaultValue={hours}
              onChange={e => setHours(e.target.value)}
              helperText={
                <span style={{ color: "#ff2800" }}>{validHours()}</span>
              }
            />
            <TextField
              variant="outlined"
              style={{ width: "10%", padding: "0px" }}
              type="number"
              defaultValue={minutes}
              onChange={e => setMinutes(e.target.value)}
              helperText={
                <span style={{ color: "#ff2800" }}>{validHours()}</span>
              }
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "10%",
                paddingRight: "10px"
              }}
            >
              <Check
                style={{
                  cursor: "pointer",
                  opacity: isValidItem === true ? "100%" : "20%"
                }}
                onClick={() => isValidItem === true && onUpdateItem(row)}
              />
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => cancelEditItem()}
              />
            </Box>
          </Box>
        </div>
      );
    } else if (row.status === "REJECTED") {
      return (
        <div key={row.id}>
          <Divider style={{ margin: "0px" }} />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "20px",
              backgroundColor:
                colorMode !== "dark" && rowBackgroundColors[row.status],
              border:
                colorMode === "dark" &&
                `6px solid ${rowBackgroundColorsDarkMode[row.status]}`
            }}
          >
            <div
              style={{ width: "5%", justifyContent: "left" }}
              title="Ver motivo"
            >
              <Markunread
                style={{ cursor: "pointer" }}
                onClick={() => mostrarMotivo(row.reject_reason)}
              />
            </div>
            <Typography style={{ width: "25%" }}>
              {getFormatedDate(row.item_date)}
            </Typography>
            <Typography style={{ width: "30%" }}>
              {row.trackable.name}
            </Typography>
            <Typography style={{ width: "15%", paddingLeft: "20px" }}>
              {row.hours}
            </Typography>
            <Typography style={{ width: "15%", paddingLeft: "20px" }}>
              {row.minutes}
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "10%",
                paddingRight: "20px"
              }}
            >
              <Edit
                style={{
                  cursor: "pointer",
                  opacity: disableRows === true ? "20%" : "100%"
                }}
                onClick={() => disableRows === false && editItem(row)}
              />
              <Delete
                style={{
                  cursor: "pointer",
                  opacity: disableRows === true ? "20%" : "100%"
                }}
                onClick={() => disableRows === false && deleteItem(row.id)}
              />
            </Box>
          </Box>
        </div>
      );
    } else {
      return (
        <div key={row.id}>
          <Divider style={{ margin: "0px" }} />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "20px",
              backgroundColor:
                colorMode !== "dark" && rowBackgroundColors[row.status],
              border:
                colorMode === "dark" &&
                `6px solid ${rowBackgroundColorsDarkMode[row.status]}`
            }}
          >
            <div style={{ width: "5%", justifyContent: "left" }} />
            <Typography style={{ width: "25%" }}>
              {getFormatedDate(row.item_date)}
            </Typography>
            <Typography style={{ width: "30%" }}>
              {row.trackable.name}
            </Typography>
            <Typography style={{ width: "15%", paddingLeft: "20px" }}>
              {row.hours}
            </Typography>
            <Typography style={{ width: "15%", paddingLeft: "20px" }}>
              {row.minutes}
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "10%",
                paddingRight: "20px"
              }}
            >
              <Edit
                style={{
                  cursor: "pointer",
                  opacity: disableRows === true ? "20%" : "100%"
                }}
                onClick={() => disableRows === false && editItem(row)}
              />
              <Delete
                style={{
                  cursor: "pointer",
                  opacity: disableRows === true ? "20%" : "100%"
                }}
                onClick={() => disableRows === false && deleteItem(row.id)}
              />
            </Box>
          </Box>
        </div>
      );
    }
  };

  return (
    <Restricted to={HORAS_CARGAR} fallback={Forbidden}>
      <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CmtCard>
          <Box
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "20px 10px 20px 10px"
              }}
            >
              <Typography
                style={{
                  alignSelf: "center",
                  fontWeight: 600,
                  width: "25%",
                  marginLeft: "10px"
                }}
              >
                Carga de horas
              </Typography>
              <Button
                style={{
                  width: "170px",
                  marginRight: "10px",
                  padding: "5px 3px 5px 3px",
                  borderRadius: "5px",
                  opacity: disableButton === true ? "20%" : "100%",
                  backgroundColor: "#303f9f",
                  color: "#ffffff"
                }}
                onClick={() => disableButton === false && addItem()}
              >
                Agregar entrada
              </Button>
            </Box>
            <Divider style={{ margin: "0px" }} />
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "20px"
              }}
            >
              <Typography style={{ width: "5%" }} />
              <Typography style={{ fontWeight: 600, width: "25%" }}>
                Fecha
              </Typography>
              <Typography style={{ fontWeight: 600, width: "30%" }}>
                Asignación
              </Typography>
              <Typography style={{ fontWeight: 600, width: "15%" }}>
                Horas
              </Typography>
              <Typography style={{ fontWeight: 600, width: "15%" }}>
                Minutos
              </Typography>
              <Typography style={{ fontWeight: 600, width: "10%" }}>
                Acciones
              </Typography>
            </Box>
            {orderedItems.length > 0 ? (
              orderedItems.map(item => {
                return (
                  <div key={item.id}>
                    <Row row={item} />
                  </div>
                );
              })
            ) : (
              <div>
                {addNewItem !== true && (
                  <>
                    <Divider style={{ margin: "0px" }} />
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        padding: "20px"
                      }}
                    >
                      <Typography
                        style={{
                          width: "15%",
                          fontSize: "14px",
                          paddingLeft: "20px"
                        }}
                      >
                        No hay registros
                      </Typography>
                    </Box>
                  </>
                )}
              </div>
            )}
            {addNewItem === true && (
              <div>
                <Divider style={{ margin: "0px" }} />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "20px",
                    width: "100%"
                  }}
                >
                  <div style={{ width: "0%", justifyContent: "left" }} />
                  <TextField
                    type="date"
                    variant="outlined"
                    style={{ width: "20%", padding: "0px" }}
                    onChange={e => setDate(e.target.value)}
                    helperText={
                      validDate(date, dayCount) === true ? (
                        ""
                      ) : (
                        /* <span style={{ color: "#ff2800" }}>
                          Debe ser de la semana vigente
                        </span> */
                        <span style={{ color: "#ff2800" }}>
                          {getHelperTextDate()}
                        </span>
                      )
                    }
                  />
                  <Autocomplete
                    style={{ width: "25%", padding: "0px" }}
                    id="asignación"
                    disableClearable
                    options={orderedTrackables}
                    getOptionLabel={item => (item.name ? item.name : "")}
                    getOptionSelected={(option, value) => option === value}
                    onChange={(event, getOptionSelected) =>
                      setTrackable(getOptionSelected)
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label=""
                        variant="outlined"
                        helperText={
                          <span style={{ color: "#ff2800" }}>
                            {trackable === "" && "Obligatorio"}
                          </span>
                        }
                      />
                    )}
                    noOptionsText="No hay opciones disponibles"
                  ></Autocomplete>
                  <TextField
                    variant="outlined"
                    style={{ width: "10%", padding: "0px" }}
                    type="number"
                    onChange={e => setHours(e.target.value)}
                    helperText={
                      <span style={{ color: "#ff2800" }}>{validHours()}</span>
                    }
                  />
                  <TextField
                    variant="outlined"
                    style={{ width: "10%", padding: "0px" }}
                    type="number"
                    onChange={e => setMinutes(e.target.value)}
                    helperText={
                      <span style={{ color: "#ff2800" }}>{validMinutes()}</span>
                    }
                  />
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "10%",
                      paddingRight: "10px"
                    }}
                  >
                    <Check
                      style={{
                        cursor: "pointer",
                        opacity: isValidItem === true ? "100%" : "20%"
                      }}
                      onClick={() => isValidItem === true && onSaveNewItem()}
                    />
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={() => cancelAddItem()}
                    />
                  </Box>
                </Box>
              </div>
            )}
          </Box>
        </CmtCard>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="20px 0px 0px 0px"
        >
          <Button
            color="primary"
            variant="contained"
            onClick={confirmItems}
            id="btn-confirmarEntradas"
            disabled={
              editarItem === "" &&
              borrarItem === "" &&
              addNewItem === false &&
              timeTrackingItems.length > 0
                ? false
                : true
            }
          >
            Confirmar Entradas
          </Button>
        </Box>
      </Box>
    </Restricted>
  );
}
