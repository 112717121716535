import React from "react";
import Popper from "@material-ui/core/Popper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputBase from "@material-ui/core/InputBase";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { addUserApprovable } from "redux/actions/Users";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
  popper: {
    boxShadow:
      "0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: theme.shape.borderRadius,
    width: 300,
    zIndex: 9,
    fontSize: 12,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default
  },
  searchIcon: {
    position: "absolute",
    top: "50%",
    left: 10,
    zIndex: 1,
    transform: "translateY(-50%)",
    color: theme.palette.text.disabled
  },
  inputBase: {
    "& input": {
      padding: "12px 10px 10px 36px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      borderBottom: `1px solid ${theme.palette.borderColor.main}`,
      fontSize: 12,
      color: theme.palette.text.disabled,
      "&:focus": {
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: "4px 10px",
    backgroundColor: theme.palette.background.default,
    '&[aria-selected="true"]': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main
    },
    '&[data-focus="true"]': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main
    }
  },
  popperDisablePortal: {
    position: "relative"
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  text: {
    flexGrow: 1
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18
  }
}));

const AddApproverPopup = ({
  options,
  anchorEl,
  setAnchorEl,
  team,
  setTeam,
  trackableId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }

    if (anchorEl) {
      anchorEl.focus();
    }

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "github-label" : undefined;

  const onAddUser = (userId, trackableId, newValue) => {
    dispatch(addUserApprovable(userId, trackableId, () => {}));
  };

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="right"
      className={classes.popper}
    >
      <Autocomplete
        open
        onClose={handleClose}
        multiple
        classes={{
          paper: classes.paper,
          option: classes.option,
          popperDisablePortal: classes.popperDisablePortal
        }}
        value={[]}
        onChange={(event, newValue, reason) => {
          if (newValue.length) {
            onAddUser(newValue[0].id, trackableId, newValue);
          }
        }}
        disableCloseOnSelect
        disablePortal
        renderTags={() => null}
        noOptionsText="No Members"
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Typography className={classes.text}>
              {option.first_name} {option.last_name}
            </Typography>
          </React.Fragment>
        )}
        options={options}
        getOptionLabel={option => option.first_name}
        renderInput={params => (
          <Box position="relative">
            <SearchIcon fontSize="small" className={classes.searchIcon} />
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              fullWidth
              placeholder="Buscar..."
              className={classes.inputBase}
            />
          </Box>
        )}
      />
    </Popper>
  );
};

export default AddApproverPopup;
