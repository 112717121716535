import React from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import GridContainer from "@jumbo/components/GridContainer";
import CmtAvatar from "@coremat/CmtAvatar";
import Button from "@material-ui/core/Button";
import AppAutocomplete from "@jumbo/components/Common/formElements/AppAutocomplete";

const ProfesionalDetails = ({
  handleChange,
  handleInterests,
  values,
  classes,
  prevStep,
  uploadedPic,
  handleHobbies,
  validateData,
  error,
  success
}) => {
  const { professionalSkills, hobbies } = useSelector(
    ({ usersReducer }) => usersReducer
  );

  return (
    <Box>
      <Typography className={classes.instructions}>
        Datos Profesionales
      </Typography>
      <GridContainer style={{ marginBottom: 10 }}>
        <Grid item xs={4} md={4} xl={4}>
          <Box mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className="pointer">
            <CmtAvatar
              size={70}
              src={uploadedPic || values.profilePic}
              id="img-editarImagenPerfil"
            />
          </Box>
        </Grid>
        <Grid item xs={8} md={8} xl={8}>
          <TextField
            id="txt-estudios"
            placeholder="Estudios"
            label="Estudios"
            onChange={handleChange("studies")}
            defaultValue={values.studies}
          />
          <AppAutocomplete
            multiple
            id="professionals-skills-standard"
            options={professionalSkills.filter(
              h => !values.interests.map(i => i.id).includes(h.id)
            )}
            onChange={(event, newValue) => {
              handleInterests(newValue);
            }}
            getOptionLabel={option => option.name}
            // defaultValue={values.interests}
            value={values.interests}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Intereses Profesionales"
                placeholder="Intereses"
              />
            )}
          />
          <AppAutocomplete
            multiple
            id="hobbies-standard"
            options={hobbies.filter(
              h => !values.hobbies.map(i => i.id).includes(h.id)
            )}
            onChange={(event, newValue) => {
              handleHobbies(newValue);
            }}
            getOptionLabel={option => option.name}
            //defaultValue={values.hobbies}
            value={values.hobbies}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Hobbies"
                placeholder="Hobbies"
              />
            )}
          />
        </Grid>
      </GridContainer>
      <Box>
        <Button
          id="btn-volver"
          variant="contained"
          color="primary"
          onClick={prevStep}
          className={classes.button}
        >
          Volver
        </Button>
        <Button
          id="btn-finalizar"
          variant="contained"
          color="primary"
          onClick={validateData}
          className={classes.button}
        >
          Finalizar
        </Button>
        <Typography color="secondary">{error}</Typography>
        <Typography style={{ color: "03AC13#" }}>{success}</Typography>
      </Box>
    </Box>
  );
};

export default ProfesionalDetails;
