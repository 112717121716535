import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import * as React from "react";
import CustomCell from "./CustomCell";
import moment from "moment";
import { Box, Typography } from "@material-ui/core";
import { ErrorOutlineOutlined, Notifications } from "@material-ui/icons";

export default class CustomGroupRow extends React.Component {
  rotateIconStyle = isOpen => ({
    transform: isOpen ? "rotate(90deg)" : "none"
  });

  render() {
    let colSpan = this.props.columns.filter(columnDef => !columnDef.hidden)
      .length;
    this.props.options.selection && colSpan++;
    this.props.detailPanel && colSpan++;
    this.props.actions && this.props.actions.length > 0 && colSpan++;
    const column = this.props.groups[this.props.level];

    let detail;
    if (this.props.groupData.isExpanded) {
      if (this.props.groups.length > this.props.level + 1) {
        // Is there another group
        detail = this.props.groupData.groups.map((groupData, index) => (
          <this.props.components.GroupRow
            actions={this.props.actions}
            key={groupData.value || "" + index}
            columns={this.props.columns}
            components={this.props.components}
            detailPanel={this.props.detailPanel}
            getFieldValue={this.props.getFieldValue}
            groupData={groupData}
            groups={this.props.groups}
            icons={this.props.icons}
            level={this.props.level + 1}
            path={[...this.props.path, index]}
            onGroupExpandChanged={this.props.onGroupExpandChanged}
            onRowSelected={this.props.onRowSelected}
            onRowClick={this.props.onRowClick}
            onToggleDetailPanel={this.props.onToggleDetailPanel}
            onTreeExpandChanged={this.props.onTreeExpandChanged}
            onEditingCanceled={this.props.onEditingCanceled}
            onEditingApproved={this.props.onEditingApproved}
            options={this.props.options}
            hasAnyEditingRow={this.props.hasAnyEditingRow}
            isTreeData={this.props.isTreeData}
          />
        ));
      } else {
        detail = this.props.groupData.data.map((rowData, index) => {
          if (rowData.tableData.editing) {
            return (
              <this.props.components.EditRow
                columns={this.props.columns}
                components={this.props.components}
                data={rowData}
                icons={this.props.icons}
                path={[...this.props.path, index]}
                localization={this.props.localization}
                key={index}
                mode={rowData.tableData.editing}
                options={this.props.options}
                isTreeData={this.props.isTreeData}
                detailPanel={this.props.detailPanel}
                onEditingCanceled={this.props.onEditingCanceled}
                onEditingApproved={this.props.onEditingApproved}
                getFieldValue={this.props.getFieldValue}
              />
            );
          } else {
            return (
              <this.props.components.Row
                actions={this.props.actions}
                key={index}
                columns={this.props.columns}
                components={this.props.components}
                data={rowData}
                detailPanel={this.props.detailPanel}
                getFieldValue={this.props.getFieldValue}
                icons={this.props.icons}
                path={[...this.props.path, index]}
                onRowSelected={this.props.onRowSelected}
                onRowClick={this.props.onRowClick}
                onToggleDetailPanel={this.props.onToggleDetailPanel}
                options={this.props.options}
                isTreeData={this.props.isTreeData}
                onTreeExpandChanged={this.props.onTreeExpandChanged}
                onEditingCanceled={this.props.onEditingCanceled}
                onEditingApproved={this.props.onEditingApproved}
                hasAnyEditingRow={this.props.hasAnyEditingRow}
              />
            );
          }
        });
      }
    }

    const freeCells = [];
    for (let i = 0; i < this.props.level; i++) {
      freeCells.push(<TableCell padding="checkbox" key={i} />);
    }

    let value = this.props.groupData.value;
    if (column.lookup) {
      value = column.lookup[value];
    }

    let title = column.title;
    if (typeof title !== "string") {
      title = React.cloneElement(title);
    }

    const getPendingToAprove = (items) => {
      const itemsPendingToAprove = items.filter(
        (i) => i.status === "CONFIRMED"
      );
      return itemsPendingToAprove.length;
    };

    const getHours = items => {
      const horasItems = items.map(h => h.hours);
      let totalHoras = horasItems.reduce((a, b) => a + b, 0);

      const minutosItems = items.map(m => m.minutes);
      const totalMinutos = minutosItems.reduce((a, b) => a + b, 0);

      let total = "";

      if (totalMinutos > 59) {
        const minutosAHoras = Math.floor(totalMinutos / 60);
        const minutos = totalMinutos % 60;
        totalHoras = totalHoras + minutosAHoras;
        if (minutos > 9) {
          total = totalHoras + ":" + minutos;
        } else {
          total = totalHoras + ":0" + minutos;
        }
      } else {
        if (totalMinutos > 9) {
          total = totalHoras + ":" + totalMinutos;
        } else {
          total = totalHoras + ":0" + totalMinutos;
        }
      }
      return total;
    };

    const getMaxHours = items => {
      const horasItems = items.map(h => h.hours);
      let totalHoras = horasItems.reduce((a, b) => a + b, 0);

      const minutosItems = items.map(m => m.minutes);
      const totalMinutos = minutosItems.reduce((a, b) => a + b, 0);

      if (totalMinutos > 59) {
        const minutosAHoras = Math.floor(totalMinutos / 60);
        totalHoras = totalHoras + minutosAHoras;
      } else {
        totalHoras = totalHoras + totalMinutos;
      }
      return totalHoras;
    };

    const colorMode = sessionStorage.getItem("colorMode");

    return (
      <>
        <TableRow
          style={{
            backgroundColor:
              colorMode === "dark" ? "rgb(3, 14, 24)" : "rgb(232, 244, 253)"
          }}
        >
          {freeCells}
          <CustomCell
            colSpan={colSpan}
            padding="none"
            columnDef={column}
            value={value}
            icons={this.props.icons}
            groupData={this.props.groupData}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "542px",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography
                  style={{
                    width: "542px",
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    marginLeft: "18px"
                  }}
                >
                  Fecha:{" "}
                  {moment(this.props.groupData.value).format("DD/MM/YYYY")}
                </Typography>
              </div>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                {getPendingToAprove(this.props.groupData.data) > 0 ? (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#3f51b5",
                      width: "20px",
                    }}
                  >
                    <Notifications />
                  </Box>
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#f04c16",
                      width: "20px",
                    }}
                  ></Box>
                )}
                {getMaxHours(this.props.groupData.data) > 8 ? (
                  <div style={{ display: "flex", paddingLeft: "15px" }}>
                    <Typography
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        color:
                          getMaxHours(this.props.groupData.data) > 8 &&
                          "#f04c16"
                      }}
                    >
                      Horas totales: {getHours(this.props.groupData.data)}
                    </Typography>
                    <ErrorOutlineOutlined
                      style={{
                        color: "#f04c16",
                        fontSize: "16px",
                        alignSelf: "center",
                        marginLeft: "10px"
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <Typography
                      style={{
                        paddingLeft: "10px",
                        marginLeft: "5px",
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        color:
                          getMaxHours(this.props.groupData.data) > 8 &&
                          "#f04c16"
                      }}
                    >
                      Horas totales: {getHours(this.props.groupData.data)}
                    </Typography>
                  </div>
                )}
                <IconButton
                  style={{
                    marginRight: "5px",
                    transition: "all ease 200ms",
                    ...this.rotateIconStyle(this.props.groupData.isExpanded)
                  }}
                  onClick={event => {
                    this.props.onGroupExpandChanged(this.props.path);
                  }}
                >
                  <this.props.icons.DetailPanel />
                </IconButton>
              </Box>
            </div>
          </CustomCell>
        </TableRow>
        {detail}
      </>
    );
  }
}

CustomGroupRow.defaultProps = {
  columns: [],
  groups: [],
  options: {},
  level: 0
};

CustomGroupRow.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  components: PropTypes.object,
  detailPanel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  getFieldValue: PropTypes.func,
  groupData: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object),
  hasAnyEditingRow: PropTypes.bool,
  icons: PropTypes.object,
  isTreeData: PropTypes.bool.isRequired,
  level: PropTypes.number,
  localization: PropTypes.object,
  onGroupExpandChanged: PropTypes.func,
  onRowSelected: PropTypes.func,
  onRowClick: PropTypes.func,
  onToggleDetailPanel: PropTypes.func.isRequired,
  onTreeExpandChanged: PropTypes.func.isRequired,
  onEditingCanceled: PropTypes.func,
  onEditingApproved: PropTypes.func,
  options: PropTypes.object,
  path: PropTypes.arrayOf(PropTypes.number)
};
