import {
  ADD_USER_ACTIVITY,
  DELETE_USER_ACTIVITY,
  GET_CURRENT_USER_ACTIVITIES,
  GET_USER_ACTIVITIES,
  GET_USER_ACTIVITY,
  SET_USER_ACTIVITY_DETAILS,
  USER_ACTIVITY_ALERT
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  userActivities: [],
  news: [],
  currentActivity: null,
  userActivityAlert: null,
  currentUserActivities: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_ACTIVITIES: {
      return {
        ...state,
        userActivities: action.payload
      };
    }
    case GET_USER_ACTIVITY: {
      return {
        ...state,
        currentActivity: action.payload
      };
    }
    case ADD_USER_ACTIVITY: {
      return { ...state, news: [action.payload, ...state.news] };
    }
    case DELETE_USER_ACTIVITY: {
      return {
        ...state,
        userActivities: state.userActivities.filter(
          activity => activity.id !== action.payload
        )
      };
    }
    case SET_USER_ACTIVITY_DETAILS: {
      return {
        ...state,
        currentActivity: action.payload
      };
    }
    case USER_ACTIVITY_ALERT: {
      return {
        ...state,
        userActivityAlert: action.payload
      };
    }
    case GET_CURRENT_USER_ACTIVITIES: {
      return {
        ...state,
        currentUserActivities: action.payload
      };
    }
    default:
      return state;
  }
};
