import {useContext, useState} from "react";
import PermissionContext from "./PermissionContext";

const usePermission = permission => {
  const [ allowed, setAllowed ] = useState();

  const {isAllowedTo} = useContext(PermissionContext);
  isAllowedTo(permission).then(allowed => {
    setAllowed(allowed);
  });
  return [ allowed ];
};

export default usePermission;
