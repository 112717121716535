import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";
import {
  GET_TIME_TRACKING_ITEMS,
  GET_APPROVABLES_TIME_TRACKING_ITEMS,
  GET_ADMIN_TIME_TRACKING_ITEMS,
  GET_TIME_TRACKING_ITEMS_HISTORY,
  GET_TIME_TRACKING_ITEMS_BY_ID,
  GET_TIME_TRACKING_ITEMS_BY_LEADER
} from "../../@jumbo/constants/ActionTypes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getTimeTrackingItems = callbackFun => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TIME_TRACKING_ITEMS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getTimeTrackingItemsHistory = callbackFun => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(
        //`${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items/history`
        `${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items/history-new`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_TIME_TRACKING_ITEMS_HISTORY,
            payload: data.data
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getApprovableTimeTrackingItems = (
  userId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/${userId}/time-tracking-items/approvables`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_APPROVABLES_TIME_TRACKING_ITEMS,
            payload: data.data
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getAdminTimeTrackingItems = (
  leaderId,
  filterOptions,
  searchTerm = "",
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/users/${leaderId}/time-tracking-items`,
        { params: filterOptions }
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_ADMIN_TIME_TRACKING_ITEMS,
            payload: data.data
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};
export const addTimeTrackingItem = (
  timeTrackingItem,
  callbackFun,
  userIdExist,
  userId
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items`,
        timeTrackingItem
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("New Time tracking Item was added successfully.")
          );
          dispatch(getTimeTrackingItems(() => { }));
          dispatch(getTimeTrackingItemsHistory(() => { }));
          //dispatch({ type: ADD_TIME_TRACKING_ITEM, payload: data.data });
          if (callbackFun) callbackFun(data.data);
          if (userIdExist !== "") {
            dispatch(getTimeTrackingItemsById(userId, [], "", () => { }));
          }
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateTimeTrackingItem = (timeTrackingItem, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items/${timeTrackingItem.id}`,
        timeTrackingItem
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("New Time tracking Item was updated successfully.")
          );
          /*  dispatch(getTimeTrackingItems(() => {}));
          dispatch(getTimeTrackingItemsHistory(() => {})); */
          //dispatch({ type: ADD_TIME_TRACKING_ITEM, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .then(data => {
        dispatch(getTimeTrackingItems(() => { }));
        dispatch(getTimeTrackingItemsHistory(() => { }));
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateTimeTrackingItemAdmin = (
  timeTrackingItem,
  leaderId,
  params,
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items/${timeTrackingItem.id}`,
        timeTrackingItem
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("New Time tracking Item was updated successfully.")
          );
          if (leaderId !== "") {
            dispatch(getAdminTimeTrackingItems(leaderId, params, "", () => { }));
          }
          //dispatch({ type: ADD_TIME_TRACKING_ITEM, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const approveTimeTrackingItem = (id, userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items/${id}/approve`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("Time tracking Item was approved successfully.")
          );
          dispatch(getApprovableTimeTrackingItems(userId));
          //dispatch({ type: ADD_TIME_TRACKING_ITEM, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const confirmTimeTrackingItems = (timeTrackingItemIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items/confirm`,
        timeTrackingItemIds
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("Time tracking Items were confirmed successfully.")
          );
          dispatch(getTimeTrackingItems());
          dispatch(getTimeTrackingItemsHistory());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const rejectTimeTrackingItem = (id, userId, motivo, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items/${id}/reject`,
        motivo
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("Time tracking Item was rejected successfully.")
          );
          dispatch(getApprovableTimeTrackingItems(userId));
          //dispatch({ type: ADD_TIME_TRACKING_ITEM, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const removeTimeTrackingItem = (id, leaderId, params, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT}/api/time-tracking-items/${id}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Evento removido."));
          if (leaderId !== "") {
            dispatch(getAdminTimeTrackingItems(leaderId, params, "", () => { }));
          }
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Hubo un error en la respuesta del servidor."));
        }
      })
      .then(data => {
        dispatch(getTimeTrackingItems(() => { }));
        dispatch(getTimeTrackingItemsHistory(() => { }));
      })
      .catch(error => {
        dispatch(fetchError("Hubo un error en la respuesta del servidor"));
      });
  };
};

export const getTimeTrackingItemsById = (
  userId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/${userId}/time-tracking-items`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TIME_TRACKING_ITEMS_BY_ID, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const addAdminTimeTrackingItem = (
  timeTrackingItem,
  callbackFun,
  userId
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/time-tracking-items`,
        timeTrackingItem
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Asignaste horas como administrador"));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const generateReport = (report, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/reports`, report)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Se generó un reporte"));
          MySwal.fire(
            "¡Listo!",
            "El reporte se creó correctamente, podes verlo ingresando en 'Ver reportes' > en la carpeta que contenga tu nombre",
            "success"
          );
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
          MySwal.fire("¡Oh no!", "Hubo un error creando el reporte", "error");
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
        MySwal.fire("¡Oh no!", "Hubo un error creando el reporte", "error");
      });
  };
};

export const getTimeTrackingItemsByLeader = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/${userId}/time-tracking-items/training-licences`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_TIME_TRACKING_ITEMS_BY_LEADER,
            payload: data.data
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const generateReportDir = (report, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/general-report`, report)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Se generó un reporte"));
          MySwal.fire(
            "¡Listo!",
            "El reporte se creó correctamente, podes verlo ingresando en 'Ver reportes' > en la carpeta que contenga tu nombre",
            "success"
          );
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
          MySwal.fire("¡Oh no!", "Hubo un error creando el reporte", "error");
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
        MySwal.fire("¡Oh no!", "Hubo un error creando el reporte", "error");
      });
  };
};

export const generateReportGes = (report, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/gestor-report`, report)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Se generó un reporte"));
          MySwal.fire(
            "¡Listo!",
            "El reporte se creó correctamente, podes verlo ingresando en 'Ver reportes' > en la carpeta que contenga tu nombre",
            "success"
          );
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
          MySwal.fire("¡Oh no!", "Hubo un error creando el reporte", "error");
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
        MySwal.fire("¡Oh no!", "Hubo un error creando el reporte", "error");
      });
  };
};
