import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "./Common";
import Auth from "./Auth";
import Users from "./Users";
import Licences from "./Licences";
import Trainings from "./Trainings";
import UserActivities from "./UserActivities";
import Projects from "./Projects";
import Storage from "./Storage";
import TimeTrackingItems from "./TimeTrackingItems";
import Roles from "./Roles";
import Holidays from "./Holidays";

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    usersReducer: Users,
    licencesReducer: Licences,
    trainingsReducer: Trainings,
    userActivitiesReducer: UserActivities,
    projectsReducer: Projects,
    rolesReducer: Roles,
    storage: Storage,
    timeTrackingItemsReducer: TimeTrackingItems,
    holidaysReducer: Holidays
  });
