import {
  ADD_LICENCE,
  DELETE_BULK_LICENCES,
  DELETE_LICENCE,
  EDIT_LICENCE,
  GET_LICENCES,
  GET_LICENCE,
  SET_LICENCE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  licences: [],
  currentLicence: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LICENCES: {
      return {
        ...state,
        licences: action.payload,
      };
    }
    case GET_LICENCE: {
      return {
        ...state,
        currentLicence: action.payload,
      };
    }
    case SET_LICENCE_DETAILS: {
      return {
        ...state,
        currentLicence: action.payload,
      };
    }
    case ADD_LICENCE: {
      return {
        ...state,
        licences: [action.payload, ...state.licences],
      };
    }
    case EDIT_LICENCE: {
      return {
        ...state,
        licences: state.licences.map(licence => (licence.id === action.payload.id ? action.payload : licence)),
      };
    }
    case DELETE_LICENCE: {
      return {
        ...state,
        licences: state.licences.filter(licence => licence.id !== action.payload),
      };
    }
    case DELETE_BULK_LICENCES: {
      return {
        ...state,
        licences: state.licences.filter(licence => !action.payload.includes(licence.id)),
      };
    }
    default:
      return state;
  }
};
