import Firebase from "./firebase";

export const StorageMethods = {
  firebase: Firebase
};

export const StorageContentTypes = {
  USER_IMAGE_UPLOAD: "USER_IMAGE_UPLOAD",
  NEWS_IMAGE_UPLOAD: "NEWS_IMAGE_UPLOAD",
  NEWS_VIDEO_UPLOAD: "NEWS_VIDEO_UPLOAD",
  BENEFITS_IMAGE_UPLOAD: "BENEFITS_IMAGE_UPLOAD",
  BENEFITS_VIDEO_UPLOAD: "BENEFITS_VIDEO_UPLOAD"
};
