import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#43A205",
    marginBottom: "5px"
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center"
  }
}));

const RoleAlert = () => {
  const { roleAlert } = useSelector(({ rolesReducer }) => rolesReducer);
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Typography className={classes.text}>{roleAlert}</Typography>
    </Box>
  );
};
export default RoleAlert;
