import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { Box, makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";
import { Home } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  turnedOn: {
    color: theme.palette.common.white
  }
}));

const Logo = ({ color, ...props }) => {
  const classes = useStyles();
  const logoUrl =
    color === "white" ? "/images/LogoHeaderCDT20.svg" : "/images/logo.png";

  //const logoOriginal = '/images/LogoHeaderCDT.png'

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage
            style={{ heigth: "53px", width: "270px" }}
            src={logoUrl}
            alt="logo"
          />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <Home className={classes.turnedOn} />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
