import React, { useEffect } from "react";
import TimeTrackingCalendar from "./TimeTrackingCalendar";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@material-ui/core";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import Restricted from "../../../../services/permissionProvider/Restricted";
import Helper from "@jumbo/components/Helper";
import { useDispatch, useSelector } from "react-redux";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { getTimeTrackingItemsHistory } from "../../../../redux/actions/TimeTrackingItems";
import Forbidden from "@jumbo/components/Forbidden";
import { HORAS_CARGAR } from "../../../../services/permissionProvider/PermissionsCatalog";
import CustomTimeTrackingLoader from "./CustomTimeTrackingLoader";
import { useHasPrivilege } from "routes/checkPermission";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: "relative",
    "& .Cmt-card-content": {
      padding: "15px",
      paddingBottom: 24
    }
  },
  scrollbarRoot: {
    height: 280
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: "2px 10px",
    borderRadius: 30
  }
}));

export default function TimeTrackingModule() {
  const canDispatch = useHasPrivilege(HORAS_CARGAR);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (canDispatch) {
      dispatch(getTimeTrackingItemsHistory(() => {}));
    }
  }, [dispatch, canDispatch]);

  //let fecha = columns[0];
  // console.log(fecha);
  /*  let fechaid = "id"
  fecha[fechaid] = "fechaid" 

Object.defineProperty(fecha, 'id', {
    value: "fechaid"
  }); 

  Object.assign( fecha, { id: "fechaid" });
 
  let asignacion = columns[1].title
  console.log(asignacion)
  */

  const { timeTrackingItemsHistory } = useSelector(
    ({ timeTrackingItemsReducer }) => timeTrackingItemsReducer
  );

  return (
    <Restricted to={HORAS_CARGAR} fallback={Forbidden}>
      <GridContainer>
        <Grid item style={{ width: "100%" }}>
          <Helper
            message={
              <div>
                <p>
                  En esta sección podés cargar tus horas, clickea en{" "}
                  <strong>"Agregar Entrada"</strong> y agregalas
                </p>
                <p>
                  Cuando ya no necesites editarlas, presioná{" "}
                  <strong>"Confirmar Entradas"</strong>, y un gestor las
                  revisará.
                </p>
                <p>
                  Para ver el detalle de entradas ya envíadas, podés consultar
                  la sección <strong>"Mi Historial"</strong>, abajo en esta
                  página.
                </p>
              </div>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <CustomTimeTrackingLoader />
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardHeader title="Mi Historial" />
            <CmtCardContent>
              <TimeTrackingCalendar
                timeTrackingItems={timeTrackingItemsHistory}
              />
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </Restricted>
  );
}
