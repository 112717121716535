import {
  ADD_TRAINING,
  DELETE_BULK_TRAININGS,
  DELETE_TRAINING,
  EDIT_TRAINING,
  GET_TRAININGS,
  GET_TRAINING,
  SET_TRAINING_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  trainings: [],
  currentTraining: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRAININGS: {
      return {
        ...state,
        trainings: action.payload,
      };
    }
    case GET_TRAINING: {
      return {
        ...state,
        currentTraining: action.payload,
      };
    }
    case SET_TRAINING_DETAILS: {
      return {
        ...state,
        currentTraining: action.payload,
      };
    }
    case ADD_TRAINING: {
      return {
        ...state,
        trainings: [action.payload, ...state.trainings],
      };
    }
    case EDIT_TRAINING: {
      return {
        ...state,
        trainings: state.trainings.map(training => (training.id === action.payload.id ? action.payload : training)),
      };
    }
    case DELETE_TRAINING: {
      return {
        ...state,
        trainings: state.trainings.filter(training => training.id !== action.payload),
      };
    }
    case DELETE_BULK_TRAININGS: {
      return {
        ...state,
        trainings: state.trainings.filter(training => !action.payload.includes(training.id)),
      };
    }
    default:
      return state;
  }
};
