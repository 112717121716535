import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  Zoom
} from "@material-ui/core";
import GridContainer from "@jumbo/components/GridContainer";
import { useDispatch, useSelector } from "react-redux";
import SingleNews from "./SingleNews";
import {
  getCurrentUserActivities,
  getUserActivities
} from "redux/actions/UserActivities";
import { useHistory } from "react-router-dom";
import Restricted from "services/permissionProvider/Restricted";
import {
  NOTICIAS_EDITAR,
  NOTICIAS_VER
} from "services/permissionProvider/PermissionsCatalog";
import CmtImage from "@coremat/CmtImage";
import EditNew from "./EditNew";
import Forbidden from "@jumbo/components/Forbidden";
import moment from "moment";
import { useHasPrivilege } from "routes/checkPermission";

const useStyles = makeStyles(theme => ({
  button: {
    padding: "15px",
    marginBottom: "20px",
    borderRadius: "20px",
    boxShadow: "0 0 5px 5px rgba(168, 175, 206, 0.8)"
  },
  title: {
    fontSize: "35px"
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  }
}));

const News = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { userActivities } = useSelector(
    ({ userActivitiesReducer }) => userActivitiesReducer
  );
  const { currentUserActivities } = useSelector(
    ({ userActivitiesReducer }) => userActivitiesReducer
  );
  const [showAllNews, setShowAllNews] = useState(false);
  const [editarNoticia, setEditarNoticia] = useState(false);
  const [currentActivity, setCurrentActivity] = useState("");

  const history = useHistory();

  const canDispatch = useHasPrivilege(NOTICIAS_VER);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canDispatch) {
      dispatch(getUserActivities([], "", () => {}));
    }
  }, [dispatch, canDispatch]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getCurrentUserActivities([], "", () => {}));
    }
  }, [dispatch, userActivities, canDispatch]);

  const newsByAreaOrMail = currentUserActivities
    .filter(act => {
      let acts;
      if (
        !!act.recipient_areas &&
        act.recipient_areas.some(area => area.name === authUser.area.name)
      ) {
        acts = act;
      } else if (
        !!act.recipient_users &&
        act.recipient_users.some(user => user.email === authUser.email)
      ) {
        acts = act;
      }
      return acts;
    })
    .map(act => act);

  const currentActivities = newsByAreaOrMail.sort((a, b) => {
    if (a.id === b.id) {
      return 0;
    }
    if (a.id > b.id) {
      return -1;
    }
    return 1;
  });

  const allActivities = userActivities.sort((a, b) => {
    if (a.id === b.id) {
      return 0;
    }
    if (a.id > b.id) {
      return -1;
    }
    return 1;
  });

  const changeNewsDisplay = () => {
    if (showAllNews) {
      setShowAllNews(false);
    } else {
      setShowAllNews(true);
    }
  };

  const handleEditNew = act => {
    setEditarNoticia(true);
    setCurrentActivity({ act });
  };

  const handleCancelEditNew = () => {
    setEditarNoticia(false);
  };

  const formatDate = date => {
    const newDate = moment(date)
      .add(1, "days")
      .format("DD/MM/yyyy");
    return newDate;
  };

  return (
    <Restricted to={NOTICIAS_VER} fallback={Forbidden}>
      <Box>
        <GridContainer className={classes.gridContainer}>
          <Grid item>
            <Restricted to={NOTICIAS_EDITAR}>
              <Tooltip
                title={
                  showAllNews ? "Ver las noticias recientes" : "Editar noticias"
                }
                TransitionComponent={Zoom}
              >
                <Button
                  id="btn-verNoticias"
                  variant="outlined"
                  className={classes.button}
                  onClick={() => changeNewsDisplay()}
                >
                  {showAllNews
                    ? "VER LAS NOTICIAS RECIENTES"
                    : "EDITAR NOTICIAS"}
                </Button>
              </Tooltip>
            </Restricted>
          </Grid>
          <Grid item>
            <Typography className={classes.title}>Noticias</Typography>
          </Grid>
          <Grid item>
            <Restricted to={NOTICIAS_EDITAR}>
              <Tooltip title="Agregar noticia" TransitionComponent={Zoom}>
                <Button
                  id="btn-agregarNoticia"
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  onClick={() => history.push("/agregar-noticia")}
                >
                  AGREGAR NUEVA NOTICIA
                </Button>
              </Tooltip>
            </Restricted>
          </Grid>
        </GridContainer>

        {!showAllNews ? (
          <GridContainer
            spacing={3}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {currentActivities.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  columnGap: "0.8em",
                  columns: 5,
                  height: "100%"
                }}
              >
                {currentActivities.map(activity => (
                  <Grid item key={activity.id}>
                    <div
                      style={{
                        height: "100%",
                        width: "350px",
                        display: "block"
                      }}
                    >
                      <SingleNews
                        title={activity.title}
                        date={formatDate(activity.publish_date)}
                        description={activity.description}
                        image={activity.image_url}
                        video={activity.video_url}
                        id={activity.id}
                        activity={activity}
                        handleEditNew={handleEditNew}
                        showAllNews={showAllNews}
                      />
                    </div>
                  </Grid>
                ))}
              </div>
            ) : (
              <Grid
                style={{
                  height: "100%",
                  maxwidth: "345px",
                  marginTop: "50px",
                  marginBottom: "0.8em",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Box>
                  <Card sx={{ maxwidth: 600 }}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="cdt-pic">
                          <img
                            src="https://media-exp1.licdn.com/dms/image/C4D0BAQE-f4fkYhjAtQ/company-logo_200_200/0/1556211617173?e=2147483647&v=beta&t=n2WH60sO4EFD_A1L3_9rFLX3iRm4hxfG4ZGTF9vx6Uw"
                            alt="CDT"
                          />
                        </Avatar>
                      }
                      title="¡Bienvenidos a CDT!"
                    />
                    <CmtImage src="/images/bienvenidaCDT.jpg" />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary">
                        Todas las novedades de CDT se van a encontrar en este
                        portal.
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            )}
          </GridContainer>
        ) : (
          <GridContainer
            spacing={3}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {allActivities.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  columnGap: "35px",
                  columns: 5,
                  height: "100%",
                  width: "100%"
                }}
              >
                {allActivities.map(activity => (
                  <Grid item key={activity.id}>
                    <div
                      style={{
                        height: "100%",
                        width: "370px",
                        display: "block"
                      }}
                      key={activity.id}
                    >
                      <SingleNews
                        title={activity.title}
                        date={formatDate(activity.publish_date)}
                        description={activity.description}
                        image={activity.image_url}
                        video={activity.video_url}
                        id={activity.id}
                        activity={activity}
                        handleEditNew={handleEditNew}
                        showAllNews={showAllNews}
                      />
                    </div>
                  </Grid>
                ))}
              </div>
            ) : (
              <Grid
                style={{
                  width: "100%",
                  marginTop: "50px",
                  marginBottom: "0.8em",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Box>
                  <Card sx={{ maxwidth: 600 }}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="cdt-pic">
                          <img
                            src="https://media-exp1.licdn.com/dms/image/C4D0BAQE-f4fkYhjAtQ/company-logo_200_200/0/1556211617173?e=2147483647&v=beta&t=n2WH60sO4EFD_A1L3_9rFLX3iRm4hxfG4ZGTF9vx6Uw"
                            alt="CDT"
                          />
                        </Avatar>
                      }
                      title="¡Bienvenidos a CDT!"
                    />
                    <CmtImage src="/images/bienvenidaCDT.jpg" />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary">
                        Todas las novedades de CDT se van a encontrar en este
                        portal.
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            )}
          </GridContainer>
        )}
        <Dialog open={editarNoticia}>
          <DialogContent>
            <EditNew
              handleCancelEditNew={handleCancelEditNew}
              currentActivity={currentActivity}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Restricted>
  );
};

export default News;
