import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Popover, Tooltip, useTheme } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppsIcon from "@material-ui/icons/Apps";
import CmtCard from "../../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../../@coremat/CmtCard/CmtCardContent";
import HelpIcon from "@material-ui/icons/Help";
import CmtGridView from "../../../../../@coremat/CmtGridView";
import CmtAvatar from "../../../../../@coremat/CmtAvatar";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { setShowHelp } from "../../../../../redux/actions/Common";
import { AccessTime, Portrait } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 4,
      paddingBottom: 4
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  iconRoot: {
    color: alpha(theme.palette.common.white, 0.38),
    "&:hover, &:focus": {
      color: theme.palette.common.white
    }
  },
  turnedOn: {
    color: theme.palette.common.white
  },
  turnedOff: {
    color: alpha(theme.palette.common.white, 0.38)
  }
}));

const actions = [];

const applications = [
  {
    name: "Horas",
    icon: <AccessTime style={{ color: "#0795F4" }} />,
    bgColor: "#CDEAFD",
    path: "/carga-de-horas"
  },
  {
    name: "Perfil",
    icon: <Portrait style={{ color: "#8DCD03" }} />,
    bgColor: "#E8F5CD",
    path: "/perfil"
  }
];

const appItem = (item, index, onClick) => {
  return (
    <Box
      key={index}
      py={2}
      className="pointer"
      display="flex"
      flexDirection="column"
      alignItems="center"
      onClick={() => onClick(item.path)}
    >
      <CmtAvatar style={{ backgroundColor: item.bgColor }} size={62}>
        {item.icon}
      </CmtAvatar>
      <Box mt={5} fontSize={16} fontWeight="bold" color="#666666">
        {item.name}
      </Box>
    </Box>
  );
};

const AppsMenu = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const history = useHistory();
  const { showHelp } = useSelector(({ common }) => common);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const toggleShowHelp = () => {
    dispatch(setShowHelp(!showHelp));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onClickApp = path => {
    history.push(path);
    onClosePopOver();
  };

  const [showHelpIcon, setShowHelpIcon] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/aprobacion-de-horas" ||
      location.pathname === "/admin-horas" ||
      location.pathname === "/carga-de-horas"
    ) {
      setShowHelpIcon(true);
    } else {
      setShowHelpIcon(false);
    }
  }, [showHelpIcon, location]);

  return (
    <div>
      {showHelpIcon && (
        <Tooltip title="Ayuda">
          <IconButton
            className={clsx(showHelp ? classes.turnedOff : classes.turnedOn)}
            onClick={toggleShowHelp}
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Aplicaciones">
        <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, "Cmt-appIcon")}
        >
          <AppsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Aplicaciones"
            actionsPos="top-corner"
            actions={actions}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: "solid"
            }}
          />
          <CmtCardContent>
            {applications.length > 0 ? (
              <CmtGridView
                itemPadding={24}
                column={2}
                data={applications}
                renderRow={(item, index) => appItem(item, index, onClickApp)}
              />
            ) : (
              <Typography variant="body2">No applications found</Typography>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover>
    </div>
  );
};

export default AppsMenu;
