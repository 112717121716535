import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TrainingListRow from "./TrainingListRow";
import TrainingTableHead from "./TrainingTableHead";
import TrainingTableToolbar from "./TrainingTableToolbar";
import { getComparator, stableSort } from "../../../@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTraining,
  getTrainings,
  setCurrentTraining
} from "../../../redux/actions/Trainings";
import AddEditTraining from "./AddEditTraining";
import ConfirmDialog from "../../../@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "../../../@jumbo/utils/commonHelper";
import { useHistory } from "react-router-dom";
import useStyles from "./index.style";
import TrainingDetailView from "./TrainingDetailView";
import NoRecordFound from "./NoRecordFound";
import Restricted from "services/permissionProvider/Restricted";
import Forbidden from "@jumbo/components/Forbidden";
import { CAPACITACIONES_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import SuspendedTrainings from "./SuspendedTrainings";
import { useHasPrivilege } from "routes/checkPermission";

const TrainingsModule = () => {
  const classes = useStyles();
  const { trainings } = useSelector(({ trainingsReducer }) => trainingsReducer);
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openTrainingDialog, setOpenTrainingDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState({ name: "" });
  const [trainingsFetched, setTrainingsFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewSuspendedTrainings, setViewSuspendedTrainings] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const canDispatch = useHasPrivilege(CAPACITACIONES_EDITAR);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    if (canDispatch) {
      dispatch(
        getTrainings(filterOptions, debouncedSearchTerm, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setTrainingsFetched(true);
        })
      );
    }
  }, [dispatch, filterOptions, debouncedSearchTerm, canDispatch]);

  const activeTrainings = trainings.filter(
    training => training.status === "ACTIVE"
  );

  const suspendedTrainings = trainings.filter(
    training => training.status === "SUSPENDED"
  );

  const orderedActive = stableSort(
    activeTrainings,
    getComparator(order, orderBy)
  );

  const orderedSuspended = stableSort(
    suspendedTrainings,
    getComparator(order, orderBy)
  );

  const handleCloseTrainingDialog = () => {
    setOpenTrainingDialog(false);
    dispatch(setCurrentTraining(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = trainings.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTrainingView = training => {
    dispatch(setCurrentTraining(training));
    history.push("/capacitaciones/" + training.id);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentTraining(null));
  };

  const handleTrainingEdit = training => {
    dispatch(setCurrentTraining(training));
    setOpenTrainingDialog(true);
  };

  const handleTrainingDelete = training => {
    setSelectedTraining(training);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    const a = selectedTraining.id;
    dispatch(deleteTraining(a));
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const filteredTrainings = orderedActive.filter(row => {
    let rows;
    if (searchTerm === "") {
      rows = row;
    } else if (row.name !== "" && row.name !== null && row.name !== undefined) {
      if (row.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        rows = row;
      }
    }
    return rows;
  });

  return (
    <Restricted to={CAPACITACIONES_EDITAR} fallback={Forbidden}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TrainingTableToolbar
            selected={selected}
            setSelected={setSelected}
            onTrainingAdd={setOpenTrainingDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="sticky enhanced table"
            >
              <TrainingTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={trainings.length}
              />
              <TableBody>
                {!!filteredTrainings.length ? (
                  filteredTrainings
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TrainingListRow
                        key={index}
                        row={row}
                        onRowClick={handleRowClick}
                        onTrainingEdit={handleTrainingEdit}
                        onTrainingDelete={handleTrainingDelete}
                        onTrainingView={handleTrainingView}
                        isSelected={isSelected}
                      />
                    ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>
                          No se encontraron capacitaciones.
                        </NoRecordFound>
                      ) : (
                        <NoRecordFound>
                          {trainingsFetched
                            ? "No se encontraron capacitaciones"
                            : "Cargando capacitaciones..."}
                        </NoRecordFound>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={activeTrainings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage="Filas por página"
          />
        </Paper>

        {openTrainingDialog && (
          <AddEditTraining
            open={openTrainingDialog}
            onCloseDialog={handleCloseTrainingDialog}
          />
        )}
        {openViewDialog && (
          <TrainingDetailView
            open={openViewDialog}
            onCloseDialog={handleCloseViewDialog}
          />
        )}

        <Button
          id="btn-capacitacionesSuspendidas"
          variant="outlined"
          onClick={() => setViewSuspendedTrainings(true)}
        >
          Ver capacitaciones suspendidas
        </Button>

        {viewSuspendedTrainings && (
          <SuspendedTrainings
            orderedSuspended={orderedSuspended}
            viewSuspendedTrainings={viewSuspendedTrainings}
            setViewSuspendedTrainings={setViewSuspendedTrainings}
          />
        )}

        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirmación de  borrado ${selectedTraining.name}`}
          content={"Estás seguro de querer borrar esta capacitación?"}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </Restricted>
  );
};

export default TrainingsModule;
