import Empleado from "./Empleado";
import './lista_empleados.css'

import React from "react";

export default function ListaEmpleados({ generarFirma, empleados }) {
    return (
        <>{
                empleados === undefined ?
                <div className="loader-wrapper">
                    <div className="loader"></div>
                </div>
                :
                <ul className="lista-empleados">
                    {empleados.map((empleado, empIndex) => {
                        return <Empleado key={empIndex} nombre={empleado.first_name !== null && empleado.first_name} apellido={empleado.last_name !== null && empleado.last_name} puesto={empleado.job_title !== null && empleado.job_title.name !== null && empleado.job_title.name} generarFirma={generarFirma}></Empleado>
                    })}
                </ul>
            }
        </>
    )
}