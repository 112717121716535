import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";
import CmtList from "../../../../@coremat/CmtList";
import { useDispatch, useSelector } from "react-redux";
import { getActiveUsers } from "../../../../redux/actions/Users";
import NewUserItem from "./NewUserItem";
import ActionSnackBar from "./ActionSnackBar";
import NoRecordFound from "routes/modules/Users/NoRecordFound";

const useStyles = makeStyles(() => ({
  cardRoot: {
    position: "relative",
    "& .Cmt-card-content": {
      padding: 0,
      paddingBottom: 24
    }
  },
  scrollbarRoot: {
    height: 292
  }
}));

const NewUsersList = () => {
  const { activeUsers } = useSelector(({ usersReducer }) => usersReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveUsers([], "", () => {}));
  }, [dispatch]);
  const classes = useStyles();
  const [removedRequests, setRemovedRequests] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [openSnackBar, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  useEffect(() => {
    if (currentRequest) {
      const data = [...removedRequests];
      data.push(currentRequest);
      setRemovedRequests(data);
      setSnackBarStatus(true);
    } else {
      setSnackBarStatus(false);
      setSnackBarMessage("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRequest, removedRequests]);

  const handleCloseSnackBar = () => {
    setCurrentRequest(null);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader
        title="Nuevos Usuarios"
        //subTitle={activeUsers.length + " Este mes"}
      />
      <CmtCardContent>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          {!!activeUsers.length ? (
            <CmtList
              data={activeUsers.reverse()}
              renderRow={(item, index) => {
                return <NewUserItem key={index} item={item} />;
              }}
            />
          ) : (
            <NoRecordFound>Cargando usuarios... </NoRecordFound>
          )}
        </PerfectScrollbar>
      </CmtCardContent>

      <ActionSnackBar
        message={snackBarMessage}
        open={openSnackBar}
        onClose={handleCloseSnackBar}
      />
    </CmtCard>
  );
};

export default NewUsersList;
