import React, { forwardRef } from "react";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PatchedPagination from "./PatchedPagination";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  }
}));

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <span
      id="btn-agregarEntrada"
      {...props}
      ref={ref}
      className="MuiButton-label MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
    >
      Agregar Entrada
    </span>
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const TimeTrackingTable = ({
  actions,
  editable,
  columns,
  timeTrackingItems,
  title,
  options,
  components
}) => {
  const classes = useStyles();

  return (
    <MaterialTable
      components={{
        ...components,
        Pagination: PatchedPagination
      }}
      options={{
        ...options,
        pageSize: 20,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [10, 20, 30],
        sorting: true
      }}
      className={classes.root}
      icons={tableIcons}
      actions={actions}
      title={title}
      columns={columns}
      data={timeTrackingItems}
      editable={editable}
      localization={{
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "Filas por página"
        },
        header: {
          actions: ""
        },
        body: {
          emptyDataSourceMessage: "No hay registros",
          filterRow: {
            filterTooltip: "Filtro"
          },
          editRow: {
            saveTooltip: "Guardar",
            cancelTooltip: "Cancelar",
            deleteText: "¿Querés borrar esta fila?"
          },
          addTooltip: "Agregar Entrada",
          deleteTooltip: "Borrar",
          editTooltip: "Editar"
        },
        toolbar: {
          searchPlaceholder: "Buscar",
          searchTooltip: "Buscar",
          nRowsSelected: "{0} fila(s) seleccionadas"
        }
      }}
    />
  );
};

export default TimeTrackingTable;
