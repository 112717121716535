import './empleado.css';

import React from 'react';

export default function Empleado({nombre, apellido, puesto, generarFirma}){

    return(
        <li className={sessionStorage.getItem("colorMode") === "dark" ? "empleado modo-oscuro" : ' empleado'} onClick={() => generarFirma(nombre, apellido, puesto)}>
            <span className='nombre-apellido'>{nombre} {apellido}</span>
            <span className='puesto'>{puesto}</span>
        </li>
    )
}