import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import {
  Box,
  Button,
  Dialog,
  Divider,
  makeStyles,
  MenuItem,
  TextField
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  generateReport,
  generateReportDir,
  generateReportGes
} from "redux/actions/TimeTrackingItems";
import { useHasPrivilege } from "routes/checkPermission";
import { HORAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  helperText: {
    "& .MuiFormHelperText-root": {
      color: "#ff0000 ! important"
    }
  }
}));

const DownloadReport = ({
  isOpenCreateReport,
  setIsOpenCreateReport,
  userName
}) => {
  const [reportType, setReportType] = useState("");
  const [reportTemplate, setReportTemplate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isValidStartDate, setIsValidStartDate] = useState("");
  const [isValidEndDate, setIsValidEndDate] = useState("");
  const [reportName, setReportName] = useState("");
  const [isValidReportName, setIsValidReportName] = useState("");
  const canDispatchAdmin = useHasPrivilege(HORAS_EDITAR);
  const { authUser } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();
  const styles = useStyles();

  useEffect(() => {
    const fecha1 = moment(startDate).format("DD/MM/YYYY");
    const fecha2 = moment(endDate).format("DD/MM/YYYY");

    if (startDate !== null && endDate !== "") {
      if (fecha2.slice(3, 5) > fecha1.slice(3, 5)) {
        setIsValidEndDate(true);
      } else {
        if (fecha1 !== fecha2 && fecha1 < fecha2) {
          setIsValidEndDate(true);
        } else {
          setIsValidEndDate("mostrar");
        }
      }
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (reportTemplate === "GESTOR") {
      setReportType("ALL");
    }
  }, [reportTemplate]);


  const checkIsValidStartDate = date => {
    setStartDate(date);
    if (date === "" || date === null) {
      setIsValidStartDate("mostrar");
    } else {
      setIsValidStartDate(true);
    }
  };

  const checkIsValidEndDate = date => {
    setEndDate(date);
    if (date === null || date === "") {
      setIsValidStartDate("mostrar");
    } else {
      setIsValidEndDate(true);
    }
  };

  const checkIsValidReportName = value => {
    setReportName(value);
    const reportNameTrimmed = value.replace(/\s+/g, "");
    if (value.length !== reportNameTrimmed.length) {
      setIsValidReportName(false);
    } else {
      setIsValidReportName(true);
    }
  };

  const handleChange = (value, name) => {
    switch (name) {
      case "reportType":
        return setReportType(value);
      case "reportTemplate":
        setReportTemplate(value);
        if (value === "GESTOR") {
          setReportType("ALL");
        } else {
          setReportType(value);
        }
        break;
      case "startDate":
        return checkIsValidStartDate(value);
      case "endDate":
        return checkIsValidEndDate(value);
      case "reportName":
        return checkIsValidReportName(value);
      default:
        return console.log("algo salio mal en switch");
    }
  };

  const handleSubmit = () => {
    const startDateFormat = moment(startDate).format("DD/MM/YYYY");
    const endDateFormat = moment(endDate).format("DD/MM/YYYY");
    const id = authUser.id;

    const body = {
      startDate: startDateFormat,
      endDate: endDateFormat,
      name: reportName,
      type: reportType
    };
    //console.log("generar reporte con body:", body);

    const bodyGestor = {
      startDate: startDateFormat,
      endDate: endDateFormat,
      name: reportName,
      leaderId: id
    };

    if (reportTemplate === "ESTANDAR") {
      dispatch(generateReport(body, "", () => { }));
    } else if (reportTemplate === "DIRECCION") {
      dispatch(generateReportDir(body, "", () => { }));
    } else if (reportTemplate === "GESTOR") {
      dispatch(generateReportGes(bodyGestor, "", () => { }));
    }

    handleCancel();
  };

  const handleCancel = () => {
    setReportType("");
    setReportTemplate("");
    setStartDate("");
    setEndDate("");
    setIsValidStartDate("");
    setIsValidEndDate("");
    setReportName("");
    setIsValidReportName("");
    setIsOpenCreateReport(false);
  };

  return (
    <Dialog open={isOpenCreateReport}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: "20px"
        }}
      >
        <CmtCard style={{ width: "435px" }}>
          <Box display="flex" justifyContent="center">
            <CmtCardHeader title={`Generador de reportes`} />
          </Box>
          <Box>
            <Divider />
          </Box>
          {/* <Box
            style={{
              padding: "10px",
              margin: "10px 20px 10px 20px",
              borderRadius: "15px",
              border: "2px solid rgb(232, 244, 253)"
            }}
          >
            <Typography style={{ fontSize: "14px" }}>
              Tené en cuenta que las horas se actualizan cada día a las 21:30hs.
            </Typography>
            <Typography style={{ fontSize: "14px" }}>
              Las horas imputadas/modificadas el día de hoy se verán reflejadas
              en reportes generados a partir de ese horario.
            </Typography>
          </Box> */}
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center">
              <TextField
                id="sel-formatoReporte"
                variant="outlined"
                select
                label="Formato de reporte"
                style={{ width: "350px", margin: "10px" }}
                onChange={e => handleChange(e.target.value, "reportTemplate")}
                value={reportTemplate}
              >
                {canDispatchAdmin && (
                  <MenuItem value={"ESTANDAR"} id="formato-Estándar">
                    Formato Estándar
                  </MenuItem>
                )}
                {canDispatchAdmin && (
                  <MenuItem value={"DIRECCION"} id="formato-">
                    Formato Dirección
                  </MenuItem>
                )}
                <MenuItem value={"GESTOR"} id="formato-Gestor">
                  Formato Gestor
                </MenuItem>
              </TextField>
            </Box>
            <Box display="flex" justifyContent="center">
              <TextField
                id="sel-tipoReporte"
                variant="outlined"
                select
                label="Tipo de reporte"
                style={{ width: "350px", margin: "10px" }}
                onChange={e => handleChange(e.target.value, "reportType")}
                value={reportType}
                disabled={reportTemplate === "GESTOR"}
              >
                {canDispatchAdmin && reportTemplate !== "GESTOR" && (
                  <>
                    <MenuItem value={"TRAINING"} id="opcion-Capacitaciones">
                      Capacitaciones
                    </MenuItem>
                    <MenuItem value={"LICENCE"} id="opcion-Licencias">
                      Licencias
                    </MenuItem>
                    <MenuItem value={"PROJECT"} id="opcion-Proyectos">
                      Proyectos
                    </MenuItem>
                  </>
                )}
                <MenuItem value={"ALL"} id="opcion-Todas">
                  Todas las horas
                </MenuItem>
              </TextField>
            </Box>

            <Box display="flex" justifyContent="center">
              <TextField
                id="btn-fechaInicio"
                type="date"
                name="start_date"
                label="Fecha de Inicio"
                variant="outlined"
                className={styles.helperText}
                style={{ width: "350px", margin: "10px" }}
                onChange={e => {
                  handleChange(e.target.value, "startDate");
                }}
                InputLabelProps={{
                  shrink: true
                }}
                helperText={
                  isValidStartDate === "mostrar"
                    ? "Debes seleccionar una fecha previa a la fecha de fin"
                    : ""
                }
                FormHelperTextProps={{
                  color: "secondary"
                }}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <TextField
                id="btn-fechaFin"
                type="date"
                name="end_date"
                label="Fecha de Fin"
                variant="outlined"
                className={styles.helperText}
                style={{ width: "350px", margin: "10px" }}
                onChange={e => {
                  handleChange(e.target.value, "endDate");
                }}
                InputLabelProps={{
                  shrink: true
                }}
                helperText={
                  isValidEndDate === "mostrar"
                    ? "Debes seleccionar una fecha posterior a la fecha de inicio"
                    : ""
                }
                FormHelperTextProps={{
                  color: "secondary"
                }}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <TextField
                id="txt-NombreReporte"
                type="text"
                name="reportName"
                label="Nombre del reporte"
                variant="outlined"
                className={styles.helperText}
                style={{ width: "350px", margin: "10px" }}
                onChange={e => {
                  handleChange(e.target.value, "reportName");
                }}
                value={reportName}
                InputLabelProps={{
                  shrink: true
                }}
                helperText={
                  isValidReportName === false
                    ? "Por favor no incluyas espacios"
                    : ""
                }
              />
            </Box>
            <Divider />
            <Box display="flex" justifyContent="center">
              <Button
                id="btn-cancelar"
                style={{ width: "350px", margin: "20px" }}
                color="secondary"
                onClick={() => handleCancel()}
              >
                Cancelar
              </Button>
              <Button
                id="btn-generarReporte"
                style={{ width: "350px", margin: "20px" }}
                color="primary"
                variant="contained"
                disabled={
                  isValidStartDate === true &&
                    isValidEndDate === true &&
                    reportType !== "" &&
                    isValidReportName === true
                    ? false
                    : true
                }
                onClick={() => handleSubmit()}
              >
                Generar reporte
              </Button>
            </Box>
          </Box>
        </CmtCard>
      </Box>
    </Dialog>
  );
};
export default DownloadReport;
