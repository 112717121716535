import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import UserTableHead from "./UserTableHead";
import UserTableToolbar from "./UserTableToolbar";
import { getComparator, stableSort } from "../../../@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getUsers,
  getLeaders,
  setCurrentUser
} from "../../../redux/actions/Users";
import ConfirmDialog from "../../../@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "../../../@jumbo/utils/commonHelper";
import { useHistory } from "react-router-dom";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";
import EditUser from "./AddEditUser/EditUser";
import Restricted from "services/permissionProvider/Restricted";
import { USUARIOS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import Forbidden from "@jumbo/components/Forbidden";
import CheckboxRoles from "../Roles/CheckboxRoles";
import AddUser from "./AddEditUser/AddUser";
import { useHasPrivilege } from "routes/checkPermission";

const UsersModule = () => {
  const classes = useStyles();
  const { users } = useSelector(({ usersReducer }) => usersReducer);
  const [orderBy, setOrderBy] = React.useState("first_name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editUserRoles, setEditUserRoles] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();
  let history = useHistory();
  const canDispatch = useHasPrivilege(USUARIOS_EDITAR);

  useEffect(() => {
    if (canDispatch) {
      dispatch(
        getUsers(filterOptions, debouncedSearchTerm, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setUsersFetched(true);
        })
      );
    }
  }, [dispatch, filterOptions, debouncedSearchTerm, canDispatch]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getLeaders());
    }
  }, [dispatch, canDispatch]);

  const activeUsers = users.filter(user => user.status === "ACTIVE");
  const onBoardingRequiredUsers = users.filter(
    user => user.status === "ONBOARDING_REQUIRED"
  );
  const suspendedUsers = users.filter(user => user.status === "SUSPENDED");
  const orderedActiveUsers = stableSort(
    activeUsers,
    getComparator(order, orderBy)
  );
  const orderedOnBoardingRequiredUsers = stableSort(
    onBoardingRequiredUsers,
    getComparator(order, orderBy)
  );
  const orderedSuspendedUsers = stableSort(
    suspendedUsers,
    getComparator(order, orderBy)
  );
  const concatUsers = orderedActiveUsers.concat(orderedOnBoardingRequiredUsers);
  const allUsers = concatUsers.concat(orderedSuspendedUsers);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    dispatch(setCurrentUser(user));
    history.push("/perfil/" + user.id);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleCloseEditUserDialog = () => {
    setOpenEditUserDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleUserEdit = user => {
    dispatch(setCurrentUser(user));
    setOpenEditUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteUser(selectedUser.id));
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleEditUserRoles = user => {
    dispatch(setCurrentUser(user));
    setEditUserRoles(true);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const filteredUsers = allUsers.filter(row => {
    let rows;
    if (searchTerm === "") {
      rows = row;
    } else if (
      row.first_name.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      rows = row;
    } else if (row.last_name.toLowerCase().includes(searchTerm.toLowerCase())) {
      rows = row;
    } else if (row.cuil.includes(searchTerm)) {
      rows = row;
    } else if (row.email.includes(searchTerm)) {
      rows = row;
    }
    return rows;
  });

  return (
    <Restricted to={USUARIOS_EDITAR} fallback={Forbidden}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <UserTableToolbar
            selected={selected}
            setSelected={setSelected}
            onUserAdd={setOpenUserDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="sticky enhanced table"
            >
              <UserTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users.length}
              />
              <TableBody>
                {!!allUsers.length ? (
                  filteredUsers.length > 0 ? (
                    filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <UserListRow
                          key={index}
                          row={row}
                          onRowClick={handleRowClick}
                          onUserEdit={handleUserEdit}
                          onUserDelete={handleUserDelete}
                          onUserView={handleUserView}
                          isSelected={isSelected}
                          onUserEditRoles={handleEditUserRoles}
                          users={users}
                        />
                      ))
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={7} rowSpan={10}>
                        <NoRecordFound>
                          No se encontraron usuarios usando ese filtro.
                        </NoRecordFound>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>
                          No se encontraron usuarios usando ese filtro.
                        </NoRecordFound>
                      ) : (
                        <NoRecordFound>
                          {usersFetched
                            ? "No se encontraron usuarios usando ese filtro."
                            : "Cargando usuarios..."}
                        </NoRecordFound>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage="Filas por página"
          />
        </Paper>

        {openUserDialog && (
          <AddUser
            open={openUserDialog}
            onCloseDialog={handleCloseUserDialog}
            users={users}
          />
        )}

        {openEditUserDialog && (
          <EditUser
            open={openEditUserDialog}
            onCloseDialog={handleCloseEditUserDialog}
            users={users}
          />
        )}

        {editUserRoles && (
          <CheckboxRoles
            editUserRoles={editUserRoles}
            setEditUserRoles={setEditUserRoles}
            users={users}
          />
        )}

        {openViewDialog && (
          <UserDetailView
            open={openViewDialog}
            onCloseDialog={handleCloseViewDialog}
          />
        )}

        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirmación de  borrado ${selectedUser.first_name +
            " " +
            selectedUser.last_name}`}
          content={"Estás seguro de querer borrar a este usuario?"}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </Restricted>
  );
};

export default UsersModule;
