import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Modal,
  styled,
  Tooltip,
  Typography,
  Zoom
} from "@material-ui/core";
import { Delete, Edit, ExpandMore } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import React from "react";
import {
  deleteUserActivity,
  setCurrentActivity
} from "redux/actions/UserActivities";
import GridContainer from "@jumbo/components/GridContainer";
import Restricted from "services/permissionProvider/Restricted";
import { NOTICIAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactPlayer from "react-player";

const MyModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const SingleNews = ({
  title,
  date,
  description,
  image,
  video,
  id,
  activity,
  handleEditNew,
  showAllNews
}) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const handleDeleteNews = () => {
    dispatch(
      deleteUserActivity(id, () => {
        setOpen(false);
        MySwal.fire(
          "¡Listo!",
          "La noticia se elimino correctamente",
          "success"
        );
      })
    );
  };

  return (
    <Box m={2}>
      <Card sx={{ maxwidth: 350 }}>
        <CardContent>
          <CardHeader
            avatar={
              <Avatar aria-label="cdt-pic">
                <img
                  src="https://media-exp1.licdn.com/dms/image/C4D0BAQE-f4fkYhjAtQ/company-logo_200_200/0/1556211617173?e=2147483647&v=beta&t=n2WH60sO4EFD_A1L3_9rFLX3iRm4hxfG4ZGTF9vx6Uw"
                  alt="CDT"
                />
              </Avatar>
            }
            action={
              <Box>
                <Restricted to={NOTICIAS_EDITAR}>
                  {!!showAllNews && (
                    <Tooltip title="Editar" TransitionComponent={Zoom}>
                      <IconButton
                        id="btn-editarNoticia"
                        aria-label="edit"
                        onClick={() => handleEditNew({ activity })}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Eliminar" TransitionComponent={Zoom}>
                    <IconButton
                      id="btn-eliminarNoticia"
                      aria-label="delete"
                      onClick={e => setOpen(true)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Restricted>
                <Tooltip title="Ver más" TransitionComponent={Zoom}>
                  <IconButton
                    id="btn-verNoticia"
                    aria-label="show-more"
                    target="_blank"
                    href={"/noticias/" + id}
                    onClick={() => dispatch(setCurrentActivity({ activity }))}
                  >
                    <ExpandMore />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            title={title}
            subheader={date}
          />

          {!!image && image.trim() !== "" ? (
            <CardMedia
              component="img"
              height="350px"
              maxwidth="345px"
              image={image}
              alt="something"
            />
          ) : !!video && video.trim() !== "" ? (
            <ReactPlayer
              url={video}
              controls={true}
              playing={false}
              width="100%"
            />
          ) : (
            <CardMedia
              component="img"
              height="350px"
              maxwidth="345px"
              src="/images/LogoCapitalHumano.jpg"
              alt="something"
            />
          )}

          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <MyModal
        open={open}
        onClose={e => setOpen(false)}
        aria-labelledby="modal-delete-news"
        aria-describedby="modal-message"
      >
        <Box
          width={300}
          height={140}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={3}
          borderRadius="10px"
          overflow="auto"
        >
          <Typography
            id="modal-message"
            variant="h6"
            component="p"
            style={{
              marginTop: "20px",
              fontSize: "15px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            ¿Desea eliminar esta noticia?
          </Typography>
          <GridContainer
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            <Grid item>
              <Button
                id="btn-eliminarNoticia"
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                onClick={handleDeleteNews}
              >
                eliminar
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="btn-cancelar"
                variant="contained"
                color="default"
                style={{ marginTop: "20px" }}
                onClick={e => setOpen(false)}
              >
                cancelar
              </Button>
            </Grid>
          </GridContainer>
        </Box>
      </MyModal>
    </Box>
  );
};

export default SingleNews;
