import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import LicenceListRow from "./LicenceListRow";
import LicenceTableHead from "./LicenceTableHead";
import LicenceTableToolbar from "./LicenceTableToolbar";
import { getComparator, stableSort } from "../../../@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLicence,
  getLicences,
  setCurrentLicence
} from "../../../redux/actions/Licences";
import AddEditLicence from "./AddEditLicence";
import ConfirmDialog from "../../../@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "../../../@jumbo/utils/commonHelper";
import useStyles from "./index.style";
import { useHistory } from "react-router-dom";
import LicenceDetailView from "./LicenceDetailView";
import NoRecordFound from "./NoRecordFound";
import Restricted from "services/permissionProvider/Restricted";
import { LICENCIAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import Forbidden from "@jumbo/components/Forbidden";
import SuspendedLicences from "./SuspendedLicences";
import { useHasPrivilege } from "routes/checkPermission";

const LicencesModule = () => {
  const classes = useStyles();
  const { licences } = useSelector(({ licencesReducer }) => licencesReducer);
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openLicenceDialog, setOpenLicenceDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedLicence, setSelectedLicence] = useState({ name: "" });
  const [licencesFetched, setLicencesFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewSuspendedLicences, setViewSuspendedLicences] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const canDispatch = useHasPrivilege(LICENCIAS_EDITAR);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    if (canDispatch) {
      dispatch(
        getLicences(filterOptions, debouncedSearchTerm, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setLicencesFetched(true);
        })
      );
    }
  }, [dispatch, filterOptions, debouncedSearchTerm, canDispatch]);

  const activeLicences = licences.filter(
    licence => licence.status === "ACTIVE"
  );

  const suspendedLicences = licences.filter(
    licence => licence.status === "SUSPENDED"
  );

  const orderedActive = stableSort(
    activeLicences,
    getComparator(order, orderBy)
  );

  const orderedSuspended = stableSort(
    suspendedLicences,
    getComparator(order, orderBy)
  );

  const handleCloseLicenceDialog = () => {
    setOpenLicenceDialog(false);
    dispatch(setCurrentLicence(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = licences.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLicenceView = licence => {
    dispatch(setCurrentLicence(licence));
    history.push("/licencias/" + licence.id);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentLicence(null));
  };

  const handleLicenceEdit = licence => {
    dispatch(setCurrentLicence(licence));
    setOpenLicenceDialog(true);
  };

  const handleLicenceDelete = licence => {
    setSelectedLicence(licence);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteLicence(selectedLicence.id));
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const filteredLicences = orderedActive.filter(row => {
    let rows;
    if (searchTerm === "") {
      rows = row;
    } else if (row.name !== "" && row.name !== null && row.name !== undefined) {
      if (row.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        rows = row;
      }
    }
    return rows;
  });

  return (
    <Restricted to={LICENCIAS_EDITAR} fallback={Forbidden}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <LicenceTableToolbar
            selected={selected}
            setSelected={setSelected}
            onLicenceAdd={setOpenLicenceDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="sticky enhanced table"
            >
              <LicenceTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={licences.length}
              />
              <TableBody>
                {!!filteredLicences.length ? (
                  filteredLicences
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <LicenceListRow
                        key={index}
                        row={row}
                        onRowClick={handleRowClick}
                        onLicenceEdit={handleLicenceEdit}
                        onLicenceDelete={handleLicenceDelete}
                        onLicenceView={handleLicenceView}
                        isSelected={isSelected}
                      />
                    ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>
                          No se encontraron licencias.
                        </NoRecordFound>
                      ) : (
                        <NoRecordFound>
                          {licencesFetched
                            ? "No se encontraron licencias."
                            : "Cargando licencias..."}
                        </NoRecordFound>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={activeLicences.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage="Filas por página"
          />
        </Paper>

        {openLicenceDialog && (
          <AddEditLicence
            open={openLicenceDialog}
            onCloseDialog={handleCloseLicenceDialog}
          />
        )}
        {openViewDialog && (
          <LicenceDetailView
            open={openViewDialog}
            onCloseDialog={handleCloseViewDialog}
          />
        )}

        <Button
          id="btn-licenciasSuspendidas"
          variant="outlined"
          onClick={() => setViewSuspendedLicences(true)}
        >
          Ver licencias suspendidas
        </Button>

        {viewSuspendedLicences && (
          <SuspendedLicences
            orderedSuspended={orderedSuspended}
            viewSuspendedLicences={viewSuspendedLicences}
            setViewSuspendedLicences={setViewSuspendedLicences}
          />
        )}

        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirmación de  borrado ${selectedLicence.name}`}
          content={"Estás seguro de querer borrar a esta licencia?"}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </Restricted>
  );
};

export default LicencesModule;
