import React from "react";
import usePermission from "./usePermission";

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted = ({to, fallback, children}) => {
  // We "connect" to the provider thanks to the PermissionContext
  const [ allowed ] = usePermission(to);

  // If the user has that permission, render the children
  if (allowed) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  // Otherwise, render the fallback
  return <React.Fragment>{fallback}</React.Fragment>;
};

export default Restricted;
