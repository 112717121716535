import React, { useCallback, useEffect, useState } from "react";
import TimetrackingTable from "../TimeTrackingTable";
import GridContainer from "@jumbo/components/GridContainer";
import { Button, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getAdminTimeTrackingItems,
  updateTimeTrackingItemAdmin,
  removeTimeTrackingItem
} from "../../../../redux/actions/TimeTrackingItems";
import Swal from "sweetalert2";
import Restricted from "../../../../services/permissionProvider/Restricted";
import Forbidden from "@jumbo/components/Forbidden";
import { HORAS_EDITAR } from "../../../../services/permissionProvider/PermissionsCatalog";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import AppSelectBox from "../../../../@jumbo/components/Common/formElements/AppSelectBox";
import { Box, Typography, MenuItem, TextField } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import withReactContent from "sweetalert2-react-content";
import Helper from "@jumbo/components/Helper";
import { getUserDetail, getLeaders } from "../../../../redux/actions/Users";
import { makeStyles } from "@material-ui/core";
import NoRecordFound from "./NoRecordFound";
import {
  getComparator,
  getComparatorHash,
  stableSort,
  stableSortHash
} from "@jumbo/utils/tableHelper";
import SearchBox from "@jumbo/components/AppLayout/VerticalLayouts/ModernSidebar/ActionSideBar/Search/SearchBox";
import DownloadReport from "./DownloadReport";
import CmtCard from "@coremat/CmtCard";
import CustomGroupRow from "./AdminTableStyled/CustomComponent";
import { useHasPrivilege } from "routes/checkPermission";

const useStyles = makeStyles(theme => ({
  root: {
    display: "unset",
    padding: "0px 0px 0px"
  },
  noRecordFound: {
    height: "calc(100%-30%)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 15
  },
  buttonStyle: {
    marginBottom: "30px",
    border: "2px solid #2681C4",
    height: "60px",
    width: "220px",
    transition: "all .2s ease-in-out",
    "&:hover": {
      boxShadow:
        "0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)",
      transform: "scale(1.1)"
    }
  },
  pagination1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5px",
    width: "100%"
  },
  pagination: {
    display: "flex",
    width: "98%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    border: "2px solid rgba(0, 0, 0, 0.12)"
  }
}));

export default function TimeTrackingAdminModule() {
  const canDispatch = useHasPrivilege(HORAS_EDITAR);
  const dispatch = useDispatch();
  const maxDate = moment(new Date())
    .add(1, "days")
    .format("yyyy-MM-DD");
  const minDate = moment(new Date())
    .subtract(1, "months")
    .format("yyyy-MM-DD");
  const [page, setPage] = useState(1);
  const { authUser } = useSelector(({ auth }) => auth);
  const { userDetail, leaders } = useSelector(
    ({ usersReducer }) => usersReducer
  );
  const [leaderError] = useState("");
  const [leaderId, setLeaderId] = useState("1");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpenCreateReport, setIsOpenCreateReport] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const classes = useStyles();
  const MySwal = withReactContent(Swal);
  const pageSize = 3;

  const trackables = userDetail
    ? userDetail.user.trackables.reduce(function (map, { id, name }) {
      map[id] = name;
      return map;
    }, {})
    : {};

  useEffect(() => {
    if (canDispatch) {
      dispatch(getUserDetail(authUser.id));
    }
  }, [dispatch, authUser, refresh, canDispatch]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getLeaders([], "", () => { }));
    }
  }, [dispatch, canDispatch]);

  const refreshTable = () => {
    setRefresh(!refresh);
  };

  const leaderFiltered = leaders.filter(
    leader => leader.status !== "SUSPENDED"
  );

  const validTimeTrackingItem = timeTrackingItem => {
    if (
      timeTrackingItem.itemDate === undefined ||
      timeTrackingItem.hours === undefined ||
      timeTrackingItem.trackable === undefined ||
      isNaN(timeTrackingItem.hours)
    ) {
      MySwal.fire({
        title: <strong>Datos Invalidos</strong>,
        html: <i>Por favor completá todos los campos</i>,
        icon: "error"
      });

      return false;
    }

    return true;
  };

  const mostrarMotivo = motivo => {
    return MySwal.fire("Motivo de rechazo", motivo);
  };

  const columns = [
    {
      field: "internal_action",
      render: rowData =>
        rowData.status === "Rechazadas" && rowData.rejectReason !== null ? (
          <Button
            id="btn-Motivo"
            onClick={() => mostrarMotivo(rowData.rejectReason)}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography>Ver motivo</Typography>
          </Button>
        ) : (
          <Box style={{ width: "80px" }}></Box>
        ),
      cellStyle: {
        width: "6%"
      },
      headerStyle: {
        display: "none"
      }
    },
    {
      title: "Fecha",
      field: "itemDate",
      type: "date",
      dateSetting: { locale: "es-ES" },
      defaultGroupOrder: 0,
      headerStyle: {
        display: "none"
      }
    },
    {
      title: "",
      field: "trackable.name",
      editable: "never",
      type: "string",
      render: rowData =>
        rowData.trackable.name.length > 25 ? (
          <span style={{ width: "100%" }}>
            {rowData.trackable.name.substring(0, 25) + "..."}
          </span>
        ) : (
          <span style={{ width: "100%" }}>{rowData.trackable.name}</span>
        ),
      headerStyle: {
        display: "none"
      },
      cellStyle: {
        width: "40%"
      }
    },
    {
      title: "",
      field: "hours",
      type: "numeric",
      align: "center",
      render: rowData => (
        <span
          style={{ display: "flex", justifyContent: "center", width: "100px" }}
        >
          Horas:{" " + rowData.hours}
        </span>
      ),
      cellStyle: {
        width: "9.5%"
      },
      headerStyle: {
        display: "none"
      }
    },
    {
      title: "",
      field: "minutes",
      type: "numeric",
      align: "center",
      render: rowData => (
        <span
          style={{ display: "flex", justifyContent: "center", width: "100px" }}
        >
          Minutos:{" " + rowData.minutes}
        </span>
      ),
      cellStyle: {
        width: "9.5%"
      },
      headerStyle: {
        display: "none"
      }
    },
    {
      title: "",
      field: "status",
      editable: "never",
      type: "string",
      render: rowData => (
        <span
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          Estado:{" " + rowData.status}
        </span>
      ),
      headerStyle: {
        display: "none"
      },
      cellStyle: {
        width: "30%"
      }
    }
  ];

  const statusTranslations = {
    APPROVED: "Aprobadas",
    REJECTED: "Rechazadas",
    CONFIRMED: "Confirmadas"
  };

  /* const translateStatus = item => {
    item.status = statusTranslations[item.status];
    return item;
  }; */

  const translateStatus = item => {
    // FUNCIONA
    switch (item.status) {
      case "APPROVED":
        item.status = "Aprobadas";
        return item;
      case "REJECTED":
        item.status = "Rechazadas";
        return item;
      case "CONFIRMED":
        item.status = "Confirmadas";
        return item;
      case "CREATED":
        item.status = "Creadas";
        return item;
      default:
        return item;
    }
  };

  const retrieveParams = useCallback(() => {
    let params = {};
    if (startDate !== "")
      params["start"] = moment(startDate).format("DD/MM/yyyy");
    if (endDate !== "") params["end"] = moment(endDate).format("DD/MM/yyyy");
    return params;
  }, [startDate, endDate]);

  const getKeyByValue = (obj, value) =>
    Object.keys(obj).find(key => obj[key] === value);

  const onUpdate = timeTrackingItem => {
    timeTrackingItem["user"] = { id: authUser.id };
    timeTrackingItem["status"] = getKeyByValue(
      statusTranslations,
      timeTrackingItem.status
    );

    if (validTimeTrackingItem(timeTrackingItem)) {
      timeTrackingItem["item_date"] = timeTrackingItem.itemDate;
      dispatch(
        updateTimeTrackingItemAdmin(
          timeTrackingItem,
          leaderId,
          retrieveParams(),
          () => {
            getAdminTimeTrackingItems(leaderId, retrieveParams(), "", () => { });
          }
        )
      );
      refreshTable();
    }
  };

  useEffect(() => {
    dispatch(
      getAdminTimeTrackingItems(leaderId, retrieveParams(), "", () => { })
    );
  }, [leaderId, startDate, endDate, dispatch, retrieveParams]);

  const onRemove = id => {
    if (false) {
      //validate here
    } else {
      dispatch(
        removeTimeTrackingItem(id, leaderId, retrieveParams(), () => { })
      );
      refreshTable();
    }
  };

  const handleChangePage = (_, pageNumber) => {
    setPage(pageNumber);
  };

  const editable = {
    onRowUpdate: (newData, oldData) =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          onUpdate(newData);
        }, 800);
        refreshTable();
      }),
    onRowDelete: oldData =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          onRemove(oldData.id);
        }, 600);
        refreshTable();
      })
  };

  const colorMode = sessionStorage.getItem("colorMode");

  const rowBackgroundColors = {
    Aprobadas: "rgb(174,241,149)",
    Rechazadas: "rgb(255,164,164)"
  };

  const rowBackgroundColorsDarkMode = {
    Aprobadas: "#3fa641",
    Rechazadas: "#eb4034"
  };

  const orderedLeader = stableSort(
    leaderFiltered,
    getComparator("asc", "first_name")
  );

  const options = {
    rowStyle: rowData => {
      return {
        backgroundColor:
          colorMode !== "dark" && rowBackgroundColors[rowData.status],
        border:
          colorMode === "dark" &&
          `6px solid ${rowBackgroundColorsDarkMode[rowData.status]}`
      };
    },
    actionsColumnIndex: 5,
    overflow: "hidden"
  };

  const { adminTimeTrackingItems } = useSelector(
    ({ timeTrackingItemsReducer }) => timeTrackingItemsReducer
  );

  const customRow = rowData => {
    // console.log(rowData)
    return (
      <tr id="tr-fecha">
        <td colSpan="7">
          <table style={{ width: "100%", overflow: "hidden" }}>
            <CustomGroupRow {...rowData} />
          </table>
        </td>
      </tr>
    );
  };

  const getComponents = (myHashMap, pageNumber) => {
    const comps = [];

    if (myHashMap && trackables && Object.keys(myHashMap).length > 0) {
      const myHashMapValues = Object.values(myHashMap);

      const hashMap = myHashMapValues
        .map(item => item.filter(hour => hour.status !== "CREATED"))
        .filter(item => item.length > 0);
      const users = stableSortHash(hashMap, getComparatorHash());

      const orderedUsers = users.filter(row => {
        let rows;
        if (searchTerm === "") {
          rows = row;
        } else if (
          row[0].user.first_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          rows = row;
        } else if (
          row[0].user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          rows = row;
        }
        return rows;
      });

      const firstElement = (pageNumber - 1) * pageSize;
      const lastElement = firstElement + pageSize;

      orderedUsers.slice(firstElement, lastElement).map(item =>
        comps.push(
          <Grid item xs={12} md={12} xl={12} key={item.key}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={`opcion-${item[0].user.first_name +
                  " " +
                  item[0].user.last_name}`}
                style={{
                  marginTop: "15px",
                  border: "2px solid #2681C4"
                }}
              >
                <Typography>
                  {item[0].user.first_name + " " + item[0].user.last_name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={classes} id="ACORDION-DETAILS">
                <TimetrackingTable
                  editable={editable}
                  columns={columns}
                  title={"Aprobación de Horas:   " + item[0].user.email}
                  timeTrackingItems={item.map(a => translateStatus(a))}
                  options={options}
                  components={{
                    GroupRow: rowData => customRow(rowData)
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )
      );
      comps.push(
        <Box className={classes.pagination1} key="pagination">
          <Box className={classes.pagination}>
            <Box
              key="pagination"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Pagination
                count={Math.ceil(orderedUsers.length / pageSize)}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      );
    } else {
      comps.push(
        <NoRecordFound key={"norecordfound"} styling={classes.noRecordFound}>
          <Typography>
            No hay registros que cumplan con esos filtros.
          </Typography>
          <Typography>
            Cambia los valores en los filtros para obtener resultados.
          </Typography>
        </NoRecordFound>
      );
    }
    return comps;
  };

  return (
    <Restricted to={HORAS_EDITAR} fallback={Forbidden}>
      <GridContainer>
        <Grid item style={{ width: "100%" }}>
          <Helper
            message={
              <div>
                <p>
                  En esta sección aparecen los usuarios y sus horas confirmadas.
                </p>
                <p>Primero filtrá por lider y luego por fechas.</p>
                <p>Como admin podés editar o eliminar cualquier registro.</p>
              </div>
            }
          />
        </Grid>

        <CmtCard
          container="true"
          display="flex"
          flexdirection="column"
          style={{
            width: "100%",
            padding: "20px",
            borderRadius: "15px",
            marginBottom: "20px"
          }}
        >
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            marginbottom="20px"
          >
            <Button
              id="btn-asignarHoras"
              className={classes.buttonStyle}
              onClick={() => window.open("/admin-asignar-horas", "_blank")}
            >
              Asignar horas
            </Button>
            <Button
              id="btn-verReportes"
              className={classes.buttonStyle}
              onClick={() =>
                window.open(
                  "https://drive.google.com/drive/folders/1S3YqJNi1miSN1F_ynuQM7RARMLCwgPBD",
                  "_blank"
                )
              }
            >
              Ver reportes
            </Button>
            <Button
              id="btn-crearReportes"
              className={classes.buttonStyle}
              onClick={() => setIsOpenCreateReport(true)}
            >
              Crear reportes
            </Button>
            {/* <Button
              className={classes.buttonStyle}
              onClick={() => setIsOpenCreateSignature(true)}
            >
              Crear firmas
            </Button> */}
          </Grid>

          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={3} md={3} xl={4}>
              <Box>
                <AppSelectBox
                  id="sel-lider"
                  style={{ padding: "8px" }}
                  fullWidth
                  variant="outlined"
                  data={orderedLeader}
                  value={leaderId}
                  renderRow={(item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      id={`opcion-${item.first_name + " " + item.last_name}`}
                    >
                      {item.first_name + " " + item.last_name}
                    </MenuItem>
                  )}
                  valueKey="id"
                  label="Líder"
                  onChange={e => {
                    setLeaderId(e.target.value);
                  }}
                  helperText={leaderError}
                />
              </Box>
            </Grid>
            <Grid item xs={3} md={3} xl={4}>
              <TextField
                id="btn-fechaInicio"
                type="date"
                name="start_date"
                label="Fecha de inicio"
                variant="outlined"
                fullWidth
                defaultValue={minDate}
                onChange={e => {
                  setStartDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} xl={4}>
              <TextField
                id="btn-fechaFin"
                type="date"
                name="end_date"
                label="Fecha de fin"
                variant="outlined"
                fullWidth
                defaultValue={maxDate}
                onChange={e => {
                  setEndDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </CmtCard>

        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignContent="center"
          marginTop="20px"
        >
          <Box width="100%">
            <SearchBox
              id="txt-buscar"
              placeholder="Buscar..."
              onSearch={e => setSearchTerm(e.target.value)}
            />
          </Box>
        </Box>
        <DownloadReport
          isOpenCreateReport={isOpenCreateReport}
          setIsOpenCreateReport={setIsOpenCreateReport}
          userName={authUser && authUser.first_name + " " + authUser.last_name}
        />
        {/* <DownloadSignature
          isOpenCreateSignature={isOpenCreateSignature}
          setIsOpenCreateSignature={setIsOpenCreateSignature}
        /> */}
        {getComponents(adminTimeTrackingItems, page)}
      </GridContainer>
    </Restricted>
  );
}
