import React from "react";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import GridContainer from "@jumbo/components/GridContainer";
import CmtAvatar from "@coremat/CmtAvatar";
import Button from "@material-ui/core/Button";
import { useDropzone } from "react-dropzone";

const PersonalDetails = ({
  nextStep,
  handleChange,
  values,
  classes,
  handlePic,
  uploadedPic
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      handlePic(acceptedFiles[0]);
    }
  });
  return (
    <Box>
      <Typography className={classes.instructions}>Info Personal</Typography>
      <GridContainer style={{ marginBottom: 10 }}>
        <Grid item xs={4} md={4} xl={4}>
          <Box
            {...getRootProps()}
            mr={{ xs: 0, md: 5 }}
            mb={{ xs: 3, md: 0 }}
            className="pointer"
          >
            <input {...getInputProps()} />
            <CmtAvatar
              size={70}
              src={uploadedPic || values.profilePic}
              id="img-editarImagenPerfil"
            />
          </Box>
        </Grid>
        <Grid item xs={8} md={8} xl={8}>
          <TextField
            disabled={true}
            placeholder="Nombre"
            label="Nombre"
            onChange={handleChange("firstName")}
            defaultValue={values.firstName}
          />
          <TextField
            disabled={true}
            placeholder="Apellido"
            label="Apellido"
            onChange={handleChange("lastName")}
            defaultValue={values.lastName}
          />
          <TextField
            id="txt-residencia"
            placeholder="Lugar de residencia"
            label="Residencia"
            onChange={handleChange("residence")}
            defaultValue={values.residence}
          />
        </Grid>
      </GridContainer>
      <Box>
        <Button
          id="btn-siguiente"
          variant="contained"
          color="primary"
          onClick={nextStep}
          className={classes.button}
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  );
};

export default PersonalDetails;
