import {fetchError, fetchStart, fetchSuccess} from "./Common";
import axios from "axios";
import {
  ADD_ROLE,
  DELETE_BULK_ROLES,
  DELETE_ROLE,
  GET_ROLE,
  EDIT_ROLE,
  GET_ROLES,
  SET_ROLE_DETAILS,
  ADD_ROLE_TO_USER,
  ADD_PRIVILEGE_TO_ROLE,
  DELETE_PRIVILEGE_FROM_ROLE,
  ROLE_ALERT
} from "../../@jumbo/constants/ActionTypes";
import {getUsers, setCurrentUser} from "./Users";

const roleAlert = message => {
  return dispatch => {
    dispatch({
      type: ROLE_ALERT,
      payload: message
    });
  };
};

export const getRoles = (filterOptions = [], searchTerm = "", callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/roles`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_ROLES, payload: data.data});
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getRole = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/roles/${id}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_ROLE, payload: data.data});
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const setCurrentRole = role => {
  return dispatch => {
    dispatch({type: SET_ROLE_DETAILS, payload: role});
  };
};

export const addNewRole = (role, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/roles`, role)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New role was added successfully."));
          dispatch({type: ADD_ROLE, payload: data.data});
          dispatch(getRoles());
          dispatch(roleAlert("El Rol fue creado con éxito"));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(roleAlert("Hubo un error creando el rol"));
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateRole = (role, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/api/roles/${role.id}`, role)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected role was updated successfully."));
          dispatch({type: EDIT_ROLE, payload: data.data});
          dispatch(getRoles());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateRoleStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/roles/update-status", data)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess("Role status was updated successfully."));
          dispatch({type: EDIT_ROLE, payload: response.data});
          dispatch(getRoles());
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteBulkRoles = (roleIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/roles/bulk-delete", {roleIds})
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess("Selected roles were deleted successfully."));
          dispatch({type: DELETE_BULK_ROLES, payload: roleIds});
          dispatch(getRoles());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteRole = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT}/api/roles/${id}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected role was deleted successfully."));
          dispatch(getRoles());
          dispatch(roleAlert("El rol se ha eliminado correctamente"));
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(roleAlert("Hubo un error eliminando el rol"));
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const addRoleToUser = (userId, roleId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        `${process.env
          .REACT_APP_API_ENDPOINT}/api/users/${userId}/roles/${roleId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New role was added successfully."));
          dispatch({type: ADD_ROLE_TO_USER, payload: data.data});
          dispatch(getUsers());
          dispatch(setCurrentUser(data.data));
          dispatch(roleAlert("Rol agregado con éxito"));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(roleAlert("Hubo un error agregando el rol"));
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteRoleFromUser = (userId, roleId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(
        `${process.env
          .REACT_APP_API_ENDPOINT}/api/users/${userId}/roles/${roleId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Role was deleted successfully."));
          dispatch({type: DELETE_ROLE, payload: data.data});
          dispatch(getUsers());
          dispatch(setCurrentUser(data.data));
          dispatch(roleAlert("Rol eliminado con éxito"));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(roleAlert("Hubo un error eliminando el rol"));
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const addPrivilegeToRole = (roleId, privilegeId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        `${process.env
          .REACT_APP_API_ENDPOINT}/api/roles/${roleId}/privileges/${privilegeId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Role was added successfully."));
          dispatch({type: ADD_PRIVILEGE_TO_ROLE, payload: data.data});
          dispatch(getRoles());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deletePrivilegeFromRole = (roleId, privilegeId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(
        `${process.env
          .REACT_APP_API_ENDPOINT}/api/roles/${roleId}/privileges/${privilegeId}`
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Role was deleted successfully."));
          dispatch({type: DELETE_PRIVILEGE_FROM_ROLE, payload: data.data});
          dispatch(getRoles());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};
