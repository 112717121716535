import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";
import {
  ADD_PROJECT,
  DELETE_BULK_PROJECTS,
  DELETE_PROJECT,
  GET_PROJECT,
  EDIT_PROJECT,
  GET_PROJECTS,
  SET_PROJECT_DETAILS,
  GET_GESTORES
} from "../../@jumbo/constants/ActionTypes";

export const getProjects = (
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/projects`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PROJECTS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("Error en la respuesta de proyectos del servidor")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getProject = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/projects/${id}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PROJECT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const setCurrentProject = project => {
  return dispatch => {
    dispatch({ type: SET_PROJECT_DETAILS, payload: project });
  };
};

export const addNewProject = (project, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/projects`, project)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New project was added successfully."));
          dispatch({ type: ADD_PROJECT, payload: data.data });
          dispatch(getProjects());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateProject = (project, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/projects/${project.id}`,
        project
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected project was updated successfully."));
          dispatch({ type: EDIT_PROJECT, payload: data.data });
          dispatch(getProjects());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateProjectStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/projects/update-status", data)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess("Project status was updated successfully."));
          dispatch({ type: EDIT_PROJECT, payload: response.data });
          dispatch(getProjects());
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteBulkProjects = (projectIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/projects/bulk-delete", { projectIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(
            fetchSuccess("Selected projects were deleted successfully.")
          );
          dispatch({ type: DELETE_BULK_PROJECTS, payload: projectIds });
          dispatch(getProjects());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteProject = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT}/api/projects/${id}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected project was deleted successfully."));
          dispatch({ type: DELETE_PROJECT, payload: id });
          dispatch(getProjects());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getGestores = callbackFun => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/approvers`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_GESTORES, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};
