import React from "react";
import CmtCard from "@coremat/CmtCard";
import { Typography } from "@material-ui/core";
import CmtAvatarGroup from "@coremat/CmtAvatarGroup";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import Box from "@material-ui/core/Box";
import ComputerOutlinedIcon from "@material-ui/icons/ComputerOutlined";

import PropTypes from "prop-types";
import { alpha, makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles(theme => ({
  iconView: {
    backgroundColor: alpha(green["500"], 0.1),
    color: green["500"],
    padding: 8,
    borderRadius: 4,
    "& .MuiSvgIcon-root": {
      display: "block"
    },
    "&.web": {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main
    },
    "&.phone": {
      backgroundColor: alpha(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark
    }
  }
}));

const Projects = ({ userDetail }) => {
  const classes = useStyles();

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader title="Proyectos" />
      <CmtCardContent>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <ComputerOutlinedIcon />
          </Box>
          <Box ml={5}>
            <CmtAvatarGroup
              items={userDetail.user.trackables
                .filter(t => t.type === "PROJECT")
                .filter(project => project.status === "ACTIVE")}
              srcKey="profile_pic"
              size="large"
              spacing={1}
              max={5}
              titleKey="name"
              renderItemSummary={item => (
                <Typography color="inherit" style={{ fontSize: 10 }}>
                  {item.name}
                </Typography>
              )}
            />
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Projects;

Projects.prototype = {
  userDetail: PropTypes.object.isRequired
};
