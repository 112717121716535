import { useEffect, useRef } from "react";
import { useState } from "react";

import React from "react";

export default function Instrucciones({ setHeight, tutorialRef }) {

    const [instruccionesMostradas, setInstruccionesMostradas] = useState([]);

    const instructionRefs = useRef([]);

    useEffect(() => {

        const instrucciones = [
            {
                header: 'Accedé a la configuración:',
                info: 'Hacé click en el ícono del engranaje <i class = "fa-solid fa-gear"></i> en la esquina superior derecha y seleccioná "Ver todos los ajustes".'
            },
            {
                header: 'Agregá tu firma:',
                info: 'En la pestaña "Firma" hace click en "Crear", asignale un nombre y pegá la firma que copiaste.'
            },
            {
                header: 'Guardá los cambios:',
                info: 'Desplazate hacia abajo y hacé click en "Guardar cambios"'
            },
            {
                info: '¡Listo! Ahora tu firma corporativa va a estar en todos tus correos enviados.'
            }
        ]

        setInstruccionesMostradas(instrucciones);
    }, []);

    useEffect(() => {
        setHeight(`${tutorialRef.current.scrollHeight}px`)

        // Agregamos la clase "show-instruction" con un delay de 200ms
        if (instruccionesMostradas.length > 0) {
            instruccionesMostradas.forEach((instruccion, index) => {
                setTimeout(() => {
                    if (instructionRefs.current[index]) {
                        instructionRefs.current[index].classList.add('show-instruction');
                    }
                }, 700/instruccionesMostradas.length * index);
            });
        }

    }, [instruccionesMostradas, tutorialRef, setHeight])

    return (
        <>
            {instruccionesMostradas.map((instruccion, instruccionIndex) =>
                <li key={instruccionIndex} className={instruccion.className} ref={el => instructionRefs.current[instruccionIndex] = el}>
                    {instruccion.header ? <h2>{instruccion.header}</h2> : ""}
                    <p className={instruccion.header ? "" : "spaced-info"} dangerouslySetInnerHTML={{ __html: instruccion.info }} />
                </li>)}
        </>
    )
}