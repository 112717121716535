import React from "react";
import Fab from "@material-ui/core/Fab";
import { WhatsApp } from "@material-ui/icons";
import { Grid, Tooltip, Zoom } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function TLeoWhatsApp() {
  return (
    <Grid
      item
      style={{
        margin: "0px",
        top: "auto",
        right: "20px",
        bottom: "20px",
        left: "auto",
        position: "fixed"
      }}
    >
      <Link to={{ pathname: "https://walink.co/b821cc" }} target="_blank">
        <Tooltip title="TLeo" TransitionComponent={Zoom}>
          <Fab
            aria-label="whatsapp-cdt"
            size="large"
            style={{ backgroundColor: "#57D640" }}
          >
            <WhatsApp
              style={{ color: "white", width: "120px", height: "40px" }}
            />
          </Fab>
        </Tooltip>
      </Link>
    </Grid>
  );
}
