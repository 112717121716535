import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { HORAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import Restricted from "services/permissionProvider/Restricted";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Autocomplete } from "@material-ui/lab";
import { addAdminTimeTrackingItem } from "redux/actions/TimeTrackingItems";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#f44336"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f44336"
    }
  }
}));

const AddOneDay = ({
  usuarios,
  user,
  setUser,
  tipoAsignacion,
  proyectos,
  licencias,
  capacitaciones,
  estado,
  clearInputs
}) => {
  const MySwal = withReactContent(Swal);
  const [isValidFechaInicio, setIsValidFechaInicio] = useState("");
  const [isValidFechaFin, setIsValidFechaFin] = useState("");
  const [isValidHoras, setIsValidHoras] = useState("");
  const [isValidMinutos, setIsValidMinutos] = useState("");
  const [isValidUser, setIsValidUser] = useState("");
  const [isValidAsignacion, setIsValidAsignacion] = useState("");
  const [asignacion, setAsignacion] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [horas, setHoras] = useState("");
  const [minutos, setMinutos] = useState("");
  const [claseHoras, setClaseHoras] = useState("");
  const [claseMinutos, setClaseMinutos] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    setAsignacion("");
  }, [tipoAsignacion, user]);

  useEffect(() => {
    if (isValidHoras) {
      setClaseHoras("");
    } else {
      setClaseHoras(classes.root);
    }
  }, [isValidHoras, classes.root]);

  useEffect(() => {
    if (isValidMinutos) {
      setClaseMinutos("");
    } else {
      setClaseMinutos(classes.root);
    }
  }, [isValidMinutos, classes.root]);

  const fechaInicioEsValida = date => {
    if (date === "" || date === null) {
      setIsValidFechaInicio(false);
    } else {
      setIsValidFechaInicio(true);
      setFechaInicio(date);
    }
  };

  const validarHoras = hours => {
    setHoras(hours);
    if (hours <= 8 && hours >= 1) {
      setIsValidHoras(true);
    } else {
      setIsValidHoras(false);
    }
  };

  const validarMinutos = minutes => {
    setMinutos(minutes);
    if (minutes !== "" && minutes <= 59 && minutes >= 0) {
      setIsValidMinutos(true);
    } else {
      setIsValidMinutos(false);
    }
  };

  const handleChange = (e, name) => {
    setIsValidFechaFin(true);
    switch (name) {
      case "Usuario":
        if (e !== null) {
          setUser(e);
          setIsValidUser(true);
        } else {
          setUser("");
          setIsValidUser(false);
        }
        break;
      case "Asignacion":
        if (e !== null) {
          setAsignacion(e);
          setIsValidAsignacion(true);
        } else {
          setAsignacion("");
          setIsValidAsignacion(false);
        }
        break;
      case "FechaInicio":
        fechaInicioEsValida(e);
        break;
      case "Horas":
        validarHoras(e.target.value);
        break;
      case "Minutos":
        validarMinutos(e.target.value);
        break;
      default:
        setIsValidFechaInicio(false);
    }
  };

  const showState = () => {
    switch (estado) {
      case "APPROVED":
        return "Aprobadas";
      case "CONFIRMED":
        return "Pendientes de Aprobación";
      default:
        return "";
    }
  };

  const openPopup = () => {
    const userName = user.first_name + " " + user.last_name;
    MySwal.fire({
      title: <strong>Por favor, confirma que los datos sean correctos</strong>,
      html: (
        <Typography>
          Usuario: {userName} <br />
          {tipoAsignacion}: {asignacion.name} <br />
          Fecha: {moment(fechaInicio).format("DD/MM/YYYY")}
          <br />
          Horas: {horas > 9 ? horas : 0 + horas}:
          {minutos > 9 ? minutos : 0 + minutos} <br />
          Estado: {showState()}
        </Typography>
      ),
      confirmButtonText: "Aceptar",
      showCancelButton: true,
      cancelButtonText: "Editar"
    }).then(result => {
      if (result.isConfirmed) {
        onSaveTimeTrackingItems();
      }
    });
  };

  const onSaveTimeTrackingItems = () => {
    let currentDate = moment(fechaInicio);
    const timeTrackingBody = {
      user: { id: user.id },
      item_date: currentDate.toISOString(),
      status: estado,
      trackable: { id: asignacion.id },
      hours: horas,
      minutes: minutos
    };
    dispatch(
      addAdminTimeTrackingItem(timeTrackingBody, () => {
        MySwal.fire("¡Listo!", "", "success");
      })
    );
    clearInputs();
  };

  const getOptions = () => {
    switch (tipoAsignacion) {
      case "Proyecto":
        return proyectos;
      case "Licencia":
        return licencias;
      case "Capacitación":
        return capacitaciones;
      default:
        return proyectos;
    }
  };

  return (
    <Restricted to={HORAS_EDITAR}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignContent: "center"
        }}
      >
        <Box
          style={{
            display: "flex",
            width: "1000px",
            justifyContent: "space-between",
            alignContent: "center",
            marginTop: "20px",
            marginBottom: "20px"
          }}
        >
          <Box display="block" width="300px">
            <Autocomplete
              id="sel-usuario"
              disableClearable
              getOptionSelected={(userValue, value) => userValue === value}
              onChange={(e, getOptionSelected) => {
                handleChange(getOptionSelected, "Usuario");
              }}
              sx={{ width: 300 }}
              options={usuarios}
              getOptionLabel={option =>
                option.first_name + " " + option.last_name
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Usuario"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
              noOptionsText="No hay opciones disponibles"
            />
          </Box>

          <Box display="block" width="300px">
            <Autocomplete
              id="sel-proyecto"
              disableClearable
              value={asignacion}
              style={{ width: "300px" }}
              getOptionSelected={(asignacionValue, value) =>
                asignacionValue === value
              }
              onChange={(e, getOptionSelected) => {
                handleChange(getOptionSelected, "Asignacion");
              }}
              sx={{ width: 300 }}
              options={getOptions()}
              autoHighlight
              getOptionLabel={option => option.name}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <div>{option.name}</div>
                </React.Fragment>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label={tipoAsignacion}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
              noOptionsText="No hay opciones disponibles"
            />
          </Box>

          <Box display="block" width="300px">
            <TextField
              id="fechaInicio"
              InputLabelProps={{
                shrink: true
              }}
              style={{ width: "300px" }}
              label="Fecha"
              variant="outlined"
              type="date"
              locale="es-ES"
              format="DD/MM/YYYY"
              value={fechaInicio}
              helperText={
                isValidFechaInicio !== false ? "" : "Debes completar este campo"
              }
              onChange={e => handleChange(e.target.value, "FechaInicio")}
            />
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            width: "1000px",
            justifyContent: "space-between",
            alignContent: "center",
            marginTop: "20px",
            marginBottom: "20px"
          }}
        >
          <Box display="block">
            <TextField
              id="txt-horas"
              label="Horas"
              variant="outlined"
              className={claseHoras}
              style={{ width: "300px" }}
              type="number"
              value={horas}
              onChange={e => handleChange(e, "Horas")}
              helperText={isValidHoras !== false ? "" : "Entre 1 y 8"}
            />
          </Box>

          <Box display="block">
            <TextField
              id="txt-minutos"
              label="Minutos"
              variant="outlined"
              className={claseMinutos}
              style={{ width: "300px" }}
              type="number"
              value={minutos}
              onChange={e => handleChange(e, "Minutos")}
              helperText={isValidMinutos !== false ? "" : "Entre 0 y 59"}
            />
          </Box>

          <Box style={{ width: "300px" }}></Box>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "20px"
          }}
        >
          <Divider />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: "1000px"
            }}
          >
            <Button
              style={{
                width: "300px",
                marginTop: "40px",
                marginBottom: "40px",
                padding: "10px",
                borderRadius: "16px"
              }}
              id="btn-guardarCambios"
              variant="contained"
              color="primary"
              disabled={
                isValidUser &&
                isValidAsignacion &&
                isValidFechaInicio &&
                isValidFechaFin &&
                isValidHoras &&
                isValidMinutos &&
                tipoAsignacion !== "" &&
                estado !== ""
                  ? false
                  : true
              }
              onClick={() => openPopup()}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </Box>
    </Restricted>
  );
};
export default AddOneDay;
