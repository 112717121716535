import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateProject } from "../../../../redux/actions/Projects";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";

const SuspendedProjects = ({
  orderedSuspended,
  viewSuspendedProjects,
  setViewSuspendedProjects
}) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [projectId, setProjectId] = useState("");

  const onProjectActivate = id => {
    dispatch(
      updateProject({ id: projectId, status: "ACTIVE" }, () => {
        setOpenDialog(false);
      })
    );
  };

  const handleCancelActivateProject = () => {
    setOpenDialog(false);
  };

  const handleSetProjectId = id => {
    setOpenDialog(true);
    setProjectId(id);
  };

  return (
    <Dialog open={viewSuspendedProjects}>
      <DialogContent dividers>
        <Box
          mb={{ xs: 6, md: 5 }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width="400px"
        >
          {!!orderedSuspended.length ? (
            orderedSuspended.map(project => (
              <Box mb={{ xs: 6, md: 5 }} key={project.id}>
                <Box
                  m={4}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>{project.name}</Typography>
                  <Button
                    id="btn-activarProyecto"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleSetProjectId(project.id)}
                  >
                    Activar
                  </Button>
                </Box>
                <Divider />
              </Box>
            ))
          ) : (
            <Box m={4}>
              <Typography>No se encontraron proyectos</Typography>
            </Box>
          )}
          <Button
            id="btn-cerrarPopUp"
            variant="outlined"
            color="primary"
            onClick={() => setViewSuspendedProjects(false)}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
      <ConfirmDialog
        title="Activar proyecto"
        content="¿Estas seguro de querer activar este proyecto?"
        open={openDialog}
        onConfirm={onProjectActivate}
        onClose={handleCancelActivateProject}
      />
    </Dialog>
  );
};

export default React.memo(SuspendedProjects);
