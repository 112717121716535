import React from "react";
import { Box, CardMedia } from "@material-ui/core";
import CmtTimeLine from "../../../../@coremat/CmtTimeLine";
import CmtTimeLineItem from "../../../../@coremat/CmtTimeLine/CmtTimeLineItem";
import CmtTimelineContent from "../../../../@coremat/CmtTimeLine/CmtTimelineContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import CmtAvatar from "@coremat/CmtAvatar";
import ReactPlayer from "react-player";

const useStyles = makeStyles(theme => ({
  timelineView: {
    position: "relative",
    "& .Cmt-timeline-item": {
      paddingLeft: 64,
      paddingBottom: "15px",
      "&:before": {
        left: 20
      },
      "&:first-child:before, &:last-child:before": {
        borderLeftStyle: "solid"
      },
      "& .Cmt-timeline-badge": {
        transition: "all 0.3s ease",
        transform: "scale(1)",
        marginTop: "30px"
      },
      "&:hover": {
        "& .Cmt-timeline-badge": {
          boxShadow: "0 4px 8px rgba(0,0,0,.45)",
          transform: "scale(1.2)"
        }
      }
    },
    "& .Cmt-timeline-badge": {
      top: 0,
      width: 40,
      height: 40
    }
  },
  titleRoot: {
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0.5,
    marginBottom: 4,
    cursor: "pointer"
  },
  subTitleRoot: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary
  },
  dateRoot: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.disabled,
    textAlign: "right"
  },
  image: {
    height: "100px",
    width: "100px",
    marginRight: "10px",
    border: "1px solid #BCBCBC"
  }
}));

const colors = {
  LAUNCH: "#836AFF",
  WORKSHOP: "#246840",
  EVENT: "#3f51b5",
  BIRTHDAY: "#FF8C00"
};

const icons = {
  LAUNCH: (
    <CmtAvatar
      alt="Alerta"
      src="../images/icons/IconImages/alerta.jpg"
      style={{ color: "#FFFFFF" }}
    />
  ),
  WORKSHOP: (
    <CmtAvatar
      alt="Comunicado"
      src="../images/icons/IconImages/comunicado.jpg"
      style={{ color: "#FFFFFF" }}
    />
  ),
  EVENT: (
    <CmtAvatar
      alt="Evento"
      src="../images/icons/IconImages/evento.jpg"
      style={{ color: "#FFFFFF" }}
    />
  ),
  BIRTHDAY: (
    <CmtAvatar
      alt="Cumpleaños"
      src="../images/icons/IconImages/cumpleaños.jpg"
      style={{ color: "#FFFFFF" }}
    />
  ),
  TRAINING: (
    <CmtAvatar
      alt="Capacitaciones"
      src="../images/icons/IconImages/capacitaciones.jpg"
      style={{ color: "#FFFFFF" }}
    />
  ),
  READING: (
    <CmtAvatar
      alt="Lectura"
      src="../images/icons/IconImages/lectura.jpg"
      style={{ color: "#FFFFFF" }}
    />
  )
};

const ActivitiesTimeLine = ({ data }) => {
  const history = useHistory();
  const classes = useStyles();

  //console.log(data);
  return (
    <Box className={classes.timelineView}>
      <CmtTimeLine align={"left"}>
        {data.map((item, index) => (
          <CmtTimeLineItem
            key={index}
            point={icons[item.type]}
            pointColor={colors[item.type]}
            content={
              <CmtTimelineContent isWrapper={false}>
                <Box
                  display="flex"
                  onClick={() => history.push("/noticias/" + item.id)}
                >
                  <Box
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between"
                    }}
                  >
                    <Box style={{ marginTop: "20px" }}>
                      <Box
                        component="span"
                        className={classes.dateRoot}
                        ml="auto"
                      >
                        {item.publish_date
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </Box>
                      <Typography
                        className={classes.titleRoot}
                        component="div"
                        variant="h4"
                        mt={0}
                      >
                        {item.title.length > 43
                          ? item.title.substring(0, 43) + "..."
                          : item.title}
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.subTitleRoot}
                        mt={0}
                      >
                        {item.description.length > 43
                          ? item.description.substring(0, 43) + "..."
                          : item.description}
                      </Typography>
                    </Box>

                    {/*   {item.image_url !== "" ? (
                      <Box display="flex" justifyContent="center">
                        <CardMedia
                          className={classes.image}
                          component="img"
                          src={item.image_url}
                          alt="something"
                        />
                      </Box>
                    ) : (
                      item.video_url !== "" &&
                        item.video_url !== null ? (
                        <Box display="flex" justifyContent="center" className={classes.video}>
                          <ReactPlayer
                            light={true}
                            controls={false}
                            playIcon={false}
                            url={item.video_url}
                          />
                        </Box>
                      ) : (
                        <Box display="flex" justifyContent="center">
                          <CardMedia
                            className={classes.image}
                            component="img"
                            src="/images/LogoCapitalHumano.jpg"
                            alt="something"
                          />
                        </Box>
                      )} */}

                    {item.video_url !== "" && item.video_url !== null ? (
                      <Box display="flex" justifyContent="center">
                        <ReactPlayer
                          light={true}
                          controls={false}
                          playIcon={false}
                          url={item.video_url}
                          width="120px"
                          height="80px"
                        />
                      </Box>
                    ) : item.image_url !== "" ? (
                      <Box display="flex" justifyContent="center">
                        <CardMedia
                          className={classes.image}
                          component="img"
                          src={item.image_url}
                          alt="something"
                        />
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="center">
                        <CardMedia
                          className={classes.image}
                          component="img"
                          src="/images/LogoCapitalHumano.jpg"
                          alt="something"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </CmtTimelineContent>
            }
          />
        ))}
      </CmtTimeLine>
    </Box>
  );
};

export default ActivitiesTimeLine;
