import { v4 as uuidv4 } from "uuid";
import {
  newsImageUploaded,
  newsImageUploadedFailed,
  userImageUploaded,
  userImageUploadedFailed,
  newsVideoUploaded,
  newsVideoUploadedFailed,
  benefitsImageUploaded,
  benefitsImageUploadedFailed,
  benefitsVideoUploaded,
  benefitsVideoUploadedFailed
} from "../../../redux/actions/Storage";
import { StorageContentTypes } from "../../storage/index";
import { storage } from "../../auth/firebase/config";

const uploadStorageFunction = contentType => {
  switch (contentType) {
    case StorageContentTypes.NEWS_IMAGE_UPLOAD: {
      return newsImageUploaded;
    }
    case StorageContentTypes.USER_IMAGE_UPLOAD: {
      return userImageUploaded;
    }
    case StorageContentTypes.NEWS_VIDEO_UPLOAD: {
      return newsVideoUploaded;
    }
    case StorageContentTypes.BENEFITS_IMAGE_UPLOAD: {
      return benefitsImageUploaded;
    }
    case StorageContentTypes.BENEFITS_VIDEO_UPLOAD: {
      return benefitsVideoUploaded;
    }
    default: {
      return null;
    }
  }
};

const uploadStorageFailedFunction = contentType => {
  switch (contentType) {
    case StorageContentTypes.NEWS_IMAGE_UPLOAD: {
      return newsImageUploadedFailed;
    }
    case StorageContentTypes.USER_IMAGE_UPLOAD: {
      return userImageUploadedFailed;
    }
    case StorageContentTypes.NEWS_VIDEO_UPLOAD: {
      return newsVideoUploadedFailed;
    }
    case StorageContentTypes.BENEFITS_IMAGE_UPLOAD: {
      return benefitsImageUploadedFailed;
    }
    case StorageContentTypes.BENEFITS_VIDEO_UPLOAD: {
      return benefitsVideoUploadedFailed;
    }
    default: {
      return null;
    }
  }
};

const Firebase = {
  onUpload: (content, contentType) => {
    const successFunction = uploadStorageFunction(contentType);
    const failureFunction = uploadStorageFailedFunction(contentType);

    return dispatch => {
      if (content === "") {
        dispatch(
          failureFunction(
            `No es una imagen, el tipo de archivo es ${typeof content}`
          )
        );
      }
      const imageName = uuidv4();
      const uploadTask = storage.ref(`/images/${imageName}`).put(content);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        snapShot => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        err => {
          //catches the errors
          console.log(err);
          dispatch(failureFunction(`Error subiendo el archivo`));
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref("images")
            .child(imageName)
            .getDownloadURL()
            .then(fireBaseUrl => {
              dispatch(successFunction(fireBaseUrl));
            });
        }
      );
    };
  }
};

export default Firebase;

/* const Firebase = {
  onUpload: (content, contentType) => {
    const successFunction = uploadStorageFunction(contentType);
    const failureFunction = uploadStorageFailedFunction(contentType);

    return dispatch => {
      if (content === "") {
        dispatch(
          failureFunction(
            `No es una imagen, el tipo de archivo es ${typeof content}`
          )
        );
      }
      const videoName = uuidv4();
      const uploadTask = storage.ref(`/videos/${videoName}`).put(content);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        snapShot => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        err => {
          //catches the errors
          console.log(err);
          dispatch(failureFunction(`Error subiendo el archivo`));
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref("videos")
            .child(videoName)
            .getDownloadURL()
            .then(fireBaseUrl => {
              dispatch(successFunction(fireBaseUrl));
        });
        }
      );
    };
  }
}
export default Firebase;  */
