import React from "react";
import { AlarmAddRounded } from "@material-ui/icons";
import HoverInfoCard from "../../../../@jumbo/components/Common/HoverInfoCard";
import { Link } from "react-router-dom";

const HoursWidget = () => {
  return (
    <Link to="/carga-de-horas">
      <HoverInfoCard
        icon={<AlarmAddRounded style={{ color: "#ffffff" }} />}
        backgroundColor="#5DC460"
        title="Carga de horas"
        subTitle="¡No te olvides de ingresar!"
      />
    </Link>
  );
};

export default HoursWidget;
