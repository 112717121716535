import React, { useEffect } from "react";
import { Box, Divider, TextField } from "@material-ui/core";
import { HORAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import Restricted from "services/permissionProvider/Restricted";
import moment from "moment";

const AddMoreDays = ({
  fechaInicio,
  setFechaInicio,
  fechaFin,
  setFechaFin,
  fechaInicioValida,
  setFechaInicioValida,
  fechaFinValida,
  setFechaFinValida
}) => {
  useEffect(() => {
    const fecha1 = moment(fechaInicio).format("DD/MM/YYYY");
    const fecha2 = moment(fechaFin).format("DD/MM/YYYY");

    if (
      fechaInicio !== null &&
      fechaInicio !== "" &&
      fechaFin !== null &&
      fechaFin !== ""
    ) {
      if (fecha1 !== fecha2 && Date.parse(fechaInicio) < Date.parse(fechaFin)) {
        setFechaInicioValida(true);
        setFechaFinValida(true);
      } else {
        setFechaFinValida("mostrar");
      }
    } else {
      setFechaFinValida("mostrar");
    }
  }, [fechaFin, fechaInicio, setFechaInicioValida, setFechaFinValida]);

  const handleChange = (e, name) => {
    switch (name) {
      case "FechaInicio":
        setFechaInicio(e);
        break;
      case "FechaFin":
        setFechaFin(e);
        break;
      default:
        setFechaInicioValida(false);
    }
  };

  return (
    <Restricted to={HORAS_EDITAR}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center"
        }}
      >
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box display="block" padding="12px">
            <TextField
              id="btn-fechaInicio"
              InputLabelProps={{
                shrink: true
              }}
              style={{ width: "338px" }}
              label="Fecha de inicio"
              variant="outlined"
              type="date"
              locale="es-ES"
              format="DD/MM/YYYY"
              helperText={
                fechaInicioValida === "mostrar"
                  ? "Debes seleccionar una fecha"
                  : ""
              }
              onChange={e => handleChange(e.target.value, "FechaInicio")}
            />
          </Box>

          <Box display="block" padding="12px">
            <TextField
              id="btn-fechaFin"
              InputLabelProps={{
                shrink: true
              }}
              style={{ width: "338px" }}
              label="Fecha de fin"
              variant="outlined"
              type="date"
              locale="es-ES"
              format="DD/MM/YYYY"
              helperText={
                fechaFinValida === "mostrar"
                  ? "Debes seleccionar una fecha posterior a la fecha de inicio"
                  : ""
              }
              onChange={e => handleChange(e.target.value, "FechaFin")}
            />
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "20px"
          }}
        >
          <Divider />
        </Box>
      </Box>
    </Restricted>
  );
};
export default AddMoreDays;
