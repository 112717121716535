import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { /* getSignature, */ getUsers } from "../../../redux/actions/Users";
import { addRoleToUser, getRoles } from "redux/actions/Roles";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography } from "@material-ui/core";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const AddRolesToUser = ({
  setOnAddUserRoles,
  onCloseUpdateAlert,
  users,
  signatureName
}) => {
  const { roles } = useSelector(({ rolesReducer }) => rolesReducer);

  const [rolesId, setRolesId] = useState([]);
  const [roleWarning, setRoleWarning] = useState("");

  const dispatch = useDispatch();

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    dispatch(getRoles([], "", () => {}));
  }, [dispatch]);

  const verifyRequiredRole = () => {
    if (rolesId.length === 0) {
      setRoleWarning("Debes seleccionar un rol");
    } else {
      setRoleWarning("");
      onSubmitClickRoles();
    }
  };

  const onSubmitClickRoles = () => {
    dispatch(getUsers([], "", () => {}));

    const lastUserId = Math.max(...users.map(({ id }) => id));

    const roleId = rolesId.map(rol => rol.id);
    for (let i = 0; i < roleId.length; i++) {
      dispatch(
        addRoleToUser(lastUserId, roleId[i], () => {
          MySwal.fire({
            title: <strong>¡Listo!</strong>,
            html: (
              <Box>
                <p>El usuario se creó correctamente</p>
                <br></br>
                <p style={{ color: "#ed3c1c" }}>
                  Recordá que el usuario debe completar su perfil para que se
                  active y puedas asignarle proyectos, licencias y
                  capacitaciones.
                </p>
              </Box>
            ),
            icon: "success"
          });
          //dispatch(getSignature(lastUserId, signatureName));
        })
      );
      setOnAddUserRoles(false);
      onCloseUpdateAlert();
    }
  };

  return (
    <Box>
      <Box mb={{ xs: 6, md: 5 }}>
        <Autocomplete
          id="roles"
          multiple
          options={roles}
          getOptionLabel={role => role.name}
          getOptionSelected={(rolesId, value) => rolesId === value}
          placeholder="Buscar..."
          onChange={(event, getOptionSelected) => setRolesId(getOptionSelected)}
          renderInput={params => (
            <TextField {...params} label="Roles" variant="outlined" />
          )}
        />
      </Box>
      <Box>
        <Typography color="secondary">{roleWarning}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={4}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={verifyRequiredRole}
          >
            Guardar Roles
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default AddRolesToUser;
