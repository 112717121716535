export const CAPACITACIONES_VER = "capacitaciones-ver";
export const CAPACITACIONES_EDITAR = "capacitaciones-editar";
export const HORAS_CARGAR = "horas-cargar";
export const HORAS_APROBAR_PROYECTOS = "horas-aprobar-proyectos";
export const HORAS_EDITAR = "horas-editar";
export const LICENCIAS_VER = "licencias-ver";
export const LICENCIAS_EDITAR = "licencias-editar";
export const NOTICIAS_VER = "noticias-ver";
export const NOTICIAS_EDITAR = "noticias-editar";
export const PROYECTOS_VER = "proyectos-ver";
export const PROYECTOS_EDITAR = "proyectos-editar";
export const USUARIOS_VER = "usuarios-ver";
export const USUARIOS_EDITAR = "usuarios-editar";
export const FERIADOS_EDITAR = "feriados-editar";
export const BENEFICIOS_VER = "beneficios-ver";
export const BENEFICIOS_EDITAR = "beneficios-editar";
