import { GET_HOLIDAY } from "@jumbo/constants/ActionTypes";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";

export const addHoliday = (holidays, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/time-tracking-items-holiday`,
        holidays
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("Los feriados/asuetos fueron guardados correctamente.")
          );
          //dispatch(getHolidayHistory(() => {})); history de feriados
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Hubo un error guardando los feriados/asuetos."));
        }
      })
      .catch(error => {
        dispatch(fetchError("Hubo un error guardando los feriados/asuetos."));
      });
  };
};

export const editHoliday = (holidays, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/time-tracking-items-holiday`,
        holidays
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("Los feriados/asuetos fueron editados correctamente.")
          );
          //dispatch(getHolidayHistory(() => {})); history de feriados
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Hubo un error editando los feriados/asuetos."));
        }
      })
      .catch(error => {
        dispatch(fetchError("Hubo un error editando los feriados/asuetos."));
      });
  };
};

export const deleteHoliday = (ids, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/time-tracking-items-holiday`,
        {
          data: ids
        }
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(
              "Los feriados/asuetos fueron eliminados correctamente."
            )
          );
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("Hubo un error eliminando los feriados/asuetos.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError("Hubo un error eliminando los feriados/asuetos."));
      });
  };
};

export const getHolidays = callbackFun => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/time-tracking-items-holiday`
      )
      .then(data => {
        if (data.status === 200) {
          const arrayData = Object.values(data.data);
          dispatch({ type: GET_HOLIDAY, payload: arrayData });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Hubo un error guardando los feriados/asuetos."));
        }
      })
      .catch(error => {
        dispatch(fetchError("Hubo un error guardando los feriados/asuetos."));
      });
  };
};
