import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";
import {
  ADD_LICENCE,
  DELETE_BULK_LICENCES,
  DELETE_LICENCE,
  EDIT_LICENCE,
  GET_LICENCES,
  GET_LICENCE,
  SET_LICENCE_DETAILS
} from "../../@jumbo/constants/ActionTypes";

export const getLicences = (
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/licences`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_LICENCES, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const getLicence = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/licences/${id}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_LICENCE, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const setCurrentLicence = licence => {
  return dispatch => {
    dispatch({ type: SET_LICENCE_DETAILS, payload: licence });
  };
};

export const addNewLicence = (licence, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/licences`, licence)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("New licence was added successfully."));
          dispatch({ type: ADD_LICENCE, payload: data.data });
          dispatch(getLicences());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const sentMailToLicence = () => {
  return dispatch => {
    dispatch(fetchSuccess("Email has been sent to licence successfully"));
  };
};

export const updateLicence = (licence, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/licences/${licence.id}`,
        licence
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected licence was updated successfully."));
          dispatch({ type: EDIT_LICENCE, payload: data.data });
          dispatch(getLicences());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const updateLicenceStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/licences/update-status", data)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess("Licence status was updated successfully."));
          dispatch({ type: EDIT_LICENCE, payload: response.data });
          dispatch(getLicences());
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteBulkLicences = (licenceIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/licences/bulk-delete", { licenceIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(
            fetchSuccess("Selected licences were deleted successfully.")
          );
          dispatch({ type: DELETE_BULK_LICENCES, payload: licenceIds });
          dispatch(getLicences());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};

export const deleteLicence = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT}/api/licences/${id}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Selected licence was deleted successfully."));
          dispatch({ type: DELETE_LICENCE, payload: id });
          dispatch(getLicences());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError("Error en la respuesta del servidor"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Error en la respuesta del servidor"));
      });
  };
};
