import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/app-check";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDLDjnlCYjt92akm7bdDkyKmB847Bb8keo",
  authDomain: "intranet-test-fe108.firebaseapp.com",
  projectId: "intranet-test-fe108",
  storageBucket: "intranet-test-fe108.appspot.com",
  messagingSenderId: "263491925667",
  appId: "1:263491925667:web:48ae579ead26c44a9bf116",
  measurementId: "G-CM2HZCQ7SX"
};


firebase.initializeApp(config);
const auth = firebase.auth();
const storage = firebase.storage();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();

const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  "6LdV3lAqAAAAAL9rtBNX6S6TwrsGZ6O2nfIDa2WU",
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true
);

export {
  auth,
  storage,
  database,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  appCheck
};

