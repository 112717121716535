import React, { useState } from "react";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTheme } from "@material-ui/core";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import Box from "@material-ui/core/Box";
import CmtGridView from "@coremat/CmtGridView";
import PropTypes from "prop-types";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

import BusinessIcon from "@material-ui/icons/Business";
import SchoolIcon from "@material-ui/icons/School";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SurfingIcon from "@material-ui/icons/PeopleAlt";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

const tabs = [
  { id: 33232, title: "Datos Personales", slug: "datos-personales" }
];

const useStyles = makeStyles(theme => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 3,
      paddingBottom: 0
    }
  },
  tabsList: {
    position: "relative",
    minHeight: 60,
    "& .MuiTabs-indicator": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8)
    }
  },
  tabItem: {
    maxWidth: "none",
    minWidth: 10,
    minHeight: 60,
    padding: "5px 10px",
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular
  },
  columnRoot: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.5px"
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(1),
    margin: 0,
    boxShadow: "none"
  },
  chip: {
    margin: theme.spacing(1)
  }
}));

const About = ({ userDetail }) => {
  const [tabValue, setTabValue] = useState("datos-personales");
  const theme = useTheme();
  let history = useHistory();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { user } = userDetail;
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);

  const getInfo = name => {
    if (!authUser.roles.some(role => role.name === "externo")) {
      sessionStorage.setItem("buscador", name);
      history.push(`/hobbies-intereses/${name}`);
    }
  };

  const renderData = [
    {
      title: "Posición",
      desc: user.job_title ? user.job_title.name : "",
      icon: <BusinessIcon />
    },
    {
      title: "Mi líder es",
      desc: user.leader
        ? user.leader.first_name + " " + user.leader.last_name
        : "",
      icon: <PeopleAltIcon />
    },
    { title: "Mis Estudios", desc: user.studies, icon: <SchoolIcon /> },
    { title: "Vivo en", desc: user.residence, icon: <LocationOnIcon /> },
    {
      title: `Me interesa profesionalmente`,
      list: (
        <Box mt={1}>
          <Paper component="ul" className={classes.paper}>
            {user.interests.map(item => {
              return (
                <li key={item.id}>
                  <Chip
                    color="secondary"
                    label={item.name}
                    className={classes.chip}
                    onClick={() => getInfo(item.name)}
                  />
                </li>
              );
            })}
          </Paper>
        </Box>
      ),
      icon: <PeopleAltIcon />
    },
    {
      title: `En mi tiempo libre me interesa`,
      list: (
        <Box mt={1}>
          <Paper component="ul" className={classes.paper}>
            {user.hobbies.map(item => {
              return (
                <li key={item.id}>
                  <Chip
                    color="primary"
                    label={item.name}
                    className={classes.chip}
                    onClick={() => getInfo(item.name)}
                  />
                </li>
              );
            })}
          </Paper>
        </Box>
      ),
      icon: <SurfingIcon />
    }
  ];

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader
        separator={{
          color: theme.palette.borderColor.dark
        }}
        title="Sobre Mi"
      >
        <Tabs
          className={classes.tabsList}
          value={tabValue}
          onChange={handleTabChange}
          aria-label="profile tabs"
        >
          {tabs.map((item, index) => {
            return (
              <Tab
                className={classes.tabItem}
                key={index}
                value={item.slug}
                label={item.title}
              />
            );
          })}
        </Tabs>
      </CmtCardHeader>
      <CmtCardContent>
        <CmtGridView
          itemPadding={24}
          responsive={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3
          }}
          data={renderData}
          renderRow={(item, index) => (
            <Box display="flex" alignItems="center" key={index}>
              {item.icon}
              <Box ml={6}>
                <Box fontSize={12} color="text.secondary">
                  {item.title}
                </Box>
                <Box className={classes.columnRoot}>
                  {item.desc ? item.desc : item.list}
                </Box>
              </Box>
            </Box>
          )}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default About;

About.prototype = {
  userDetail: PropTypes.object.isRequired
};
