import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateLicence } from "../../../../redux/actions/Licences";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";

const SuspendedLicences = ({
  orderedSuspended,
  viewSuspendedLicences,
  setViewSuspendedLicences
}) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [licenceId, setLicenceId] = useState("");

  const onLicenceActivate = () => {
    dispatch(
      updateLicence({ id: licenceId, status: "ACTIVE" }, () => {
        setOpenDialog(false);
      })
    );
  };

  const handleCancelActivateLicence = () => {
    setOpenDialog(false);
  };

  const handleSetLicenceId = id => {
    setOpenDialog(true);
    setLicenceId(id);
  };

  return (
    <Dialog open={viewSuspendedLicences}>
      <DialogContent dividers>
        <Box
          mb={{ xs: 6, md: 5 }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width="400px"
        >
          {!!orderedSuspended.length ? (
            orderedSuspended.map(licence => (
              <Box mb={{ xs: 6, md: 5 }} key={licence.id}>
                <Box
                  m={4}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>{licence.name}</Typography>
                  <Button
                    id="btn-activarLicencia"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleSetLicenceId(licence.id)}
                  >
                    Activar
                  </Button>
                </Box>
                <Divider />
              </Box>
            ))
          ) : (
            <Box m={4} display="flex" justifyContent="center">
              <Typography display="flex" justifyContent="center">
                No se encontraron licencias
              </Typography>
            </Box>
          )}
          <Button
            id="btn-cerrarPopUp"
            variant="outlined"
            color="primary"
            onClick={() => setViewSuspendedLicences(false)}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
      <ConfirmDialog
        title="Activar licencia"
        content="¿Estas seguro de querer activar esta licencia?"
        open={openDialog}
        onConfirm={onLicenceActivate}
        onClose={handleCancelActivateLicence}
      />
    </Dialog>
  );
};

export default React.memo(SuspendedLicences);
