import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  cardStyle: {
    minHeight: "130px",
    transition: "all .2s ease-in-out",
    "&:hover": {
      boxShadow:
        "0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)",
      transform: "scale(1.1)"
    }
  }
}));

const Widget = ({ name, description, backgroundColor, marginBottom }) => {
  const classes = useStyles();

  return (
    <Card className={classes.cardStyle}>
      <Box>
        <Box style={{ backgroundColor, width: "100%", height: "15px" }} />
      </Box>
      <CardContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            height: "90px"
          }}
        >
          <Typography
            component="h2"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              marginBottom: marginBottom
            }}
          >
            {name}
          </Typography>
          <Typography
            component="span"
            style={{
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center"
            }}
          >
            {description}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Widget;
