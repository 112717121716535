import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import ProjectListRow from "./ProjectListRow";
import ProjectTableHead from "./ProjectTableHead";
import ProjectTableToolbar from "./ProjectTableToolbar";
import { getComparator, stableSort } from "../../../@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProject,
  getProjects,
  setCurrentProject
} from "../../../redux/actions/Projects";
import AddEditProject from "./AddEditProject";
import ConfirmDialog from "../../../@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "../../../@jumbo/utils/commonHelper";
import { useHistory } from "react-router-dom";
import useStyles from "./index.style";
import ProjectDetailView from "../../Pages/ProjectDetailView";
import NoRecordFound from "./NoRecordFound";
import { PROYECTOS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import Forbidden from "@jumbo/components/Forbidden";
import Restricted from "services/permissionProvider/Restricted";
import SuspendedProjects from "./SuspendedProjects";
import { useHasPrivilege } from "routes/checkPermission";

const ProjectsModule = () => {
  const classes = useStyles();
  const { projects } = useSelector(({ projectsReducer }) => projectsReducer);
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState({ name: "" });
  const [projectsFetched, setProjectsFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewSuspendedProjects, setViewSuspendedProjects] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const canDispatch = useHasPrivilege(PROYECTOS_EDITAR);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    if (canDispatch) {
      dispatch(
        getProjects(filterOptions, debouncedSearchTerm, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setProjectsFetched(true);
        })
      );
    }
  }, [dispatch, filterOptions, debouncedSearchTerm, canDispatch]);

  const activeProjects = projects.filter(
    project => project.status === "ACTIVE"
  );

  const suspendedProjects = projects.filter(
    project => project.status === "SUSPENDED"
  );

  const orderedActive = stableSort(
    activeProjects,
    getComparator(order, orderBy)
  );

  const orderedSuspended = stableSort(
    suspendedProjects,
    getComparator(order, orderBy)
  );

  const handleCloseProjectDialog = () => {
    setOpenProjectDialog(false);
    dispatch(setCurrentProject(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = projects.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProjectView = project => {
    dispatch(setCurrentProject(project));
    history.push("/proyectos/" + project.id);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentProject(null));
  };

  const handleProjectEdit = project => {
    dispatch(setCurrentProject(project));
    setOpenProjectDialog(true);
  };

  const handleProjectDelete = project => {
    setSelectedProject(project);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteProject(selectedProject.id));
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const filteredProjects = orderedActive.filter(row => {
    let rows;
    if (searchTerm === "") {
      rows = row;
    } else if (row.name !== "" && row.name !== null && row.name !== undefined) {
      if (row.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        rows = row;
      }
    } else if (row.area !== "" && row.area !== null && row.area !== undefined) {
      if (row.area.toLowerCase().includes(searchTerm.toLowerCase())) {
        rows = row;
      }
    }
    return rows;
  });

  return (
    <Restricted to={PROYECTOS_EDITAR} fallback={Forbidden}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <ProjectTableToolbar
            selected={selected}
            setSelected={setSelected}
            onProjectAdd={setOpenProjectDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="sticky enhanced table"
            >
              <ProjectTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={projects.length}
              />
              <TableBody>
                {!!filteredProjects.length ? (
                  filteredProjects
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <ProjectListRow
                        key={index}
                        row={row}
                        onRowClick={handleRowClick}
                        onProjectEdit={handleProjectEdit}
                        onProjectDelete={handleProjectDelete}
                        onProjectView={handleProjectView}
                        isSelected={isSelected}
                      />
                    ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>
                          No se encontraron proyectos.
                        </NoRecordFound>
                      ) : (
                        <NoRecordFound>
                          {projectsFetched
                            ? "No se encontraron proyectos."
                            : "Cargando proyectos..."}
                        </NoRecordFound>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={activeProjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage="Filas por página"
          />
        </Paper>

        {openProjectDialog && (
          <AddEditProject
            open={openProjectDialog}
            onCloseDialog={handleCloseProjectDialog}
          />
        )}
        {openViewDialog && (
          <ProjectDetailView
            open={openViewDialog}
            onCloseDialog={handleCloseViewDialog}
          />
        )}

        <Button
          id="btn-proyectosSuspendidos"
          variant="outlined"
          onClick={() => setViewSuspendedProjects(true)}
        >
          Ver proyectos suspendidos
        </Button>

        {viewSuspendedProjects && (
          <SuspendedProjects
            orderedSuspended={orderedSuspended}
            viewSuspendedProjects={viewSuspendedProjects}
            setViewSuspendedProjects={setViewSuspendedProjects}
          />
        )}

        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirmación de  borrado ${selectedProject.name}`}
          content={"Estás seguro de querer borrar a este proyecto?"}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </Restricted>
  );
};

export default ProjectsModule;
