import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid } from "@material-ui/core";

import NewUsersList from "./NewUsersList";
import RecentActivities from "./RecentActivities";
import GridContainer from "../../../@jumbo/components/GridContainer";
import CurrentProjectsSummary from "./CurrentProjectsSummary";
import OurOffice from "./OurOffice";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import { Link } from "react-router-dom";
import TLeoWhatsApp from "./TLeoWhatsAppWidget";
import { useSelector } from "react-redux";
import HoursWidget from "./HoursWidget";
import Widget from "./Widget";
import { useHistory } from "react-router-dom";
import { HORAS_CARGAR } from "services/permissionProvider/PermissionsCatalog";
import Restricted from "services/permissionProvider/Restricted";
import Forbidden from "@jumbo/components/Forbidden";

const useStyles = makeStyles(theme => ({
  pageFull: {
    width: "100%",
    "& .page-header": {
      position: "relative",
      zIndex: 3
      /* "& .title, & .bread-crumbs, & .bread-crumbs a, & .bread-crumbs .MuiTypography-colorTextPrimary": {
        color: theme.palette.common.black
      } */
    }
  },
  userPhotosRoot: {
    "& .scrollbar-container": {
      height: "272px !important"
    }
  },
  cardRoot: {
    width: "100%"
  }
}));

/* const breadcrumbs = [
  { label: "Home", link: "/" },
  { label: "Dashboard", link: "/dashboard" },
  { label: "Intranet", isActive: true }
]; */

const IntranetDashboard = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const history = useHistory();

  if (authUser.status === "ONBOARDING_REQUIRED") {
    history.push("/perfil/editar");
  }

  /*   if (
    authUser.status !== "ONBOARDING_REQUIRED" &&
    authUser.roles.some(role => role.name === "externo")
  ) {
    history.push("/carga-de-horas");
  } */

  return (
    <Restricted to={HORAS_CARGAR} fallback={Forbidden}>
      <PageContainer
        heading="Intranet"
        //breadcrumbs={breadcrumbs}
        className={classes.pageFull}
      >
        {authUser.roles.some(
          rol => rol.name === "Externo" || rol.name === "externo"
        ) ? (
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <HoursWidget />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <OurOffice />
            </Grid>
          </GridContainer>
        ) : (
          <GridContainer>
            <Grid item xs={12} md={8} xl={8}>
              <RecentActivities />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              xl={4}
              //style={{ display: "flex", alignItems: "center" }}
            >
              <GridContainer
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12} sm={12}>
                  <Link to="/beneficios">
                    <Widget
                      name="Beneficios"
                      description="¡Mirá tus beneficios!"
                      backgroundColor="#d790ff"
                      marginBottom="10px"
                    />
                  </Link>
                </Grid>
                {/*    <Grid item xs={12} sm={6}>
                  <Link
                    to={{ pathname: "https://cdt.perfomap.com/" }}
                    target="_blank"
                  >
                    <Widget
                      name="Desempeño"
                      description="Ingresá a Perfomap"
                      backgroundColor="#7f04fc"
                      marginBottom="35px"
                    />
                  </Link>
                </Grid> */}
                <Grid item xs={12} sm={12}>
                  <Link to="/carga-de-horas">
                    <Widget
                      name="Cargá tus horas"
                      description="¡No te olvides de ingresarlas!"
                      backgroundColor="#83b0fc"
                      marginBottom="10px"
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Link
                    to={{
                      pathname: "https://cdt.turecibo.com.ar"
                    }}
                    target="_blank"
                  >
                    <Widget
                      name="Recibo de Sueldo"
                      description="Ingresá a TuRecibo"
                      backgroundColor="#a3a3ff"
                      marginBottom="10px"
                    />
                  </Link>
                </Grid>
              </GridContainer>
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
              <NewUsersList />
            </Grid>
            <Grid item xs={12} md={8} xl={8}>
              <CurrentProjectsSummary />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <OurOffice />
            </Grid>
          </GridContainer>
        )}
        <TLeoWhatsApp />
      </PageContainer>
    </Restricted>
  );
};

export default IntranetDashboard;
