import React, { useEffect } from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import { Typography, makeStyles } from "@material-ui/core";

moment.tz.setDefault("America/Argentina/Buenos_Aires");

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(theme => ({
  custom_calendar: {
    "& .rbc-agenda-time-cell": {
      display: "none !important"
    },
    "& .rbc-agenda-view table.rbc-agenda-table thead > tr > :nth-child(2)": {
      display: "none !important"
    },
    "& .rbc-agenda-view table.rbc-agenda-table tbody > tr > td": {
      padding: "15px",
      border: "1px solid #3f51b5"
    },
    "& .rbc-agenda-empty": {
      display: "flex",
      justifyContent: "center",
      fontWeigth: "30px",
      marginTop: "30px",
      padding: "30px",
      border: "1px solid gray",
      borderRadius: "30px"
    },
    "& .rbc-agenda-date-cell": {
      borderRadius: "16px 0px 16px 0px"
    },
    "& .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr": {
      border: "1px solid #3f51b5",
      borderRadius: "16px 0px 16px 0px"
    },
    "& .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td": {
      border: "1px solid #3f51b5"
    },
    "& .rbc-toolbar": {
      padding: "15px",
      fontWeigth: 700,
      color: "gray"
    },
    "& .rbc-btn-group button": {
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "15px",
      paddingRight: "15px",
      backgroundColor: "#303f9f",
      color: "#ffffff",
      margin: "5px"
    },
    "& .rbc-btn-group > button:last-child:not(:first-child)": {
      borderRadius: "8px"
    },
    "& .rbc-btn-group > button:not(:first-child):not(:last-child)": {
      borderRadius: "8px"
    },
    "& .rbc-btn-group > button:first-child:not(:last-child)": {
      borderRadius: "8px"
    },
    "& .rbc-agenda-view table.rbc-agenda-table": {
      fontWeigth: "16px",
      padding: "10px",
      border: "1px solid #3f51b5",
      borderRadius: "8px"
    },
    "& .rbc-agenda-view table.rbc-agenda-table thead > tr > th": {
      padding: "10px",
      textAlign: "center"
    },
    "& .rbc-header + .rbc-header": {
      borderLeft: "1px solid #3f51b5"
    },
    "& .rbc-agenda-view table.rbc-agenda-table thead > tr > :nth-child(1)": {
      width: "30%"
    },
    "& .rbc-agenda-view table.rbc-agenda-table thead > tr > :nth-child(3)": {
      width: "70%"
    },
    "& .rbc-agenda-view table.rbc-agenda-table tbody > tr > :nth-child(1)": {
      width: "30%"
    },
    "& .rbc-agenda-view table.rbc-agenda-table tbody > tr > :nth-child(3)": {
      width: "70%"
    }
  }
}));

const TimeTrackingCalendar = ({ timeTrackingItems }) => {
  const classes = useStyles();
  const mode = sessionStorage.getItem("colorMode");

  useEffect(() => {
    //console.log(timeTrackingItems);
  }, [timeTrackingItems]);

  const normalizarHorasMinutos = (horas, minutos) => {
    let formHoras;
    let formMinutos;
    const cero = 0;
    if (horas.toString().length < 2) {
      formHoras = cero.toString() + horas.toString();
    } else {
      formHoras = horas;
    }
    if (minutos.toString().length < 2) {
      formMinutos = cero.toString() + minutos.toString();
    } else {
      formMinutos = minutos;
    }
    return `${formHoras}:${formMinutos}hs`;
  };

  const events = timeTrackingItems.map(item => ({
    title: `horastotales ${normalizarHorasMinutos(item.hours, item.minutes)}`,
    start: moment(item.itemDate)
      .add(3, "hours")
      .toISOString(),
    end: moment(item.itemDate)
      .add(3, "hours")
      .toISOString(),
    subevents: item.timeTrackingItem.map(subitem => ({
      title: `${subitem.trackable.name} - ${subitem.hours}:${subitem.minutes}hs `,
      start: moment(item.itemDate)
        .add(3, "hours")
        .toISOString(),
      end: moment(item.itemDate)
        .add(3, "hours")
        .toISOString()
    }))
  }));

  const formattedEvents = events.reduce((acc, event) => {
    acc.push(event);

    if (event.subevents && event.subevents.length > 0) {
      acc = acc.concat(event.subevents);
    }

    return acc;
  }, []);

  const getTextColor = (horas, minutos) => {
    if (mode === "dark") {
      if (horas > 8) {
        return "rgb(240,76,22)";
      } else if (horas < 8) {
        return "rgb(240,76,22)";
      } else if (horas === 8 && minutos > 0) {
        return "rgb(240,76,22)";
      } else {
        return "#FFFFFF";
      }
    } else {
      if (horas > 8) {
        return "rgb(240,76,22)";
      } else if (horas < 8) {
        return "rgb(240,76,22)";
      } else if (horas === 8 && minutos > 0) {
        return "rgb(240,76,22)";
      } else {
        return "rgb(0,0,0)";
      }
    }
  };

  const customEvent = ({ event }) => {
    const horas = parseInt(event.title.replace("horastotales", "").slice(0, 3));
    const minutos = parseInt(
      event.title.replace("horastotales", "").slice(3, 6)
    );
    return (
      <>
        {event.title.slice(0, 12) !== "horastotales" ? (
          <div
            style={{
              backgroundColor:
                mode === "dark"
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(0,0,0,0.26)",
              padding: "10px",
              borderRadius: "16px"
            }}
          >
            <strong>{event.title}</strong>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "17px"
            }}
          >
            <Typography
              style={{
                display: "flex",
                fontWeight: 700,
                justifyContent: "center",
                width: "250px",
                padding: "5px",
                border: "1px solid gray",
                borderRadius: "16px",
                color: getTextColor(horas, minutos)
              }}
            >
              Horas totales: {event.title.replace("horastotales", "")}
            </Typography>
          </div>
        )}
      </>
    );
  };

  const customDate = date => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "17px"
        }}
      >
        <Typography
          style={{
            display: "flex",
            fontWeight: 700,
            justifyContent: "center",
            width: "250px",
            padding: "5px",
            borderRadius: "16px"
          }}
        >
          {localizer.format(moment(date.day), "dddd D [de] MMMM").toUpperCase()}
        </Typography>
      </div>
    );
  };

  const today = new Date();

  return (
    timeTrackingItems && (
      <PageContainer>
        <Calendar
          current={today.toLocaleDateString()}
          localizer={localizer}
          culture="es"
          messages={{
            date: "Fecha",
            event: "Entrada",
            today: "Hoy",
            previous: "Anterior",
            next: "Siguiente",
            noEventsInRange: "No se cargaron horas en este período."
          }}
          //defaultDate={new Date(currentYear, currentMonth, 1)}
          views={["agenda"]}
          length={7}
          popup
          defaultView="agenda"
          className={classes.custom_calendar}
          events={formattedEvents}
          components={{
            agenda: {
              event: customEvent,
              date: customDate
            }
          }}
        />
      </PageContainer>
    )
  );
};

export default TimeTrackingCalendar;

//Calendar viejo

/* import React from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/es";
// and, for optional time zone support
import "moment-timezone";
import { makeStyles } from "@material-ui/core";

moment.tz.setDefault("America/Argentina/Buenos_Aires");
// end optional time zone support

const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(theme => ({
  custom_calendar: {
    "& .rbc-agenda-time-cell": {
      display: "none !important"
    },
    "& .rbc-agenda-view table.rbc-agenda-table thead > tr > :nth-child(2)": {
      display: "none !important"
    }
  }
}));

const TimeTrackingCalendar = ({ timeTrackingItems }) => {
  const classes = useStyles();

  const calendarItems = timeTrackingItems.map(
    ({ trackable, item_date, hours, minutes }) => ({
      title: " " + hours + ":" + minutes + " hs - " + trackable.name,
      start: item_date,
      end: item_date
    })
  );

  return (
    calendarItems && (
      <PageContainer>
        <Calendar
          localizer={localizer}
          culture="es"
          events={calendarItems}
          messages={{
            date: "Fecha",
            event: "Entrada",
            today: "Hoy",
            previous: "Anterior",
            next: "Siguiente",
            noEventsInRange: "No hay entradas en este período."
          }}
          defaultDate={new Date(currentYear, currentMonth, 1)}
          views={["agenda"]}
          popup
          defaultView="agenda"
          className={classes.custom_calendar}
          //onDoubleClickEvent
        />
      </PageContainer>
    )
  );
};

export default TimeTrackingCalendar; */
