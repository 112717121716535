import {
  USER_IMAGE_UPLOADED,
  USER_IMAGE_UPLOADED_FAILED,
  NEWS_IMAGE_UPLOADED,
  NEWS_IMAGE_UPLOADED_FAILED,
  BENEFITS_IMAGE_UPLOADED,
  BENEFITS_IMAGE_UPLOADED_FAILED,
  NEWS_VIDEO_UPLOADED,
  NEWS_VIDEO_UPLOADED_FAILED
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  userImageUploadedUrl: "",
  userImageUploadedError: "",
  newsImageUploadedUrl: "",
  newsImageUploadedError: "",
  benefitsImageUploadedUrl: "",
  benefitsImageUploadedUrlError: "",
  newsVideoUploadedUrl: "",
  newsVideoUploadedError: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_IMAGE_UPLOADED: {
      return {
        ...state,
        userImageUploadedUrl: action.payload
      };
    }
    case USER_IMAGE_UPLOADED_FAILED: {
      return {
        ...state,
        userImageUploadedError: action.payload
      };
    }
    case NEWS_IMAGE_UPLOADED: {
      return {
        ...state,
        newsImageUploadedUrl: action.payload
      };
    }
    case NEWS_IMAGE_UPLOADED_FAILED: {
      return {
        ...state,
        newsImageUploadedError: action.payload
      };
    }
    case BENEFITS_IMAGE_UPLOADED: {
      return {
        ...state,
        benefitsImageUploadedUrl: action.payload
      };
    }
    case BENEFITS_IMAGE_UPLOADED_FAILED: {
      return {
        ...state,
        benefitsImageUploadedUrlError: action.payload
      };
    }
    case NEWS_VIDEO_UPLOADED: {
      return {
        ...state,
        newsVideoUploadedUrl: action.payload
      };
    }
    case NEWS_VIDEO_UPLOADED_FAILED: {
      return {
        ...state,
        newsVideoUploadedError: action.payload
      };
    }
    default:
      return state;
  }
};
