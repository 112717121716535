import { THEME_TYPES } from "../@jumbo/constants/ThemeOptions";

export const semiDarkTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    sidebar: {
      bgColor: "#363636",
      textColor: "rgba(255, 255, 255, 0.3)",
      textDarkColor: "#fff",
      textActiveColor: "#fff",
      navHoverBgColor: "rgba(187, 134, 252, 0.08)",
      navActiveBgColor: "#3f51b5",
      borderColor: "rgba(255, 255, 255, 0.08)"
    },
    horizontalNav: {
      navigationColor: "rgba(255, 255, 255, 0.74)",
      navigationActiveColor: "rgba(255, 255, 255, 1)",
      textColor: "rgba(0, 0, 0, 0.6)",
      textDarkColor: "rgba(0, 0, 0, 0.87)",
      textActiveColor: "#3f51b5",
      menuHoverBgColor: "rgb(229, 229, 229)",
      menuActiveBgColor: "rgb(239, 229, 253)"
    },
    common: {
      dark: "#020202"
    },
    background: {
      paper: "#FFFFFF",
      default: "#f4f4f7"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.3)",
      white: "#fff"
    },
    borderColor: {
      main: "rgba(0, 0, 0, 0.06)",
      dark: "rgba(0, 0, 0, 0.12)"
    },
    lightBtn: {
      bgColor: "#f5f5f5",
      textColor: "rgba(0, 0, 0, 0.38)"
    },
    popupColor: {
      main: "#fff"
    }
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    MuiPopover: {
      paper: {
        backgroundColor: "#FFFFFF"
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#FFFFFF"
      }
    }
  }
};
export const darkTheme = {
  palette: {
    type: THEME_TYPES.DARK,
    sidebar: {
      bgColor: "#363636",
      textColor: "rgba(255, 255, 255, 0.3)",
      textDarkColor: "#fff",
      textActiveColor: "#fff",
      navHoverBgColor: "rgba(187, 134, 252, 0.08)",
      navActiveBgColor: "#3f51b5",
      borderColor: "rgba(255, 255, 255, 0.08)"
    },
    horizontalNav: {
      navigationColor: "rgba(255, 255, 255, 0.74)",
      navigationActiveColor: "rgba(255, 255, 255, 1)",
      textColor: "#fffff",
      textDarkColor: "#fff",
      textActiveColor: "#fffff",
      menuHoverBgColor: "rgb(0, 0, 0, 0.8)",
      menuActiveBgColor: "rgb(0, 0, 0, 0.5)"
    },
    common: {
      dark: "#fff"
    },
    background: {
      paper: "#121212",
      default: "#2e2e2e"
    },
    text: {
      primary: "rgba(255, 255, 255, 0.87)",
      secondary: "rgba(255, 255, 255, 0.6)",
      disabled: "rgba(255, 255, 255, 0.38)",
      hint: "rgba(255, 255, 255, 0.3)",
      white: "#fffff"
    },
    borderColor: {
      main: "rgba(255, 255, 255, 0.06)",
      dark: "rgba(255, 255, 255, 0.12)"
    },
    lightBtn: {
      bgColor: "#535151",
      textColor: "rgba(255, 255, 255, 0.38)"
    },
    popupColor: {
      main: "#363636"
    },
    typography: {
      fontFamily: "Alegreya Sans SC"
    }
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: "rgba(255, 255, 255, 0.87)"
      }
    },
    MuiPopover: {
      paper: {
        backgroundColor: "#535050"
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#2e2e2e"
      }
    },
    MuiTypography: {
      h1: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 22,
        fontWeight: 400
      },
      h2: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 19,
        fontWeight: 400
      },
      h3: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 17,
        fontWeight: 400
      },
      h4: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 15,
        fontWeight: 400
      },
      h5: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 15,
        fontWeight: 500
      },
      h6: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 15,
        fontWeight: 400,
        letterSpacing: 0.6
      },
      title: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 22,
        fontWeight: 400
      },
      subtitle1: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 17,
        fontWeight: 400,
        letterSpacing: 0.16
      },
      subtitle2: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 15,
        fontWeight: 400,
        letterSpacing: 0.2
      },
      body1: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 17,
        fontWeight: 400,
        letterSpacing: 0.6
      },
      body2: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontSize: 15,
        fontWeight: 400,
        letterSpacing: 0.26
      },
      p: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase"
      },
      list: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase"
      }
    },
    MuiButton: {
      root: {
        fontWeight: "bold",
        letterSpacing: 1.25,
        fontSize: 13
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontWeight: 400
      }
    },
    MuiToggleButton: {
      root: {
        borderRadius: 4
      }
    },
    MuiCardLg: {
      root: {
        borderRadius: 10
      }
    },
    MuiCard: {
      root: {
        borderRadius: 4,
        boxShadow:
          "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)"
      }
    },
    MuiTextField: {
      root: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontWeight: 400
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontWeight: 400
      }
    },
    MuiAutocomplete: {
      root: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontWeight: 400
      }
    },
    MuiCardContent: {
      root: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontWeight: 400
      }
    },
    MuiPaper: {
      root: {
        fontFamily: "Arial",
        color: "#ffffff",
        textTransform: "uppercase",
        fontWeight: 400
      }
    }
  }
};

export const lightTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    sidebar: {
      bgColor: "#fff",
      textColor: "rgba(0, 0, 0, 0.6)",
      textDarkColor: "rgba(0, 0, 0, 0.87)",
      textActiveColor: "#3f51b5",
      navHoverBgColor: "rgb(229, 229, 229)",
      navActiveBgColor: "rgb(239, 229, 253)",
      borderColor: "rgba(33, 33, 33, 0.08)"
    },
    horizontalNav: {
      navigationColor: "rgba(255, 255, 255, 0.74)",
      navigationActiveColor: "rgba(255, 255, 255, 1)",
      textColor: "rgba(0, 0, 0, 0.6)",
      textDarkColor: "rgba(0, 0, 0, 0.87)",
      textActiveColor: "#3f51b5",
      menuHoverBgColor: "rgb(229, 229, 229)",
      menuActiveBgColor: "rgb(239, 229, 253)"
    },
    common: {
      dark: "#020202"
    },
    background: {
      paper: "#FFFFFF",
      default: "#f4f4f7"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.3)",
      white: "#fff"
    },
    borderColor: {
      main: "rgba(0, 0, 0, 0.06)",
      dark: "rgba(0, 0, 0, 0.12)"
    },
    lightBtn: {
      bgColor: "#f5f5f5",
      textColor: "rgba(0, 0, 0, 0.38)"
    },
    popupColor: {
      main: "#fff"
    }
  },
  overrides: {
    typography: {
      fontFamily: "Poppins"
    },
    MuiTab: {
      textColorPrimary: {
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    MuiPopover: {
      paper: {
        backgroundColor: "#FFFFFF"
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#FFFFFF"
      }
    }
    /* MuiTypography: {
      fontFamily: "Poppins sans-serif"
    } */
  }
};
