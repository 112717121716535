import Forbidden from "@jumbo/components/Forbidden";
import GridContainer from "@jumbo/components/GridContainer";
import Restricted from "services/permissionProvider/Restricted";
import UserActivityAlert from "@jumbo/components/UserActivityAlert";
import { CurrentStorageMethod } from "@jumbo/constants/AppConstants";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import { ImageSearch } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { updateNew } from "redux/actions/UserActivities";
import { getAreas, getUsers } from "redux/actions/Users";
import { NOTICIAS_EDITAR } from "services/permissionProvider/PermissionsCatalog";
import { StorageMethods, StorageContentTypes } from "services/storage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import ReactPlayer from "react-player";
import { useHasPrivilege } from "routes/checkPermission";

const EditNew = ({ handleCancelEditNew, currentActivity }) => {
  const act = currentActivity.act.activity;
  const canDispatch = useHasPrivilege(NOTICIAS_EDITAR);
  const dispatch = useDispatch();
  const { users } = useSelector(({ usersReducer }) => usersReducer);
  const { areas } = useSelector(({ usersReducer }) => usersReducer);
  const { newsImageUploadedUrl } = useSelector(({ storage }) => storage);
  const [noticia, setNoticia] = React.useState({
    title: act.title,
    description: act.description,
    type: act.type,
    image_url: act.image_url,
    video_url: act.video_url,
    expiration_date: moment(act.expiration_date.slice(0, 10)).format(
      "DD/MM/yyyy"
    ),
    publish_date: moment(act.publish_date.slice(0, 10)).format("DD/MM/yyyy"),
    recipient_areas: act.recipient_areas,
    recipient_users: act.recipient_users
  });
  const [titleError, setTitleError] = React.useState(false);
  const [descriptionError, setDescriptionError] = React.useState(false);
  const [typeError, setTypeError] = React.useState(false);
  const [dateError, setDateError] = React.useState(false);
  const [dataError, setDataError] = React.useState(false);
  const [videoError, setVideoError] = React.useState(false);
  const [loadImage, setLoadImage] = React.useState(false);
  // const [loadVideo, setLoadVideo] = React.useState(false);
  const [doneEditingNew, setDoneEditingNew] = React.useState(false);
  const [shareToAllUsers, setShareToAllUsers] = React.useState(false);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getUsers([], "", () => {}));
    }
  }, [dispatch, canDispatch]);

  useEffect(() => {
    if (canDispatch) {
      dispatch(getAreas([], "", () => {}));
    }
  }, [dispatch, canDispatch]);

  useEffect(() => {
    if (noticia.recipient_areas.length === areas.length) {
      setShareToAllUsers(true);
    }
  }, [noticia, areas, shareToAllUsers]);

  const handlePic = pic => {
    setNoticia({ ...noticia, image_url: URL.createObjectURL(pic) });
    dispatch(
      StorageMethods[CurrentStorageMethod].onUpload(
        pic,
        StorageContentTypes.NEWS_IMAGE_UPLOAD
      )
    );
    setLoadImage(true);
  };

  /* const handleVideo = video => {
      setNoticia({ ...noticia, video_url: URL.createObjectURL(video) });
      dispatch(
        StorageMethods[CurrentStorageMethod].onUpload(
          video,
          StorageContentTypes.NEWS_VIDEO_UPLOAD
        )
      );
      setLoadVideo(true);
    }; */

  const handleVideo = e => {
    setNoticia({ ...noticia, video_url: e.target.value });
    // console.log(e.target.value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    // 'video':['.mp4', '.mp3']
    onDrop: acceptedFiles => {
      handlePic(acceptedFiles[0]);
      /* handleVideo(acceptedFiles[0]); */
    }
  });

  const handleChange = e => {
    setNoticia({ ...noticia, [e.target.name]: e.target.value });
    setTitleError(false);
    setDescriptionError(false);
    setTypeError(false);
    setDataError(false);
    setDateError(false);
    setVideoError(false);
  };

  const handleChangeDates = e => {
    const momentDate = e.target.value + "T00:00:00.000Z";
    const newDate = moment(momentDate)
      .add(1, "days")
      .format("DD/MM/yyyy");
    setNoticia({ ...noticia, [e.target.name]: newDate });
    setDateError(false);
  };

  const formatDate = date => {
    return date
      .split("/")
      .reverse()
      .join("-");
  };

  const {
    title,
    description,
    type,
    image_url,
    video_url,
    expiration_date,
    publish_date,
    recipient_areas,
    recipient_users
  } = noticia;
  const values = {
    title,
    description,
    type,
    image_url,
    video_url,
    expiration_date,
    publish_date,
    recipient_areas,
    recipient_users
  };

  const handleAreas = newValue => {
    newValue = newValue.map(op => {
      return {
        name: op.name
      };
    });
    let currentNew = Object.assign({}, noticia);
    currentNew["recipient_areas"] = newValue;
    setNoticia(currentNew);
  };

  const handleUsers = newValue => {
    newValue = newValue.map(op => {
      return {
        email: op.email
      };
    });
    let currentNew = Object.assign({}, noticia);
    currentNew["recipient_users"] = newValue;
    setNoticia(currentNew);
  };

  const todaysDate = moment(new Date()).format("DD/MM/yyyy");

  const validateNewsForm = () => {
    let condition = true;

    if (noticia.title.trim() === "" || noticia.title.length > 100) {
      setTitleError(true);
      condition = false;
    } else if (titleError && noticia.title.trim() !== "") {
      setTitleError(false);
    }
    if (noticia.description.trim() === "" || noticia.description.length > 254) {
      setDescriptionError(true);
      condition = false;
    } else if (descriptionError && noticia.description.trim() !== "") {
      setTitleError(false);
    }
    if (noticia.video_url.replaceAll(" ", "").length === 0) {
      setNoticia({ ...noticia, video_url: "" });
    } else if (noticia.video_url.length > 200) {
      setVideoError(true);
      condition = false;
    } else if (videoError && noticia.video_url.replaceAll(" ", "").length > 0) {
      setVideoError(false);
    }
    if (noticia.type === "") {
      setTypeError(true);
      condition = false;
    } else if (typeError && noticia.type !== "") {
      setTypeError(false);
    }
    if (noticia.publish_date === noticia.expiration_date) {
      setDateError(true);
      condition = false;
    } else if (noticia.publish_date.slice(6, 10) < todaysDate.slice(6, 10)) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(6, 10) > noticia.expiration_date.slice(6, 10)
    ) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(6, 10) ===
        noticia.expiration_date.slice(6, 10) &&
      noticia.publish_date.slice(3, 5) > noticia.expiration_date.slice(3, 5)
    ) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(3, 10) ===
        noticia.expiration_date.slice(3, 10) &&
      noticia.publish_date.slice(0, 2) > noticia.expiration_date.slice(0, 2)
    ) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(6, 10) === todaysDate.slice(6, 10) &&
      noticia.publish_date.slice(3, 5) < todaysDate.slice(3, 5)
    ) {
      setDateError(true);
      condition = false;
    } else if (
      noticia.publish_date.slice(3, 10) === todaysDate.slice(3, 10) &&
      noticia.publish_date.slice(0, 2) < todaysDate.slice(0, 2)
    ) {
      setDateError(true);
      condition = false;
    }

    if (!shareToAllUsers) {
      if (
        noticia.recipient_areas.length === 0 &&
        noticia.recipient_users.length === 0
      ) {
        setDataError(true);
        condition = false;
      } else if (
        (dataError && noticia.recipient_areas.length !== 0) ||
        noticia.recipient_users.length !== 0
      ) {
        setDataError(false);
      }
    }

    return condition;
  };

  const handleCheckBox = () => {
    if (shareToAllUsers) {
      let currentNew = Object.assign({}, noticia);
      currentNew["recipient_areas"] = [];
      setNoticia(currentNew);
      setShareToAllUsers(false);
    }
    if (!shareToAllUsers) {
      setShareToAllUsers(true);
    }
  };

  const onUpdateNew = news => {
    if (shareToAllUsers) {
      const allAreas = areas.map(area => {
        return { name: area.name };
      });
      noticia.recipient_areas = allAreas;
    }

    let currentNew = Object.assign({}, noticia);
    currentNew["image_url"] = newsImageUploadedUrl;
    setNoticia(currentNew);

    const newImageURL = newsImageUploadedUrl
      ? newsImageUploadedUrl
      : news.image_url;
    news.image_url = newImageURL;

    news.video_url = news.video_url.replaceAll(" ", "");

    if (validateNewsForm()) {
      dispatch(
        updateNew(act.id, noticia, () => {
          setDoneEditingNew(true);
        })
      );
    }
  };

  const orderedUsers = stableSort(users, getComparator("asc", "first_name"));

  return (
    <Restricted to={NOTICIAS_EDITAR} fallback={Forbidden}>
      <Box p={1} display="flex" justifyContent="center" flexDirection="column">
        <Box display="flex" justifyContent="center">
          <Typography style={{ fontSize: "20px", marginBottom: "10px" }}>
            Editar noticia
          </Typography>
        </Box>
        <Card>
          <CardContent>
            <GridContainer>
              <Grid xs={12} sm={12} item>
                <TextField
                  id="txt-tituloNoticia"
                  placeholder="Escribe un titulo"
                  variant="outlined"
                  label="Título"
                  fullWidth
                  value={noticia.title}
                  error={titleError}
                  helperText={
                    titleError
                      ? "Debes completar este campo con 100 carácteres como máximo."
                      : ""
                  }
                  name="title"
                  onChange={e => handleChange(e)}
                />
              </Grid>
              <Grid xs={12} sm={12} item>
                <TextField
                  id="txt-descripcionNoticia"
                  placeholder="Escribe una noticia"
                  variant="outlined"
                  label="Noticia"
                  fullWidth
                  multiline
                  minRows={4}
                  value={noticia.description}
                  error={descriptionError}
                  helperText={
                    descriptionError
                      ? "Debes completar este campo con 254 carácteres como máximo."
                      : ""
                  }
                  name="description"
                  onChange={e => handleChange(e)}
                />
              </Grid>
              <Grid xs={12} sm={12} item>
                {noticia.image_url.length > 0 && (
                  <CardMedia
                    component="img"
                    height="100px"
                    width="50px"
                    image={noticia.image_url}
                    alt="La vista previa no pudo ser cargada"
                  />
                )}
                <Button
                  id="btn-imagenNoticia"
                  fullWidth
                  variant="outlined"
                  onClick={e => handlePic(e)}
                  {...getRootProps()}
                  mr={{ xs: 0, md: 5 }}
                  mb={{ xs: 3, md: 0 }}
                >
                  {loadImage ? "Cambiar Imágen" : "Seleccionar Imágen"}
                  <ImageSearch />
                </Button>
                <input {...getInputProps()} />
              </Grid>

              <Grid xs={12} sm={12} item>
                {noticia.video_url && noticia.video_url.length > 0 && (
                  <ReactPlayer
                    url={noticia.video_url}
                    height="300px"
                    width="100%"
                    controls={true}
                  />
                )}
                <TextField
                  style={{ marginTop: "10px" }}
                  placeholder="Ingresá una URL de youtube"
                  variant="outlined"
                  label="URL"
                  name="video"
                  error={videoError}
                  helperText={
                    videoError
                      ? "Debes completar este campo con 200 carácteres como máximo."
                      : ""
                  }
                  fullWidth
                  value={noticia.video_url}
                  onChange={e => handleVideo(e)}
                />
              </Grid>

              <Box ml={3}>
                <FormControlLabel
                  label="Compartir con todos los usuarios"
                  control={
                    <Checkbox
                      id="chkbox-compartirTodosNoticia"
                      checked={shareToAllUsers}
                      onClick={() => handleCheckBox()}
                    />
                  }
                />
              </Box>

              {!shareToAllUsers && (
                <>
                  <Grid xs={12} sm={12} item>
                    <Autocomplete
                      multiple
                      id="destinatarios-areas"
                      options={areas.filter(
                        h =>
                          !noticia.recipient_areas.map(i => i.id).includes(h.id)
                      )}
                      onChange={(e, newValue) => handleAreas(newValue)}
                      getOptionLabel={area => area.name}
                      defaultValue={values.recipient_areas}
                      placeholder="Buscar..."
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Áreas Destinatarias"
                          variant="outlined"
                          error={dataError}
                          helperText={
                            dataError
                              ? "Debes elegir al menos una opción de Área o Usuarios destinatarios"
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} item>
                    <Autocomplete
                      multiple
                      id="destinatarios-users"
                      options={orderedUsers.filter(
                        h =>
                          !values.recipient_users.map(i => i.id).includes(h.id)
                      )}
                      onChange={(e, newValue) => handleUsers(newValue)}
                      getOptionLabel={user =>
                        user.first_name + " " + user.last_name
                      }
                      defaultValue={values.recipient_users}
                      placeholder="Buscar..."
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Usuarios Destinatarios"
                          variant="outlined"
                          error={dataError}
                          helperText={
                            dataError
                              ? "Debes elegir al menos una opción de Área o Usuarios destinatarios"
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid xs={12} sm={12} item>
                <TextField
                  id="publicacionType"
                  select
                  name="type"
                  fullWidth
                  label="Tipo de publicación"
                  variant="outlined"
                  value={noticia.type}
                  error={typeError}
                  helperText={typeError ? "Debes elegir una opción" : ""}
                  onChange={e => handleChange(e)}
                >
                  <MenuItem value={"LAUNCH"}>Alerta</MenuItem>
                  <MenuItem value={"TRAINING"}>Capacitaciones</MenuItem>
                  <MenuItem value={"WORKSHOP"}>Comunicado</MenuItem>
                  <MenuItem value={"BIRTHDAY"}>Cumpleaños</MenuItem>
                  <MenuItem value={"EVENT"}>Evento</MenuItem>
                  <MenuItem value={"READING"}>Lectura</MenuItem>
                </TextField>
              </Grid>
              <Grid xs={12} sm={12} item>
                <TextField
                  id="publishDate"
                  type="date"
                  name="publish_date"
                  label="Día de Publicación"
                  variant="outlined"
                  fullWidth
                  value={formatDate(noticia.publish_date)}
                  onChange={e => handleChangeDates(e)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={dateError}
                  helperText={
                    dateError
                      ? "La fecha de publicación debe ser previa a la fecha de expiración y mayor a la actual"
                      : ""
                  }
                />
              </Grid>
              <Grid xs={12} sm={12} item>
                <TextField
                  id="expirationDate"
                  type="date"
                  name="expiration_date"
                  label="Día de Expiración"
                  variant="outlined"
                  fullWidth
                  value={formatDate(noticia.expiration_date)}
                  onChange={e => handleChangeDates(e)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              {doneEditingNew ? (
                <Box
                  display="flex"
                  justifyContent="space-around"
                  style={{ width: "500px", margin: 2 }}
                >
                  <Button
                    style={{ width: "150px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleCancelEditNew()}
                  >
                    Aceptar
                  </Button>
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="space-around"
                  style={{ width: "500px", margin: 2 }}
                >
                  <Box ml={1}>
                    <Button
                      id="btn-cancelar"
                      style={{ width: "150px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleCancelEditNew()}
                    >
                      Cancelar
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      id="btn-guardarCambios"
                      style={{ width: "150px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => onUpdateNew(noticia)}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Box>
              )}
            </GridContainer>
          </CardContent>
        </Card>
        <UserActivityAlert />
      </Box>
    </Restricted>
  );
};
export default EditNew;
