import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import AppTextInput from "../../../../@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  addNewProject,
  getProjects,
  updateProject
} from "../../../../redux/actions/Projects";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography } from "@material-ui/core";
import { getAreas } from "redux/actions/Users";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const AddEditProject = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const currentProject = useSelector(
    ({ projectsReducer }) => projectsReducer.currentProject
  );
  const { projects } = useSelector(({ projectsReducer }) => projectsReducer);
  const { areas } = useSelector(({ usersReducer }) => usersReducer);

  const [name, setName] = useState(currentProject ? currentProject.name : "");
  const [nameError, setNameError] = useState("");
  const [area, setArea] = useState(currentProject ? currentProject.area : "");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAreas());
  }, [dispatch]);

  useEffect(() => {
    if (currentProject) {
      setName(currentProject.name);
    }
  }, [currentProject]);

  const areaNames = areas.map(area => area.name);

  const replaceSpecialCharacters = text => {
    const text1 = text.replaceAll("/", "-");
    const text3 = text1.replaceAll("¿", "-");
    const text4 = text3.replaceAll("?", "-");
    const text5 = text4.replaceAll("¡", "-");
    const text6 = text5.replaceAll("!", "-");
    const text7 = text6.replaceAll("[", "-");
    const text8 = text7.replaceAll("]", "-");
    const text9 = text8.replaceAll("{", "-");
    const text10 = text9.replaceAll("}", "-");
    const text11 = text10.replaceAll("*", "-");
    const text12 = text11.replaceAll('"', "");
    const text13 = text12.replaceAll("'", "");

    return text13;
  };

  useEffect(() => {
    setName(replaceSpecialCharacters(name));
  }, [name]);

  const onSubmitClick = () => {
    const activeFilter = projects.filter(
      project => project.status === "ACTIVE"
    );
    const suspendedFilter = projects.filter(
      project => project.status === "SUSPENDED"
    );
    const activeNames = activeFilter.map(project => project.name);
    const suspendedNames = suspendedFilter.map(project => project.name);
    if (name == null || name === "") {
      setNameError("Nombre requerido");
    } else if (currentProject) {
      const projectsId = projects.filter(
        project => project.id !== currentProject.id
      );
      const projectNames = projectsId.map(project => project.name);
      if (projectNames.includes(name) || currentProject.name === !name) {
        setNameError("Existe un proyecto con el nombre ingresado");
      } else {
        const id = currentProject.id;
        const status = currentProject.status;
        const updatedProject = {
          id,
          name,
          area,
          status
        };
        dispatch(
          updateProject(updatedProject, () => {
            onCloseDialog();
          })
        );
      }
    } else if (activeNames.includes(name)) {
      setNameError("Existe un proyecto activo con este nombre");
    } else if (suspendedNames.includes(name)) {
      setNameError("Existe un proyecto suspendido con este nombre");
    } else if (area == null) {
      setArea("");
      onProjectSave();
    } else {
      setArea(area.toString());
      onProjectSave();
    }
  };

  const onProjectSave = () => {
    const projectDetail = {
      name,
      area
    };

    dispatch(
      addNewProject(projectDetail, () => {
        onCloseDialog();
      })
    );
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentProject ? "Editar Proyecto" : "Crear Proyecto"}
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            id="txt-nombreProyecto"
            fullWidth
            variant="outlined"
            label={<Typography variant="h6">Nombre</Typography>}
            value={name}
            onChange={e => {
              setName(e.target.value);
              setNameError("");
            }}
            helperText={nameError}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <Autocomplete
            id="areas"
            defaultValue={currentProject ? currentProject.area : null}
            options={areaNames}
            getOptionLabel={area => area}
            getOptionSelected={(area, value) => area === value}
            placeholder="Buscar..."
            onChange={(event, getOptionSelected) => setArea(getOptionSelected)}
            fullWidth
            renderInput={params => (
              <TextField {...params} label="Área" variant="outlined" />
            )}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} id="btn-cancelar">
            Cancel
          </Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmitClick}
              id="btn-guardarCambios"
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditProject;

AddEditProject.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
